.modal-open {
    overflow: hidden;
}
.modal-title {
    font-family: $secondary_font;
    font-size: 2.6rem;
    font-weight: 500;
}
.modal-body {
    color:$black;
    text-align: justify;
    padding: clamp(15px, 2.0833vw, 40px);
    h2,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $secondary_font;
        font-size: 2rem;
        font-weight: 500!important;
        color:$black!important;
    }
}
.modal-header {
    position: relative;
    .close {
        opacity: 1;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: 0;
        transform: translateY(-50%);
    }
    ._close {
        display: flex;
        width: 16px;
        height: 16px;
        svg {
            display: flex;
            width: 16px;
            height: 16px;
        }
    }
}
.modal-content {
    border-radius: 0;
    border: none;
    -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
    background-color: #ffffff;
}
