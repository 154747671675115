$umbrella: '#quickcheckoutconfirm';
$checkout-bp: 991px;
$checkout-base-bg: $white;
$checkout-base-color: var(--black);
$field-space: max(30px, vw(30px));
$label-display: none;
$column-gap: vw(45px);
$checkout-width: 1640px;
$checkout-side-padding: max(10px, vw(80px));
$checkout-bottom-padding: max(30px, vw(50px));

#banner-easy-returns {
    margin-bottom: 0;
}

// extend classes
.checkout-hidden {
    display: none !important;
}
body {
    &.loginPopupisOpen {
        overflow: hidden;
    }
}
.cart-section-title {
    font-family: var(--secondary-font), sans-serif;
    font-size: var(--header-2);
    font-weight: 400;
    line-height: 1.2;
    color: #333333;
}

.popup-login-title {
    font-family: var(--secondary-font), sans-serif;
    font-weight: 500;
    font-size: 2.8rem;
    text-transform: uppercase;
}

.popup-login-close {
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: $black;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 1;

    &:focus,
    &:hover {
        color: $black;
        text-decoration: none;
        cursor: pointer;
        filter: alpha(opacity=50);
        opacity: .5;
    }
}

// extende classes end

.checkout__social--login {
    a {
        font-size: 1.5rem;
        text-align: center;
        padding: 22px 20px;
        max-height: unset;
        background-color: $blue;
        color: $white;
        display: flex;
        justify-content: center;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 1.3rem;
            padding: 12px 10px;
        }
    }
}

#{$umbrella} {
    .checkout {
        &__title {
            @extend .cart-section-title;
            margin: 0;
            padding: 20px 0 15px;

            &--no-top-space {
                margin-top: unset;
            }
        }
    }

    .checkout__social--login {
        background-color: $white;
        padding-bottom: 25px;

        @media (max-width: 991px) {
            a {
                font-size: 1.5rem;
                padding: 22px 20px;
            }
        }
    }
    .quickcheckout-content {
        background-color: #f5f3f0;
        border: 1px solid #f5f3f0;
        margin-bottom: 0;
        padding: 0;
    }
}

#{$umbrella} {
    color: $checkout-base-color;
    max-width: $checkout-width;
    padding-left: $checkout-side-padding;
    padding-right: $checkout-side-padding;
    padding-bottom: $checkout-bottom-padding;
    margin: auto;


    @media screen and (max-width: $checkout-bp) {
        padding-top: 150px;
    }


    .checkout__main-title {
        @extend .header-1,
        .checkout-title;
        width: 100%;
        margin-bottom: 40px;

        @media screen and (max-width: $checkout-bp) {
            text-align: center;
            font-size: 2.4rem;
        }
    }

    .checkout__wrap {
        display: flex;
        position: relative;

        @media screen and (max-width:$checkout-bp) {
            flex-direction: column;
        }

        &>* {
            width: 50%;

            @media screen and (max-width:$checkout-bp) {
                width: 100%;
            }
        }

        &--left-side {
            //background-color: #fff;

            @media screen and (min-width: ($checkout-bp + 1)) {
                margin-right: $column-gap;
            }

            &__content--wrap {
                background: #f5f3f0;
                padding-bottom: 25px;

                &>.shipping,
                &>.payment,
                &>#terms,
                &>.address {
                    &>* {
                        &:not(.address__type) {
                            margin: 25px;
                            //background: #fff;

                            @media screen and (max-width: $checkout-bp) {
                                padding-left: 0;
                                padding-right: 0;
                            }
                            @media screen and (max-width: 600px) {
                                margin: 10px 15px;
                            }
                        }
                    }
                }

                .payment__wrap {
                    margin-top: 15px;
                }
            }
        }

        &--right-side {
            background: #f5f3f0;

            padding: 25px;
            @media screen and (min-width: ($checkout-bp + 1)) {
                margin-left: $column-gap;
            }
            @media screen and (max-width: 600px) {
                padding: 15px;
            }

            &__content--wrap {
                @media screen and (min-width: ($checkout-bp + 1)) {
                    padding-bottom: 60px;
                }
            }
        }

        .address {
            &__type {
                display: flex;

                //@media screen and (max-width: $checkout-bp) {
                //    flex-direction: column;
                //}

                &>* {
                    cursor: pointer;
                    flex: 1;
                    background-color: $checkout-base-bg;
                    color: var(--black);
                    font-family: var(--primary-font), sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    padding: 20px 25px;

                    @media screen and (max-width: $checkout-bp) {
                        padding: 15px 20px;
                    }

                }

                &--active {
                    background-color: #f5f3f0;
                    text-decoration: underline;

                    //@media screen and (max-width: $checkout-bp) {
                    //    background-color: $white;
                    //    text-decoration: unset;
                    //    font-weight: bold;
                    //}
                }

                &--visitor {}

                &--register {}

                &--login {}
            }

            &__payment {}

            &__customer {}

            &__shipping {}

            &__customer-type {
                &--wrap {
                    margin-bottom: 15px;
                }
            }

            &__acknowledge {}

            &__title {
                @extend .checkout__title;
            }

            &__content {}
        }

        .terms {
            &__acknowledge {}

            &__wrap {
                background-color: red;
            }

            a {
                color: $primary_color;
            }
        }

        .payment,
        .shipping {
            //&__wrap {
            //    &:before {
            //        content: "";
            //        width: 100%;
            //        display: flex;
            //        padding-top: 20px;
            //    }
            //}

            &__title {
                @extend .checkout__title;
            }
        }
    }

    label {
        margin-bottom: 0;
        font-weight: initial;

        a {
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .option {
        &__wrap {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &__heading {
                display: none;
            }

            &__content {}
        }
    }

    [type="button"] {
        @extend .btn,
        .btn-default;
        width: 100%;

        &:hover {
            color: var(--black) !important;
            background-color: transparent !important;
            border-color: var(--black) !important;
        }
    }

    .radio__wrap,
    .checkbox__wrap {
        display: flex;
        align-items: center;

        [type="radio"],
        [type="checkbox"] {
            margin-right: 10px;
        }
    }

    .checkbox__wrap {
        line-height: 1;
        margin-bottom: 10px;
        width: 100%;

        span {
            cursor: pointer;
        }

        &--show-passwords {
            display: none;
        }

        &--create-account {}

        &--shipping-address {}

        &--newsletter {}

        &--agree {
            @media screen and (max-width: 600px) {
                width: 100%;
                margin-bottom: 15px !important;
                justify-content: center;
            }
        }
    }

    .radio__wrap {

        span,
        input {
            cursor: pointer;
        }
    }

    .field__wrap {
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }

        &--input {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            width: calc(50% - calc($field-space / 2));

            @media screen and (max-width: 768px) {
                margin-right: 0 !important;
                width: 100%;
            }


        }
    }
}

.checkout__cart {
    padding-top: 15px;
    padding-bottom: 15px;

    &__head,
    .checkout__cart__content--product {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid var(--light-gray);
        padding: 15px 10px;
        background-color: #f5f3f0;

        &>* {

            @media screen and (max-width: 600px) {
                font-size: 1.2rem;
            }
        }

        .checkout__cart--image {
            width: 15%;
            min-width: max(60px, vw(80px));
        }

        .checkout__cart--name {
            width: 35%;
            margin-right: auto;
            margin-left: 7px;
            text-transform: uppercase;

            small {
                font-size: 85% !important;
            }
        }

        .checkout__cart--quantity {
            width: 25%;
            text-align: center;
        }

        .checkout__cart--price {
            display: none;
        }

        .checkout__cart--total {
            text-align: right;
            width: 20%;
        }
    }

    &__head {
        background: $black;
        color: #fff;
        padding: 15px 10px;

        .checkout__cart--image {
            opacity: 0;
        }
    }

    &__content {

        &--product {}



        .checkout__cart--name {
            text-decoration: none;
            font-size: 1.4rem;
            color: $black;

            &>* {
                &:first-child {
                    font-family: $secondary_font;
                }
            }

            &--giftwrap {
                margin-top: 10px;

                .checkbox__wrap {
                    font-size: 1.2rem;
                    margin-bottom: unset;

                    span {
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }

    &__output {
        &--totals {
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            justify-content: space-between;

            span {
                font-size: 1.4rem;

                &:first-child {
                    font-weight: 500;
                }
            }

            &:first-child {
                margin-top: 15px;
            }

            &:last-child {
                border-top: 1px solid var(--light-gray);
                border-bottom: 1px solid var(--light-gray);
                margin-top: 15px;

                span {
                    font-size: 1.5rem;
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
        }
    }

    &--image {
        img {
            width: 100%;
            max-width: 80px;
            border: 1px solid $black;
            background-color: #fff;
        }
    }

    &--name {
        @media screen and (max-width:600px) {
            .checkbox__wrap {
                font-size: 1.1rem;
            }
        }
    }

    &--quantity {
        &__wrap {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__control {
            border: 1px solid $light-gray;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            &>* {
                padding: 5px !important;
                display: flex;
                align-items: center;
                height: 33px;
            }

            input {
                border: none !important;
                text-align: center;
            }

            button {
                background-color: transparent;
                border: none;
            }
        }

        &__update,
        &__remove {
            background-color: unset;
            padding: 0;
            margin: 5px;
        }

        &__update {
            margin-left: 15px;
        }

        &__remove {
            position: absolute;
            right: min(-35px, vw(-60px));

            @media screen and (max-width: 600px) {
                display: none;
            }
        }

    }

    &--price {}

    &--total {}

    &--textarea {
        display: flex;
        width: 100%;
        min-height: 140px;
        margin-top: 15px;
        margin-bottom: 30px;
        resize: vertical;
    }
}

#{$umbrella} {
    .text-danger {
        font-size: 1.2rem;
        color: #e45451;
    }
}

#{$umbrella} {
    .redeem {
        &__wrap {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
        }

        &--item {
            display: flex;
        }

        &--heading {
            display: none;
        }

        &--content {
            display: flex;
            width: 100%;

            input {
                flex: 1;
                border: 1px solid $black;
                padding: 12px 10px;
                &::placeholder {
                    color: $black;
                }

                @media screen and (max-width: 600px) {
                    padding: 10px;
                }
            }

            button {
                width: 100%;
                max-width: 135px;
                padding: 5px 20px;
                white-space: normal;

                @media screen and (max-width: 600px) {
                    padding: 13px 15px 10px;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .prd-remove {
            font-size: 12px;
        }
    }
}

#{$umbrella} {
    .popup-login {
        display: none;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;

        &>span {
            content: '';
            background-color: rgba(41, 41, 41);
            opacity: 0.7;
            display: flex;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
        }

        &.open {
            display: flex;
            margin: 0!important;
        }

        &__wrap {
            background-color: $white;
            width: 100%;
            max-width: 600px;
            margin: 20px;
        }

        &__head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            border-bottom: 1px solid $light_gray;
            background-color: #f5f3f0;
            &__title {
                @extend .popup-login-title;
            }

            ._close {
                @extend .popup-login-close;
            }
        }

        .login-fields {
            width: 100%;

            &--wrap {
                padding: 40px;

                input {
                    margin-bottom: 15px;
                }

                a {
                    text-decoration: underline;
                }

                button {
                    @extend .btn,
                    .btn-default;
                    margin-top: 35px;
                }
            }
        }
    }
}

#{$umbrella} {
    .checkout-header {
        width: 100%;
        padding: 40px;
        margin-bottom: 55px;

        @media screen and (max-width: $checkout-bp) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $white;
            padding: 15px;
            z-index: 1;
            border-bottom: 2px solid $black;
            margin-bottom: unset;
        }

        &__wrap {
            display: flex;
            justify-content: center;
            align-items: center;

            &>* {
                width: 300px;
                color: var(--primary-color);

                * {
                    white-space: nowrap;
                }
            }

        }

        .checkout-header--logo {
            display: flex;
            justify-content: center;
            align-content: center;
        }

        &--back-link {
            text-decoration: underline;
            display: inline-block;
            @media screen and (max-width: $checkout-bp) {
                margin-bottom: 5px;
            }
        }

        &--assist {
            text-align: right;
            @media screen and (min-width: ($checkout-bp + 1)) {
                padding-right: 25px;
            }

            @media screen and (max-width: $checkout-bp) {
                display: flex;
                flex-direction: column;
                width: auto;
                text-align: center;
            }
        }

        ._underline {
            text-decoration: underline;
        }

        .support-icon {
            position: relative;
            top: 5px;
            margin: 0 5px;
        }
    }
}

#{$umbrella} {
    #d_social_login {
        display: unset;
        width: unset;
        max-width: unset;
        margin: unset;
    }
}

#checkout-safety-net {
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.address__title{
    margin-top: 40px;
}


#quickcheckoutconfirm .alert-danger{
    font-size: 13px;
    color: #ff0000;
    display: block;
    text-align: left;
    background: none;
}

#quickcheckoutconfirm .alert.alert-success{
    font-size: 13px;
    display: block;
    text-align: left;
    background: none;
}

.redeem__wrap .alert-success{
    text-align: left;
    font-size: 13px;
    color: #3c763d;
    background-color: unset;
}
#payment-address {
    margin-bottom: 25px;
}
#payment-address .quickcheckout-content .hide {
    display: none !important;
}