/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
.product-choices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    background: $white;
    border-bottom: 2px solid $black;
    @media (max-width: $laptop-xs) {
        padding: 15px;
    }
    @media screen and (max-width: $tablet) {
        padding: 20px 15px;
        align-items: flex-end;
    }
    .product-sorting {
        width: 100%;
        max-width: 250px;
        @media screen and (max-width: $tablet) {
            max-width: 200px;
            .select2-container--default .select2-selection--single {
                padding: 0;
            }
        }
        .select2-container--default .select2-selection--single {
            padding: 3px 0;
        }
    }
    #sub-category-items {
        flex-basis: 85%;
        display: flex;
        @media screen and (max-width: $tablet) {
            flex-basis: 50%;
        }
        .list-group {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0;
            &-item {
                font-family: $secondary_font;
                border: none;
                background-color: transparent;
                text-transform: uppercase;
                color: $black;
                font-weight: 400;
                padding: 0;
                margin: 0 25px 0 0;
                position: relative;
                @media screen and (max-width: $tablet) {
                    display: none;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    width: 0;
                    height: 1px;
                    background-color: $black;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: width .3s ease;
                }
                &:hover {
                    &:after {
                        width: 100%;
                        transition: width .3s ease;
                    }
                }
                &.active {
                    &:after {
                        width: 100%;
                    }
                    @media screen and (max-width: $tablet) {
                       display: block;
                    }
                }
            }
        }
    }
}

/*** product-list ***/
.product-list{
    display: flex;
    flex-wrap: wrap;
    &__item {
        flex-basis: 25%;
        border: 2px solid $black;
        border-top: none;
        border-left: none;
        @media screen and (max-width: $tablet) {
            flex-basis: 50%;
        }
        @media screen and (max-width: $mobile) {
            flex-basis: 50%;
            border: 1px solid var(--black);
            border-right: none;
            border-top: none;
            //border-left: 1px solid var(--black);
        }
    }
}
.category-banner {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-height: 400px;
        max-height: 582px;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-height: 400px;
        max-height: 582px;
    }
    &-text {
        text-transform: uppercase;
        padding: 15px;
        width: 100%;
        max-width: 430px;
        margin: 0 auto;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.page_category, .page_product, .page_search {
    footer {
        border-top: none;
        position: relative;
        &:after {
            display: inline-block;
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            top: -2px;
            background-color: $black;
        }
    }
}

ul.pagination,
.load-more {
    display: none;
}

.select2-results__option{
    font-size: 13px;
    background-color: #f5f3f0;
}
#product-category {
    .product .product-caption{
        display: none;
    }
    #content {
        min-height: unset;
    }
}

@media screen and (max-width: $mobile) {
    .category-banner img{
        min-height: unset;
    }

    .category-banner video{
        min-height: unset;
    }

    .category-banner-text{
        font-size: 10px;
    }

}
