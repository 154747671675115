.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding:35px;
  margin: 0;
  background-color: #fff;
  color: $black;
  border: none;
  border-radius: 0;
  z-index: 9999;
  animation: note 4500ms forwards;
  @media (max-width: $tablet-sm) {
    padding: 35px 25px;
    width: 90%;
    margin: 0;
  }

  a,
  a:hover {
    color: $black;
    font-weight: 500;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: inherit;
    @media (max-width: $tablet-sm) {
      top: 10px;
      right: 10px;
    }
  }
}

@keyframes note {
  0% {
    margin-top: 25px;
    opacity: 0;
  }
  10% {
    margin-top: 0;
    opacity: 1;
  }
  90% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 25px;
    opacity: 0;
  }
}