.voucher {
  &-container {
    padding: 80px;
    @media screen and (max-width: $laptop-xs) {
      padding: 75px 15px;
    }
    h1 {
      text-align: center;
      margin-bottom: max(30px, vw(80px));
    }
    .form-group {
      display: flex;
      flex-direction: column;
      margin: 0 0 15px;
    }
    h2 {
      margin-top: 50px;
      font-size: 22px;
    }
  }
  &-wrapper {
    max-width: 1460px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $tablet) {
      flex-direction: column;
      max-width: 715px;
    }
  }
  &-left {
    max-width: 715px;
    margin-right: 15px;
    @media screen and (max-width: $tablet) {
      margin-right: 0;
    }
  }
  &-right {
    max-width: 715px;
    margin-left: 15px;
    position: relative;
    @media screen and (max-width: $laptop-sm) {
      max-width: 435px;
    }
    @media screen and (max-width: $tablet) {
      margin-left: 0;
    }
    &-wrapper {
      position: sticky;
      top: calc(var(--headerHeight) + 30px);
    }
  }
  &-image {
    position: relative;
    border-radius: 16px;
    .giftcard {
      &-amount {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: $secondary-font;
        font-size: max(35px, max(80px));
        width: 100%;
        text-align: center;
        color: $gray;
        line-height: 1;
        @media screen and (max-width: $tablet-sm) {
          font-size: 30px;
        }
      }
    }
  }
}
.input-radio-inline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  .radio {
    &:not(:last-child) {
      margin-right: 6px;
    }
    label {
      display: block;
      position: relative;
      padding-left: 44px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      user-select: none;
      width: 120px;
      height: 50px;
      align-items: center;
      justify-content: center;
      border: 1px solid $black;
      border-radius: 32px;
      margin-bottom: 0;
      @media screen and (max-width: $tablet-sm) {
        width: 75px;
        height: 35px;
      }
      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }
      input[type=radio] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ span, &:checked ~ span {
          background-color: $black;
          border: 1px solid $black;
          border-radius: 32px;
          color: $white;
        }
      }
      span {
        position: absolute;
        top: -1px;
        left: -1px;
        width: 120px;
        height: 50px;
        background-color: transparent;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: $tablet-sm) {
          width: 75px;
          height: 35px;
        }
      }
    }
  }
}
.giftcard-image-mobile {
  max-width: 450px;
  margin: 0 auto;
}