input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
textarea,
select,
label.control-label,
input[type='text'].form-control,
input[type='password'].form-control,
input[type='tel'].form-control,
input[type='email'].form-control,
textarea.form-control,
select.form-control,
.radio label,
.checkbox label,
label.control-label {
    font-size: $input_fs;
    font-weight: 400;
    color: $black;
}
.radio label,
.checkbox label,
label.control-label {
    font-weight: 600;
}
input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
textarea,
select,
input[type='text'].form-control,
input[type='password'].form-control,
input[type='tel'].form-control,
input[type='email'].form-control,
textarea.form-control,
select.form-control {
    border: none;
    border-bottom: 1px solid $black;
    padding: 11px 0;
    height: unset;
    //line-height: 1.5;
    background-color: transparent;
    box-shadow: none;
    border-radius: $border_radius_input;
    &::placeholder {
        color: $placeholder_color;
        font-weight: 400;
    }
    &:focus {
        box-shadow: none;
        border-color: $black;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: $black;
        box-shadow: 0 0 0 1000px $light_gray inset;
        border-bottom: 1px solid $black;
        &::selection{
            -webkit-text-fill-color: $white;
        }
    }
}
textarea {
    border: 1px solid $black!important;
    padding: 10px!important;
    margin-top: 5px;
}
// add custom arrow on select
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 15px;
    background-image: url('../../../assets/icons/new-arrow-down.svg') !important;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        opacity: 0.8;
        background: $light_beige;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: $primary_color;
        &:hover {
            opacity: 1;
        }
    }
}
// Bootstrap default
.form-horizontal {
    .control-label {
        padding-top: 0;
        text-align: left;
    }
}
.form-group.required {
    .control-label {
        &:after {
            content: ' *';
            color: $gray;
        }
    }
}

/* RADIO */
.radio-inline,
.radio > label {
    display: flex;
    align-items: center;
    margin-left: 0 !important;
}

input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $light_gray;
    margin: 0;
    font: inherit;
    color: $light_gray;
    width: 16px;
    height: 16px;
    border: 2px solid $light_gray;
    border-radius: 50%;
    cursor: pointer;

    display: grid;
    place-content: center;

    &:checked {
        background-color: $light_brown;
        border: 2px solid $light_brown;
    }
}

input[type='radio']::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $black;
}

input[type='radio']:checked::before {
    transform: scale(1);
}

.product-plated-option input[type=radio]::before{
    box-shadow: none;
}

input[type='radio']:focus {
    outline: max(2px, 1px) solid $light_gray;
    outline-offset: max(2px, 1px);
}

/* CHECKBOX */
input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: $light_gray;
    margin: 0 10px 0 0;
    font: inherit;
    color: $light_gray;
    width: 100%;
    max-width: 16px;
    height: 16px;
    border: 2px solid $light_gray;
    border-radius: 2px;
    display: grid !important;
    place-content: center;
    cursor: pointer;
    &:checked {
        background-color: $light_brown;
        border: 2px solid $light_brown;
    }
}

input[type='checkbox']::before {
    content: '';
    width: 8px;
    height: 8px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $black;
}

input[type='checkbox']:checked::before {
    transform: scale(1);
}

input[type='checkbox']:focus {
    outline: max(2px, 1px) solid currentColor;
    outline-offset: max(2px, 1px);
}

input[type='checkbox']:disabled {
    color: #ff0000;
    cursor: not-allowed;
}
#payment_address_shipping_address_input, #payment_address_agree_input  {
    label {
        display: flex;
        align-items: baseline;
    }
}
label[for^='payment'],
label[for='free_checkout'],
label[for='confirm_agree'],
label[for='bank_transfer'],
label[for='cod'],
.radio-input.radio label {
    display: flex;
    align-items: baseline;
    input[type='radio'] {
        top: 5px;
    }
    input[type='radio'],
    input[type='checkbox'] {
        margin-right: 5px;
        display: grid !important;
    }
}

select#user_selected_installments {
    padding-right: 30px;
}
label{
    font-size: 16px;
    width: 100%;
    margin: 0;
}
.input-group {
    &.date {
        display: inline-flex;
        .btn-default {
            margin: 0;
            flex-basis: 15%;
            padding: 10px;
        }
    }
}