.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.light {
    font-weight: 300;
}

.medium {
    font-weight: 500;
}

.bold {
    font-weight: 600;
}

.extra-bold {
    font-weight: 900;
}

.primary {
    color: $primary_color;
}

.black {
    color: $black;
}

.white {
    color: $white;
}

.gray {
    color: $gray;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.header-1 {
    font-family: $secondary_font;
    font-size: $header_1_fs;
    font-weight: 400;
    letter-spacing: 0.025em;
    line-height: 1.2;
    margin: 0 0 10px;
}

.header-2 {
    font-family: $secondary_font;
    font-size: $header_2_fs;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 15px;
}

.header-3 {
    font-family: $primary_font;
    font-size: $header_3_fs;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 5px;
}

.header-4 {
    font-family: $primary_font;
    font-size: $header_4_fs;
    font-weight: 600;
    letter-spacing: 0.025em;
    line-height: 1.2;
    margin: 0 0 15px;
}

.hero-title {
    font-family: $primary_font;
    font-size: $hero_title_fs;
    font-weight: 600;
    color: inherit;
    line-height: 1.2;
    margin: 0 auto 20px;
}

.banner-title {
    font-family: $primary_font;
    font-size: $banner_title_fs;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 auto 40px;
}

p,
.text {
    font-family: $primary_font;
    font-size: $text_fs;
    margin: 0 0 10px;
}

.text-small {
    font-family: $primary_font;
    font-size: $text_small_fs;
    line-height: 1.8;
    margin: 0 0 10px;
}

.text-large {
    font-family: $primary_font;
    font-size: $text_large_fs;
    line-height: 1.8;
    margin: 0 0 10px;
}
