/** header **/
.logo-icon {
    display: flex;
    width:122px;
    height: 25px;
    svg {
        display: flex;
        width:122px;
        height: 25px;
        fill:#231F20;
    }
}
body {
    padding-top: 114px;
    @media (max-width: $tablet) {
        padding-top: 67px;
    }
    &.page_quickcheckout {
        padding-top: 0;
    }
    &.home {
        .logo-icon {
            svg {
                fill:$white;
            }
        }
        padding-top: 0;
        #cart {
            .cart-btn {
                .cart-icon {
                    svg {
                        fill:$white;
                    }
                }
            }
        }
        #main-header {
            background-color: transparent;
            color: $white;
            .header {
                &-container {
                    border-bottom: none;
                }
                &-left {
                    .navbar-default {
                        .megamenu-wrapper {
                            ul.megamenu {
                                li {
                                    a {
                                        font-family: $secondary_font;
                                        color: $white;
                                    }
                                    .sub-menu {
                                        .content {
                                            .row {
                                                .col-sm-1 {
                                                    .categories {
                                                        ul {
                                                            li {
                                                                a.main-menu {
                                                                    font-family: $secondary_font;
                                                                    color: $white;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .header-search-icon {
                        svg {
                            stroke: #fff;
                        }
                    }
                    .search-input {
                        border-color:$white;
                        color: $white;
                        margin-bottom: 0;
                        position: relative;
                        top: -10px;
                        @media (max-width: $tablet) {
                            border-color:unset;
                            color: unset;
                            margin-bottom: unset;
                            top: unset;
                            position: fixed;
                        }
                        &-wrapper {
                            position: relative;
                            border-color: #ffffff;
                            @media (max-width: $tablet) {
                                border-color: $black;
                            }
                        }
                        input {
                            color: $white;
                            @media (max-width: $tablet) {
                                color: $black;
                            }
                        }
                        button {
                            .search-icon {
                                svg {
                                    stroke: $white;
                                }
                            }
                        }
                        &.active {
                            button {
                                .search-icon {
                                    svg {
                                        stroke: #ffffff;
                                        @media (max-width: $tablet) {
                                            stroke: $black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-right {
                    a {
                        &.header-wishlist {
                            .header-wishlist-icon {
                                svg {
                                    path {
                                        stroke: $white;
                                    }
                                }
                                &.active {
                                    svg {
                                        path {
                                            fill: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    #cart {
                        .cart-btn {
                            .cart-icon {
                                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1599 6.64217V5.34014C16.1599 2.9364 14.3039 1 11.9999 1C9.69588 1 7.8399 2.93634 7.8399 5.34014V6.64217H4V21.9994C4 22.1997 4.1279 22.3333 4.32009 22.3333H19.6799C19.8719 22.3333 20 22.1999 20 21.9994V6.64217H16.1599ZM8.48001 5.37347C8.48001 3.33697 10.048 1.70097 12.0001 1.70097C13.9521 1.70097 15.5202 3.33691 15.5202 5.37347V6.67549H8.48023L8.48001 5.37347ZM19.3599 21.6655H4.64005V7.30981H7.84001V11.6832H8.47995V7.30981H15.5199V11.6832H16.1598L16.16 7.30981H19.36L19.3599 21.6655Z' fill='%23ffffff'/%3E%3C/svg%3E");
                            }
                        }
                    }
                }
            }
            &.sticky-menu {
                .logo-icon {
                    svg {
                        fill:$black;
                    }
                }
                background: var(--background-color);
                color: $black;
                .header {
                    &-container {
                        border-bottom: 1px solid $black;
                    }
                    &-left {
                        .navbar-default {
                            .megamenu-wrapper {
                                ul.megamenu {
                                    li {
                                        a {
                                            color: $black;
                                            //line-height: 1.2;
                                            strong {
                                                display: flex;
                                                @media (max-width: $tablet) {
                                                    justify-content: center;
                                                }
                                            }
                                        }
                                        .sub-menu {
                                            .content {
                                                .row {
                                                    .col-sm-1 {
                                                        .categories {
                                                            ul {
                                                                li {
                                                                    a.main-menu {
                                                                        color: $black;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .header-search-icon {
                            svg {
                                stroke: $black;
                            }
                        }
                        .search-text {
                            color: $black;
                        }
                        .search-input {
                            color: $black;
                            &-wrapper {
                                border-color:$black;
                            }
                            input {
                                color: $black;
                            }
                            button {
                                .search-icon {
                                    svg {
                                        stroke: $black;
                                    }
                                }
                            }
                        }
                    }
                    &-right {
                        a {
                            &.header-wishlist {
                                .header-wishlist-icon {
                                    svg {
                                        path {
                                            stroke: $black;
                                        }
                                    }
                                    &.active {
                                        svg {
                                            path {
                                                fill: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        #cart {
                            .cart-btn {
                                .cart-icon {
                                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1599 6.64217V5.34014C16.1599 2.9364 14.3039 1 11.9999 1C9.69588 1 7.8399 2.93634 7.8399 5.34014V6.64217H4V21.9994C4 22.1997 4.1279 22.3333 4.32009 22.3333H19.6799C19.8719 22.3333 20 22.1999 20 21.9994V6.64217H16.1599ZM8.48001 5.37347C8.48001 3.33697 10.048 1.70097 12.0001 1.70097C13.9521 1.70097 15.5202 3.33691 15.5202 5.37347V6.67549H8.48023L8.48001 5.37347ZM19.3599 21.6655H4.64005V7.30981H7.84001V11.6832H8.47995V7.30981H15.5199V11.6832H16.1598L16.16 7.30981H19.36L19.3599 21.6655Z' fill='%23231F20'/%3E%3C/svg%3E");
                                }
                            }
                        }
                    }
                }
                .burger-menu {
                    .icon-bar {
                        background-color: $black;
                    }
                }
                #cart {
                    .cart-btn {
                        .cart-icon {
                            svg {
                                fill:$black;
                            }
                        }
                    }
                }
            }
        }
        .burger-menu {
            .icon-bar {
                background-color: $white;
            }
        }
    }
    &.home.menu-is-open {
        .logo-icon {
            svg {
                fill:$black;
            }
        }
        .burger-menu {
            .icon-bar {
                background-color: $black;
            }
        }
    }
}
.burger-menu {
    &.active {
        z-index: 9999;
    }
}

#main-header {
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--background-color);
    z-index: 99;
    color: $black;
    transition: background .3s ease-out;
    &.sticky-menu {
        top: 0;
        position: fixed;
        transition: background .3s ease-out;
        width: 100%;
        left: 0;
        background: var(--background-color);
    }
    .header {
        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 40px 40px 37px;
            border-bottom: 2px solid $black;
            @media (max-width: $laptop-xs) {
                padding: 40px 15px 37px;
            }
            @media (max-width: $tablet) {
                margin: 0;
                padding: 15px;
            }
        }
        &-logo {
            flex-basis: 20%;
            #logo {
                margin: 0;
                display: flex;
                justify-content: center;
                a {
                    font-family: 'Mustica Pro', sans-serif;
                    text-transform: uppercase;
                    font-size: 35px;
                    line-height: 1;
                    font-weight: 600;
                    z-index: 999;
                }
            }
        }
        &-left, &-right {
            flex-basis: 40%;
            display: flex;
            align-items: center;
            min-height: 36px;
            max-height: 36px;
        }
        &-right {
            justify-content: flex-end;
            @media (max-width: $tablet) {
                justify-content: unset;
                flex-direction: row-reverse;
                align-items: center;
            }
            a {
                font-family: $secondary_font;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 400;
                line-height: 1.2;
                &:not(:last-child) {
                    margin-right: 40px;
                    @media (max-width: $laptop-xs) {
                        margin-right: 20px;
                    }
                }
                &.header-wishlist {
                    .header-wishlist-icon {
                        display: none;
                    }
                }

                @media (max-width: $tablet) {
                     &.header-login {
                        display: none;
                    }
                    &.header-wishlist {
                        margin-right: 5px;
                        display: flex;
                        .header-wishlist-text {
                            display: none;
                        }
                        .header-wishlist-icon {
                            display: inline-flex;
                            width: 21px;
                            height: 21px;
                            svg {
                                display: inline-flex;
                                width: 21px;
                                height: 21px;
                                path {
                                    stroke: $black;
                                    stroke-width: 1px;
                                }
                            }
                            &.active {
                                svg {
                                    path {
                                        fill: $black;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-left {
            /** menu **/
            .megamenu-style-dev {
                margin-bottom: 0;
                @media (max-width: $tablet) {
                    position: fixed;
                    top: 0;
                    z-index: 999;
                    left: -100%;
                    width: 100%;
                    height: var(--screenHeight);
                    visibility: hidden;
                    opacity: 0;
                    transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
                    @media (max-width: $mobile) {
                        width: 100%;
                    }
                    &.active {
                        left: 0;
                        visibility: visible;
                        opacity: 1;
                        transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
                    }
                    .megamenu-pattern {
                        width: 100%;
                        height: calc(var(--screenHeight) - 40px);
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }

            .navbar-default {
                background-color: transparent;
                border: none;
                .navbar-header {
                    @media (max-width: $tablet) {
                        display: none;
                    }
                }

                .megamenu-wrapper {
                    background-color: transparent;
                    position: initial;
                    @media (max-width: $tablet) {
                        background: $white;
                        border: none;
                        width: 100%;
                        padding: 20px 15px;
                        height: var(--screenHeight);
                        max-height: unset;
                        overflow: unset;
                    }
                    ul.megamenu {
                        li {
                            margin-right: 40px;
                            @media (max-width: $laptop-xs) {
                                margin-right: 20px;
                            }
                            &:hover {
                                background-color: transparent;
                                a {
                                    background-color: transparent;
                                    color:$black;
                                }
                            }
                            a {
                                padding: 0;
                                font-family: var(--secondary-font), sans-serif;
                                text-transform: uppercase;
                                font-size: 18px;
                                font-weight: 400;
                                &:before {
                                    display: none;
                                }
                                &:hover {
                                    background-color: transparent;
                                }
                                .caret, &:after {
                                    display: none;
                                }
                            }
                            .sub-menu {
                                left: 0;
                                padding: 10px 40px 20px 40px;
                                @media (max-width: $laptop-xs) {
                                    padding: 10px 15px 20px;
                                }
                                @media (max-width: $tablet) {
                                    padding: 10px 20px 20px;
                                }
                                .content {
                                    width: 100%;
                                    padding: 0;
                                    background: transparent;
                                    border: none;
                                    box-sizing: unset;
                                    box-shadow: none;
                                    .row {
                                        .col-sm-1 {
                                            width: auto;
                                            @media (max-width: $tablet) {
                                                width: 100%;
                                            }
                                            .categories {
                                                .menu {
                                                    ul {
                                                        li {
                                                            margin-right: unset;
                                                            a.main-menu {
                                                                font-family: var(--secondary-font), sans-serif;
                                                                color: $black;
                                                                font-size: 16px;
                                                                font-weight: 400;
                                                                line-height: 1.2;
                                                                text-transform: uppercase;
                                                                margin-bottom: 0;
                                                                padding-bottom: 0;
                                                                border-bottom: none;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                    &.so-megamenu-active {
                      * {
                          color: $black!important;
                      }
                    }
                }
            }
        }
    }
}

.close-search-btn {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    @media (max-width: $tablet) {
        display: flex;
    }
    .close-btn {
        display: flex;
        width: 20px;
        height: 20px;
        svg {
            display: flex;
            width: 20px;
            height: 20px;
        }
    }
}

.burger-menu {
    display: none;
    width: 43px;
    flex-direction: column;
    @media (max-width: $tablet) {
        display: flex;
        z-index: 999;
    }
    .icon-bar {
        display: block;
        content: "";
        height: 2px;
        width: 100%;
        background-color: $black;
        transition: transform 0.3s ease;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &.top, &.bottom {
            max-width: 26px;
            margin-left: auto;
            margin-right: auto;

        }
    }
    &.active {
        .icon-bar {
            &.top {
                max-width: 100%;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                margin: 0;
                position: relative;
                top: 5px;
            }
            &.middle {
                opacity: 0;
            }
            &.bottom {
                max-width: 100%;
                transform: rotate(-45deg);
                margin: 0;
                position: relative;
                top: -9px;
            }
        }
    }
}

//header search
.search {
    &-text {
        font-family: var(--secondary-font), sans-serif;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        &.hide {
            display: none;
        }
        .header-search-text {
            @media (max-width: $tablet) {
                display: none;
            }
        }
    }
    &-input {
        display: none;
        width: 100%;
        max-width: 200px;
        &.active {
            display: inline-flex;
            @media (max-width: $tablet) {
                position: fixed;
                width: 100%;
                background: $white;
                top: 0;
                left: 0;
                max-width: 100%;
                padding: 15px 35px;
                z-index: 9999;
            }
        }
        &-wrapper {
            position: relative;
            display: flex;
            border-bottom: 1px solid $black;
            @media (max-width: $tablet) {
                width: 100%;
                max-width: 450px;
                margin: 0 auto;
            }
        }
        button {
            background-color: transparent;
            border: none;
            padding: 10px;
            flex-basis: 10%;
            //position: absolute;
            //top: 0;
            //left: 0;
            .search-icon {
                display: flex;
                width: 17px;
                height: 15px;
                svg {
                    display: flex;
                    width: 17px;
                    height: 15px;
                    stroke: $black;
                }
            }
        }
        input {
            border: none;
            padding: 0;
            position: relative;
            flex-basis: 90%;
            background-color: transparent;
            &::placeholder {
                color: transparent;
                @media (max-width: $tablet) {
                    color: $black;
                }
            }
        }
    }
}

.header-search-icon {
    display: none;
    width: 22px;
    height: 20px;
    svg {
        display: flex;
        width: 22px;
        height: 20px;
        stroke: $black;
    }
    @media (max-width: $tablet) {
        display: flex;
        margin-left: 10px;
    }
}

.main-menu.active, .giftcard-menu-item.active{
    text-decoration: underline;
}
.giftcard-menu-item {
    display: flex;
    justify-content: center;
    color: $black;
    font-size: 16px!important;
    font-weight: 400!important;
}

.cc-settings-dialog{
    @media (max-width: $mobile) {
        font-size: 12px !important;
    }
}