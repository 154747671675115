/*========================================================*/
/*** PRODUCT PAGE ***/
/*========================================================*/
.product {
    &-container {
        padding: 50px 80px 130px 0;
        @media (max-width: $tablet) {
            padding-right: 30px;
        }
        @media (max-width: $tablet-sm) {
            padding: 30px 15px;
        }
    }
    &-wrapper {
        display: flex;
        @media (max-width: $tablet-sm) {
            flex-direction: column;
        }
    }
    &-left {
        flex-basis: 50%;
        position: relative;
        &-wrapper {
            position: relative;
            max-width: 865px;
            margin-right: 40px;
            @media (max-width: $tablet) {
                margin-right: 0;
            }
        }
    }
    &-right {
        flex-basis: 50%;
        max-width: 700px;
        margin-left: 40px;
        @media (max-width: $tablet-sm) {
            flex-basis: 100%;
            max-width: 100%;
            margin-left: 0;
        }
    }
    &-images {
        position: relative;
        ul {
            flex-direction: column;
            @media (max-width: $tablet-sm) {
                flex-direction: row;
            }
        }
    }
    &-wishlist-btn {
        position: absolute;
        top: 30px;
        right: 30px;
        border: none;
        z-index: 9;
        background: transparent;
        &.active {
            .product-wishlist {
                svg {
                    fill: var(--black);
                }
            }
        }
    }
    &-title {
        font-size: 50px;
        font-weight: 600;
        line-height: 1;
        @media (max-width: $laptop-sm) {
            font-size: 35px;
        }
        @media (max-width: $tablet-sm) {
            font-size:28px;
        }
    }
    &-price {
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
        @media (max-width: $tablet-sm) {
            margin-bottom: 10px;
        }
        li {
            span {
                display: flex;
            }
            &:not(:last-child){
                margin-right: 20px;
            }
        }
        .price {
            font-size: 26px;
            font-weight: 500;
            line-height: 1.2;
            &-new {
                font-size: 26px;
                font-weight: 500;
                line-height: 1.2;
            }
            &-old{
                font-size: 20px;
                font-weight: 200;
                text-decoration: line-through;
            }
        }
    }
    &-plated {
        display: flex;
        .radio {
            label {
                padding-left: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .product-plated-option {
                    position: relative;
                    @media (max-width: $tablet-sm) {
                        max-width: 100px;
                    }
                }
                .option-value-name {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                img {
                    position: relative;
                    border-radius: 0;
                    background: transparent;
                }
                input[type=radio] {
                    margin-left: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color:transparent;
                    border: 1px solid $black;
                    border-radius: 0;
                    &:checked {
                        background-color: #fff;
                    }
                }
            }
            &:not(:last-child) {
                margin-right: 15px;
            }
            +.radio {
                margin-top: 10px;
            }
        }
    }
    &-quantity-title {
        margin-top: 30px;
    }
    /** Product Tabs **/
    &-tabs {
        margin: 0 0 20px;
    }
    &-tab {
        &-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: $secondary_font;
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
            color: #1E1E1E;
            .tab-arrow {
                width: 17px;
                height: 17px;
                display: inline-flex;
                svg {
                    width: 17px;
                    height: 17px;
                    display: inline-flex;
                }
            }

        }
        &-content {
            text-align: justify;
            display: none;
            padding: 15px 30px 0 0;
            @media (max-width: $laptop-sm) {
                padding: 10px 20px 0 0;
            }
            @media (max-width: $tablet) {
                padding: 0;
            }
            .panel-body {
                padding: 0;
            }
        }
        &.active {
            .product-tab-content {
                display: block;
                transition: height .03s ease;
            }
            .tab-arrow {
                transform: rotate(-90deg);
                transition: 200ms linear all;
            }
        }
    }
    /** Product Related **/
    &-related {
        margin: 30px 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $tablet) {
            margin: 30px 15px;
        }
        h3 {
            margin: 0;
            font-size: 22px;
            font-weight: 400;
            @media (max-width: $tablet) {
                font-size: 18px;
            }
        }
    }
}
#product {
    .select-option {
        width: 100%;
        max-width: 265px;
    }
}
div.required .control-label:before {
    display: none;
}
/** Size Guide **/
.size {
    &-guide {
        display: none;
        width: 60%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-bottom: 0;
        background-color: #fff;
        color: #000;
        border: none;
        border-radius: 0;
        z-index: 9999;
        max-height: 85vh;
        overflow-y: auto;
        @media (max-width: $mobile) {
            width: 90%;
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        &-trigger {
            text-decoration: underline;
            &:hover, &:focus, &:active {
                text-decoration: underline;
            }
        }
        &.active {
            display: block;
        }
        &-container {
            padding: max(15px, vw(50px));
            border: none;
            .close-size-guide-btn {
                position: absolute;
                top: 15px;
                right: 15px;
                z-index: 99;
                .close-btn-icon {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    svg {
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        .st0 {
                            stroke: $black;
                        }
                    }
                }
            }
        }
    }
}
/** Product Quantity **/
.quantity-input-field {
    width: 100%;
    max-width: 90px;

    .quantity {
        position: relative;
        border: 1px solid $black;

        .number-input {
            position: relative;
        }

        input[type="text"] {
            font-size: 20px;
            background-color: transparent;
            padding: 0;
            color: $black;
            text-align: center;
            box-shadow: none;
            appearance: none;
            border: none;
            border-radius: 0;
            min-height: 35px;
            @media (max-width: $tablet-sm) {
                font-size: 18px;
                min-height: 30px;
            }
        }

        .quantity-nav {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .quantity-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                cursor: pointer;

                &.quantity-down {
                    &:after {
                        content: '';
                        background-image: url('../../../assets/icons/minus-icon.svg');
                        width: 13px;
                        height: 13px;
                        background-position: center;
                        background-repeat: no-repeat;

                        @media (max-width: $mobile) {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                &.quantity-up {
                    &:after {
                        content: '';
                        background-image: url('../../../assets/icons/plus-icon.svg');
                        width: 13px;
                        height: 13px;
                        background-position: center;
                        background-repeat: no-repeat;

                        @media (max-width: $mobile) {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}

/*========================================================*/
/*** SINGLE PRODUCT ***/
/*========================================================*/
.product {
    position: relative;
    height: 100%;
    &-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: end;
        height: 100%;
        &-thumb {
            position: relative;
            object-fit: cover;
            width: 100%;
            min-height: 400px;
            max-height: 582px;
            @media screen and (max-width: $mobile) {
                min-height: unset;
            }
        }
        &-hover {
            position: absolute;
            top:0;
            left: 0;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s linear 300ms, opacity 300ms;
            object-fit: cover;
            width: 100%;
            min-height: 400px;
            max-height: 582px;
            @media screen and (max-width: $tablet) {
               display: none;
            }
        }

        &:hover {
            .product-image-hover {
                visibility: visible;
                opacity: 1;
                transition: visibility 0s linear 0s, opacity 300ms;
                z-index:1;
            }
        }
    }
    &-caption {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 25px;
        width: 100%;
        @media screen and (max-width: $tablet) {
            opacity: 1;
            visibility: visible;
        }
        @media (max-width: $mobile) {
            bottom: 20px;
        }
        .price-new {
            font-size: 16px;
            line-height: 1.3;
        }
        .price-old {
            font-size: 14px;
        }
    }
    &-heading {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
    }
}

#button-cart {
    margin: 30px 0;
}


/** Product Wishlist **/
.product-wishlist {
    display: flex;
    width: 30px;
    height: 28px;
    svg {
        display: flex;
        width: 30px;
        height: 28px;
    }
    &:hover {
        svg {
           fill: $black;
        }
    }
}
/** Swiper Dots **/
.swiper-pagination-bullet {
    position: relative;
    background-color: transparent;
    width: 16px;
    height: 16px;
    border: 1px solid $black;
    &.swiper-pagination-bullet-active {
        background-color: $black;
    }
}

.page_product , .home {
    .product-list__item {
        border-top: 2px solid $black;
    }
}

#product .form-group{
    display: none;
}

#product-product .product-right .has-error .select2-selection.select2-selection--single{
    box-shadow: none !important;
}


@media screen and (max-width: $mobile) {
    #product-category .product-heading{
        font-size: 16px;
    }

    #product-category .product-caption .price-new{
        font-size: 14px;
    }

    #product-category .product-wishlist svg{
        width: 22px;
    }

    #product-category .product-wishlist-btn{
        top: 10px;
        right: 5px;
    }
    #product-category .product-caption {
        bottom: 0;
    }
}