/*========================================================*/
/* Reset */
/*========================================================*/
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*:focus {
    outline: none !important;
}

::selection {
    background: $black;
    color: $white;
}

html {
    font-size: 62.5%;
    line-height: 1.5;
    scroll-behavior: smooth;
}

body {
    font-family: $primary_font;
    font-size: $text_fs;
    color: $black;
    overflow-x: hidden;
    background-color: $background_color;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $secondary_font;
    margin-top: unset;
    font-weight: 400;
    color: $black;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    color: inherit;
}

b,
strong {
    font-weight: 600;
}

ul {
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
    }
}

img,
picture {
    display: block;
    max-width: 100%;
    border: none;
}

.simplebar-mask {
    z-index: unset!important;
    position: unset!important;
}
.simplebar-offset {
    -webkit-overflow-scrolling: auto!important;
}
svg {
    display: inline-block;
}
.bf-disabled {
    display: none!important;
}
::-webkit-scrollbar {
    width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
    background: $beige;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #3a3b3c;
    border-radius: 0;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary_color;
}

