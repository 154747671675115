/*========================================================*/
/*** SEARCH RESULTS ***/
/*========================================================*/
#product-search {
    .product-choices {
        .search-col {
            flex-basis: 33.333%;
            .search-results {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                @media (max-width: $tablet) {
                    align-items: unset;
                }
                .search-input {
                    display: flex;
                    border-bottom: 1px solid $black;
                }
            }
            .search-total {
                display: flex;
                justify-content: flex-end;
                p {
                    margin-bottom: 0;
                }
            }
        }
        @media (max-width: $tablet) {
            align-items: flex-end;
           .search-col {
               &:nth-child(1) {
                   display: none;
               }
           }
        }
    }
    .no-search-items {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 550px;
        padding: 25px;
        text-align: center;
    }

}
.search-input {
    #input-search {
        text-transform: uppercase;
        &::placeholder {
            font-size: 22px;
            text-transform: uppercase;
        }
    }
}