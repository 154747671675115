/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.information {
    &-container {
        padding: 100px 40px;
        @media (max-width: $tablet) {
            padding: 55px 15px;
        }
    }
    &-wrapper {
        display: flex;
        justify-content: space-between;
        max-width: 1110px;
        margin: 0 auto;
        @media (max-width: $tablet-sm) {
            flex-direction: column;
        }
    }
    &-menu {
        flex-basis: 30%;
        margin-right: 30px;
        @media (max-width: $tablet-sm) {
            margin-right: 0;
            margin-bottom: 35px;
        }
        &-wrapper {
            position: sticky;
            top: calc(var(--headerHeight) + 80px);
            li {
                margin-bottom: 10px;
                a {
                    position: relative;
                    display: inline-block;
                    text-transform: uppercase;
                    font-family: var(--secondary-font);
                    font-size: 18px;
                    &.active {
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 1px;
                            background-color: var(--black);
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
    &-content {
        flex-basis: 70%;
        text-align: justify;
        h1, h2, h3, h4, h5, h6 {
            font-family: var(--secondary-font);
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 15px;
        }
    }
}
.information-links:hover{
    cursor: pointer;
}