/* cart page */
#checkout-cart {
    .cart-total {
        margin-top: 35px;
        .col-total {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 400;
            @media (max-width: $mobile) {
                justify-content: space-between;
            }
            &:last-child {
                display: none;
            }
        }
    }

    @media (max-width: $mobile) {
        .flex-row {
            &.buttons {
                flex-direction: column;
                .btn-default {
                    &:first-child {
                        margin-right: 0;
                    }
                    &:nth-child(2) {
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

