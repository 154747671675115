/*========================================================*/
/* Homepage */
/*========================================================*/

.home {
    &-banner {
        position: relative;
        height: 100vh;
        height: var(--screenHeight);
        img, video {
            object-fit: cover;
            width: 100%;
            height: 100vh;
            height: var(--screenHeight);
        }
        .banner {
            &-link {
                font-family: $secondary_font;
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                right: 40px;
                font-size: 30px;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: underline;
                color: $white;
                @media (max-width: $mobile-md) {
                    font-size: 20px;
                }
            }
            &-principles {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 75px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                @media (max-width: $mobile-sm) {
                    flex-wrap: wrap;
                }
                li {
                    font-family: 'Mustica Pro', sans-serif;
                    font-size: max(16px, vw(30px));
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $white;
                    @media (max-width: $mobile) {
                        font-size: 14px;
                    }
                    @media (max-width: $mobile-sm) {
                        font-size: 13px;
                    }
                    &:not(:last-child) {
                        &:after {
                            display: inline-block;
                            content: '·';
                            margin: 0 15px;
                            color: $white;
                            @media (max-width: $mobile-md) {
                                margin: 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    &-section {
        display: flex;
        align-items: center;
        background-color: #1E1E1E;
        @media (max-width: $tablet-sm) {
            flex-direction: column;
        }
        &-left {
            flex-basis: 50%;
            img {
                object-fit: cover;
                width: 100%;
            }
        }
        &-right {
            flex-basis: 50%;
            position: relative;
            img {
                position: relative;
            }
            &-content {
                position: absolute;
                width: 100%;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-transform: uppercase;
                color: $white;
                text-align: center;
                padding: 75px 30px;
                max-width: 705px;
                margin: 0 auto;
                .logo-icon {
                    margin: 0 auto 30px;
                }
                p {
                    font-family: $secondary_font;
                    margin-bottom: 30px;
                }
            }
        }
    }
    footer {
        border: none;
    }
}

/*swiper arrows*/
.swiper-button-prev {
    width: 63px!important;
    height: 63px!important;
    &::after {
        display: inline-block;
        content: ""!important;
        background-image: url('../../../icons/carousel-arrow.svg');
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
        background-repeat: no-repeat;
        width: 63px;
        height:  63px;
        @media (max-width: $laptop-sm) {
            background-image: url('../../../icons/carousel-arrow-md.svg');
            width: 50px;
            height:  50px;
        }
    }
    @media (max-width: $tablet-sm) {
        display: none!important;
    }
}
.swiper-button-next {
    width: 63px!important;
    height: 63px!important;
    &::after {
        display: inline-block;
        content: ""!important;
        background-image: url('../../../icons/carousel-arrow.svg');
        background-repeat: no-repeat;
        width: 63px;
        height:  63px;
        @media (max-width: $laptop-sm) {
            background-image: url('../../../icons/carousel-arrow-md.svg');
            width: 43px;
            height:  43px;
        }
    }
    @media (max-width: $tablet-sm) {
        display: none!important;
    }
}
