.checkout-container {
    @media (max-width: $tablet) {
        padding-top: calc(var(--header-height) + 20px);
    }

    #main-header,
    #search {
        display: none;
    }

    #d_logo {
        width: 100%;
        flex-basis: 50%;
        display: flex;
        justify-content: center;

        a {
            display: inline-block;
            margin: 0 auto;
            max-width: clamp(100px, 12.5vw, 240px);
            width: 100%;
            transition: width 0.3s ease;
        }

        @media (max-width: $tablet) {
            order: 1;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            margin: 0;
            z-index: 99;
            padding: 15px 0;
            background-color: $light_beige;

            a {
                width: 100px;

                &.scrolled {
                    width: 85px;
                }
            }
        }
    }

    .checkout-header {
        display: flex;
        align-items: center;
        margin: 0 0 clamp(25px, 5.2083vw, 100px);
        padding: 15px 0;

        @media (max-width: $tablet) {
            flex-direction: column;
            background-color: $light_beige;

            .back-to-home {
                order: 2;
                margin: 0 0 5px;
            }

            .phone-support {
                order: 3;
            }
        }

        .back-to-home {
            flex-basis: 25%;
        }

        .phone-support {
            flex-basis: 25%;

            br {
                display: none;

                @media (max-width: $laptop-sm) {
                    display: block;
                }
            }

            .support-icon {
                position: relative;
                top: 5px;
                margin: 0 5px;
            }

            a:last-child {
                text-decoration: none;
            }
        }
    }

    .checkout-title {
        max-width: 1515px;
        width: 100%;
        margin: 0 auto 40px;

        @media (max-width: $tablet) {
            text-align: center;
            font-size: 2.4rem;
        }
    }

    .checkout-wrapper {
        max-width: 1515px;
        width: 100%;
        margin: 0 auto;
    }

    .alert {
        margin: 0 0 20px;
    }

    .radio-input {
        display: flex;
        align-items: center;
    }

    #payment_view {
        padding: 0 25px 50px;

        @media (max-width: $laptop-xs) {
            padding: 0 0 25px;
        }
    }

    #d_quickcheckout .text {
        margin-bottom: 0;
    }

    #d_quickcheckout .price {
        margin: 0 0 0 5px;
    }

    #d_quickcheckout input[type='radio'],
    #d_quickcheckout input[type='checkbox'] {
        margin-right: 5px;
    }

    .header-2 {
        margin: 0;
    }

    a {
        color: $primary_color;
        text-decoration: underline;
    }

    .guest-login-register {
        display: flex;

        .btn-group {
            flex-basis: 33.333333%;

            @media (max-width: $mobile) {

                &:nth-child(1),
                &:nth-child(2) {
                    flex-basis: 50%;
                    margin: 0 0 5px;
                }

                &:nth-child(3) {
                    flex-basis: 100%;
                }
            }
        }
    }

    .checkout-left {
        margin-right: clamp(20px, 2.3438vw, 45px);
        width: calc(50% - clamp(20px, 2.3438vw, 45px));
        background-color: $white;

        @media (max-width: $tablet) {
            background-color: $light_beige;
            border-right: unset;
            padding-right: 0;
            padding-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }

    .checkout-right {
        padding-left: clamp(20px, 2.3438vw, 45px);

        @media (max-width: $tablet) {
            padding-right: 0;
            padding-left: 0;
        }

        .theiaStickySidebar {
            background-color: $light_beige;
        }
    }

    #d_quickcheckout {
        padding: 0 $side_padding_checkout clamp(25px, 5.2083vw, 100px);
        max-width: 1800px;
        width: 100%;

        @media (max-width: $tablet-sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    // Bootstrap fixes
    .form-horizontal {

        .radio,
        .checkbox,
        .radio-inline,
        .checkbox-inline {
            padding-top: 0;
        }

        @media (max-width: $tablet-sm) {
            .form-group {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    .well {
        box-shadow: none;
    }

    #qc_confirm_order {
        padding: 20px 15px;

        &:disabled {
            color: $border_color;
            background-color: $white;
            border-color: $border_color;
            transition: all linear 0.3s;
        }

        @media (max-width: $mobile) {
            padding: 15px;
        }
    }

    .text-danger {
        margin: 0 !important;
        font-size: 1.2rem;
    }

    button {

        &.decrease,
        &.increase,
        &.delete {
            background-color: unset;
            background-image: none;
            background-repeat: no-repeat;
            border: none;
            border-color: unset;
        }

        &.delete {
            @media (max-width: $tablet) {
                display: none;
            }
        }
    }

    .panel {
        border-radius: 0;
        border: none;
        background: none;
        box-shadow: none;
        padding: 0 25px;

        @media (max-width: $laptop-xs) {
            padding: 0;
        }

        @media (max-width: $tablet-sm) {
            margin-bottom: 0;
        }

        .panel-body {
            padding: 15px 0;

            @media (max-width: $tablet-sm) {
                padding: 10px;
            }
        }

        .panel-heading {
            background-color: unset;
            border-radius: 0;
            border: none;
            padding: 10px 0;
            margin-top: 30px;

            @media (max-width: $tablet-sm) {
                margin-top: 15px;
                padding: 10px 10px 0 10px;
            }
        }
    }

    #payment_view {
        .header-2 {
            margin: 30px 0 30px;

            @media (max-width: $tablet-sm) {
                margin: 15px 0 20px;
            }
        }
    }

    #shipping_address,
    #shipping_method,
    #payment_method {
        .panel-body {
            border-bottom: 1px solid #ececec;
        }
    }

    #shipping_method {
        strong.title {
            display: none;
        }
    }

    #payment_address_heading_heading>i {
        display: none;
    }

    .btn {
        box-shadow: none !important;
        text-shadow: none !important;
    }

    // Guest / Login / Register
    #login_view {
        margin: 0 -16px;

        @media (max-width: $tablet) {
            margin: unset;
        }

        .guest-login-register {
            display: flex;
            flex-wrap: wrap;

            .btn-default {
                background-color: $light_beige;
                border: none;
                color: $black;
                font-family: $primary_font;
                font-size: 1.6rem;
                font-weight: 400;
                letter-spacing: 0.5px;
                padding: 20px 25px 20px 40px;
                text-align: left;

                @media (max-width: $laptop-xs) {
                    padding: 20px 25px 20px 15px;
                }

                &.active {
                    background-color: $white;
                    position: relative;
                    text-decoration: underline;
                }
            }

            @media (max-width: $tablet) {
                flex-direction: column;

                .btn-default {
                    background-color: transparent;
                    padding: 10px 20px;

                    &.active {
                        background-color: transparent;
                        text-decoration: none;
                        font-weight: 600;
                    }
                }

                .btn-group {
                    flex-basis: 100%;
                    margin: 0 0 5px;
                    border-bottom: 1px solid $light_gray;
                }
            }
        }
    }

    #login_social_login {
        background-color: $light_beige;
        margin: 0 -16px;
        padding-bottom: 15px;

        @media (max-width: $tablet) {
            margin: 0;
        }

        #d_social_login {
            max-width: unset;
            margin: 0;

            .d-social-login__button .d-social-login__button__icon {
                font-size: 1.5rem;
                text-align: center;
                padding: 22px 20px;
                max-height: unset;
                background-color: $blue;
            }
        }
    }

    #cart_view {
        .qc-name a {
            text-decoration: none;
            font-size: 1.4rem;
            font-family: $secondary_font;
            color: $black;
        }

        thead td.qc-image {
            opacity: 0;
        }

        .table {
            margin-bottom: 50px;
            border: none;

            @media (max-width: $mobile) {
                font-size: 1.1rem;
            }

            td {
                border: none;

                @media (max-width: $mobile) {
                    font-size: 1.1rem;
                }

                &.qc-image {
                    padding: 15px 0;

                    @media (max-width: $mobile) {
                        max-width: 60px;
                    }
                }

                &.qc-quantity {
                    @media (max-width: $mobile) {
                        width: 22%;

                        .input-group {
                            max-width: 65px;
                        }
                    }
                }
            }

            thead {
                border-bottom: 1px solid $light_gray;

                td {
                    padding-bottom: 18px;

                    &.qc-image {
                        padding: 10px 0;
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid $light_gray;
                }

                td {
                    padding: 15px 8px;
                }
            }
        }

        .qc-image {
            width: 80px;

            a {
                display: inline-block;
            }

            img {
                mix-blend-mode: multiply;
            }
        }

        .qc-name-price {
            display: none !important;
        }

        .qc-coupon {
            margin: 0 0 25px;

            .col-sm-12 {
                padding: 0;
            }

            #coupon,
            #confirm_coupon {
                height: 60px;
            }

            #coupon {
                width: 100%;
                min-height: 35px;
                padding: 15px;

                @media (max-width: $tablet) {
                    max-width: unset;
                    padding: 20px 15px;
                    height: unset;
                }
            }

            #confirm_coupon {
                display: block;
                max-width: 135px;
                width: 100%;
                padding: 5px 20px;
                height: 60px;

                @media (max-width: $tablet) {
                    max-width: unset;
                    padding: 20px 15px;
                    height: unset;
                }
            }

            .coupon-container {
                @media (max-width: $tablet) {
                    display: flex;
                    flex-direction: column;

                    >input {
                        margin-bottom: 10px;
                    }

                    .input-group-btn {
                        width: 100%;
                        margin: 0 0 5px;
                    }
                }
            }
        }

        .qc-totals {
            .row {
                margin: 0;

                font-size: $header_3_fs;
                text-align: right;
                padding: 20px 0;

                &:last-child {
                    padding: 20px 0;
                    border-top: 1px solid $light_gray;
                    border-bottom: 1px solid $light_gray;

                    label {
                        font-weight: 500;
                        font-size: 1.5rem;
                        text-transform: uppercase;
                    }

                    div {
                        font-weight: 500;
                        font-size: 1.5rem;
                    }
                }

                label {
                    text-align: left !important;
                    font-weight: 500;
                    font-size: 1.4rem;
                    padding: 5px 0;
                }

                div {
                    font-size: 1.4rem;
                }
            }
        }

        .quantity-checkout {
            position: relative;
            max-width: 70px;
            height: 34px;

            .quantity input[type='text'].form-control {
                border: 1px solid $light_gray;
                border-radius: $border_radius_input;
                padding: 10px 5px;
                text-align: center;
                max-width: 70px;
                max-height: 34px;
            }

            button {
                position: absolute;
                z-index: 9;
                top: 45%;
                transform: translateY(-50%);
                cursor: pointer;

                &.decrease {
                    left: 5px;
                }

                &.increase {
                    right: 5px;
                }
            }
        }

        .qc-product-qantity {
            max-width: 75px;
            min-height: 35px;
            padding: 5px;
        }

        .qc-quantity {
            width: 27%;

            .input-group.input-group-sm {
                max-width: 150px;
            }
        }

        .qc-checkout-product table.table tbody td {
            vertical-align: middle;
        }
    }

    @media (max-width: $tablet) {

        label[for='payment_address_agree'],
        label[for='payment_address_shipping_address'],
        label[for='confirm_agree'] {
            input[type='checkbox'] {
                flex-basis: 5%;
                max-width: 16px;
            }

            span {
                flex-basis: 95%;
                text-align: left;
            }
        }
    }

    .form-group.required .control-label:after {
        content: '';
    }

    .form-group.required .control-label span:after {
        content: ' *';
        color: $light_gray;
    }

    #payment_address_form,
    #shipping_address_form {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
    }

    #payment_address_form>div,
    #shipping_address_form>div {
        flex-basis: calc(50% + 15px);
    }

    #payment_address_newsletter_input,
    #payment_address_customer_group_id_input,
    #payment_address_shipping_address_input,
    #payment_address_agree_input,
    #payment_address_heading_heading {
        flex-basis: calc(100% + 30px) !important;
    }

    #payment_address_customer_group_id_input .col-xs-12:first-child {
        display: none;
    }

    #confirm_form {
        margin-bottom: clamp(30px, 3.125vw, 60px);
    }

    #confirm_comment {
        min-height: 140px;
        resize: vertical;
    }

    #payment_address_newsletter_input,
    #payment_address_shipping_address_input,
    #payment_address_agree_input,
    #payment_address_heading_heading {
        label {
            text-align: left;
        }
    }

    #payment_address_heading_heading {
        margin: 30px 0 20px;

        @media (max-width: $tablet-sm) {
            margin: 20px 0 10px;
        }
    }

    #login_model {
        top: 50%;
        height: fit-content;
        transform: translateY(-50%);
    }

    @media (max-width: $tablet-sm) {

        #payment_address_form>div,
        #shipping_address_form>div,
        #custom-fields-account-invoice>div {
            flex-basis: calc(100% + 30px) !important;
        }

        #payment_address_form>div>div,
        #shipping_address_form>div>div,
        #confirm_comment_input>div,
        #confirm_agree_input>div {
            padding: 0;
        }

        #payment_view {
            padding: 10px;
        }
    }
}

.gift-packaging {
    margin: 10px 0;

    .control-label {
        align-items: baseline;
        font-size: 1.2rem;
    }
}

#banner-easy-returns {
    position: relative;
    margin: 30px auto;
    padding: 30px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:after {
        content: "";
        width: 98%;
        height: 93%;
        border: 1px solid $white;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    h5 {
        font-family: var(--primary-font), sans-serif;
        font-size: 1.8rem;
        font-weight: 500;
        color: #fff;
        max-width: 430px;
        margin: 10px auto 25px;
    }

    a {
        &.btn-default {
            position: relative;
            z-index: 1;
            text-decoration: none;
            padding: 10px;
            margin-bottom: 10px;
        }

    }
}

span.select2.select2-container.select2-container--default {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    width: 100% !important;
    border: none;
    border-bottom: 1px solid $black;
    padding: 10px 0;
    height: unset;
    line-height: 1.5;
    background-color: transparent;
    box-shadow: none;
    border-radius: var(--input-border-radius);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../assets/icons/new-arrow-down.svg') !important;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;
}
.select2-container--default.select2-container--open.select2-container--above {
    border: 1px solid $black;
    border-bottom: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: unset;
    padding: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $black;
    color: white;
}
.select2-container--default .select2-results__option--selected {
    background-color: $white;
}
.select2-search--dropdown, .select2-container--default .select2-search--dropdown .select2-search__field {
    background-color: $white;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    font-size: var(--input-font);
    font-weight: 400;
    color: var(--black);
}
//.select2-container--default .select2-selection--single .select2-selection__clear::after {
//    display: inline-block;
//    content: ' ';
//    background: url('../../../icons/remove-icon.svg');
//    background-repeat: no-repeat;
//    height: 12px;
//    width: 12px;
//    margin-left: 15px;
//    transition: all 0.5s ease-out;
//}
.select2-container--default .select2-selection--single .select2-selection__clear span {
    display: none;
}

.has-error .select2-selection.select2-selection--single {
    border-color: #a94442 !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
}

.custom-field-custom-red-box {
    border-color: #a94442 !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
}

.has-error .form-control {
    border-color: #a94442 !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
}

#checkout-loader {
    display: none;
}

#checkout-loader.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgb(250 247 243 / 40%);
}

#checkout-checkout {
    padding-top: max(30px, vw(50px));
    padding-bottom: max(30px, vw(50px));

    .alert.alert-success.success {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: max(30px, vw(50px));
        background: transparent;
        color: $primary_color;
        border-color: $primary_color;
    }

    .alert.alert-danger.alert-dismissible {
        margin-left: 0;
        margin-right: 0;
        background: transparent;
        border-radius: unset;
        color: #a94442;
        border-color: #a94442;
    }

    .main_heading_title {
        margin-bottom: 30px;
    }

    #accordion {
        .panel {
            background-color: unset;
            border-radius: unset;
            border-color: #6a6b59;

            @media screen and (max-width: 768px) {
                &:first-child {
                    .panel-body {
                        .row {
                            [class*="col-"] {
                                &:first-child {
                                    order: 2;
                                    margin-bottom: unset !important;
                                }

                                &:last-child {
                                    order: 1;
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                }
            }

            .panel-heading {
                background-color: unset;

                .panel-title {
                    @extend .panel-title,
                    .header-2;
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: bold;

                    .accordion-toggle {
                        &:after {
                            content: '';
                            position: relative;
                            padding: 15px;
                            line-height: 1.5;
                            background-image: url('../../../assets/icons/new-arrow-down.svg') !important;
                            background-repeat: no-repeat;
                            background-position: calc(100% - 10px) center;
                            background-size: 10px;
                        }

                        .fa {
                            display: none;
                        }
                    }
                }
            }

            .panel-collapse {

                &.collapse {
                    &.in {
                        .panel-body {

                            .row {
                                display: flex;

                                @media screen and (max-width: 768px) {
                                    flex-direction: column;
                                }

                                [class*="col-"] {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    justify-content: space-between;


                                    @media screen and (max-width: 768px) {
                                        min-width: 100%;

                                        &:first-of-type {
                                            margin-bottom: 30px;
                                        }
                                    }

                                    &>* {
                                        &:not([type="button"]) {
                                            width: 100%;
                                        }
                                    }

                                    fieldset#address {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        height: 100%;
                                    }
                                }
                            }

                            .checkbox {
                                line-height: 1;
                            }

                            .radio,
                            .checkbox {
                                label {
                                    &:first-child {
                                        margin-right: 15px;
                                    }
                                }
                            }

                            .checkbox-outer-wrap,
                            .payment-methods-block {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                @media screen and (max-width: 768px) {
                                    flex-direction: column;

                                    [type="button"] {
                                        margin-top: 15px;
                                        align-self: flex-end;
                                    }
                                }

                                .checkbox-wrap,
                                label {
                                    display: flex;
                                    flex: 1;
                                    font-weight: initial;

                                    @media screen and (max-width: 768px) {
                                        width: 100%;
                                    }

                                    [type="checkbox"] {
                                        margin-top: 3px;
                                    }
                                }
                            }

                            .well {
                                background-color: var(--light-brown);
                                border-color: $primary_color;
                            }

                            [type="button"] {

                                &:not([data-dismiss="alert"]) {
                                    @extend .btn,
                                    .btn-default;
                                    width: auto;

                                    &:hover {
                                        color: var(--oil) !important;
                                        background-color: transparent !important;
                                        border-color: var(--oil) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.alert-message-danger{
    color: #e45451;
    font-weight: bold;
}
.coupon-messages{
    padding: 0;
}
.coupon-messages .alert{
    margin: 0 !important;
    margin-bottom: 10px !important;
}
.quickcheckout-content .checkbox__wrap.newsletter-checkout-box{
    display: none ;
}

.field__wrap--input.required .control-label:after {
    content: " *";
    color: var(--gray);
}
.checkout-message{
    padding: 15px;
    border: 1px solid;
    margin-bottom: 15px;
    font-size: var(--input-font);
}