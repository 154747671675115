@charset "UTF-8";
@font-face {
  font-family: "Mustica Pro";
  src: url("../../../fonts/MusticaPro-SemiBold.eot");
  src: url("../../../fonts/MusticaPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/MusticaPro-SemiBold.woff2") format("woff2"), url("../../../fonts/MusticaPro-SemiBold.woff") format("woff"), url("../../../fonts/MusticaPro-SemiBold.ttf") format("truetype"), url("../../../fonts/MusticaPro-SemiBold.svg#MusticaPro-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neutral Face";
  src: url("../../../fonts/NeutralFaceRegular.eot");
  src: url("../../../fonts/NeutralFaceRegular.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeutralFaceRegular.woff2") format("woff2"), url("../../../fonts/NeutralFaceRegular.woff") format("woff"), url("../../../fonts/NeutralFaceRegular.ttf") format("truetype"), url("../../../fonts/NeutralFaceRegular.svg#NeutralFaceRegular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neutral Face";
  src: url("../../../fonts/NeutralFaceBold.eot");
  src: url("../../../fonts/NeutralFaceBold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/NeutralFaceBold.woff2") format("woff2"), url("../../../fonts/NeutralFaceBold.woff") format("woff"), url("../../../fonts/NeutralFaceBold.ttf") format("truetype"), url("../../../fonts/NeutralFaceBold.svg#NeutralFaceBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../fonts/Poppins-Regular.eot");
  src: url("../../../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Poppins-Regular.woff2") format("woff2"), url("../../../fonts/Poppins-Regular.woff") format("woff"), url("../../../fonts/Poppins-Regular.ttf") format("truetype"), url("../../../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../fonts/Poppins-Italic.eot");
  src: url("../../../fonts/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Poppins-Italic.woff2") format("woff2"), url("../../../fonts/Poppins-Italic.woff") format("woff"), url("../../../fonts/Poppins-Italic.ttf") format("truetype"), url("../../../fonts/Poppins-Italic.svg#Poppins-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../fonts/Poppins-Medium.eot");
  src: url("../../../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Poppins-Medium.woff2") format("woff2"), url("../../../fonts/Poppins-Medium.woff") format("woff"), url("../../../fonts/Poppins-Medium.ttf") format("truetype"), url("../../../fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../../../fonts/Poppins-SemiBold.eot");
  src: url("../../../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../../../fonts/Poppins-SemiBold.woff") format("woff"), url("../../../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../../../fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
:root {
  --black: #1E1E1E;
  --white: #f5f3f0;
  --gray: #727272;
  --light-gray: #dddddd;
  --beige: #efece8;
  --light-beige: #faf7f3;
  --oil: #6a6b59;
  --dark-oil: #3c3d32;
  --light-brown: #e3dbcf;
  --red: #c75959;
  --blue: #527bb9;
  --primary-color: var(--black);
  --secondary-color: var(--beige);
  --input-border-color: var(--oil);
  --input-border-color-focus: var(--oil);
  --input-placeholder-color: transparent;
  --background-color: var(--white);
  --footer-background-color: var(--white);
  --promo-bar-background-color: var(--black);
  --primary-font: "Poppins", sans-serif;
  --secondary-font: "Neutral Face", sans-serif;
  --header-1: clamp(3.2rem, 2.2917vw, 4.4rem);
  --header-2: clamp(2.4rem, 1.4583vw, 2.8rem);
  --header-3: 1.5rem;
  --header-4: 1.4rem;
  --banner-title: clamp(3rem, 4.1667vw, 8rem);
  --hero-title: clamp(3rem, 2.6042vw, 5rem);
  --text: 18px;
  --text-small: 1.2rem;
  --text-large: 1.8rem;
  --btn-font: 22px;
  --input-font: 1.5rem;
  --modal-title: clamp(2.4rem, 1.8229vw, 3.5rem);
  --input-border-radius: 0px;
  --button-border-radius: 0px;
  --side-padding: clamp(10px, 3.9063vw, 75px);
  --side-padding-header: clamp(10px, 3.9063vw, 75px);
  --side-padding-footer: clamp(10px, 3.9063vw, 75px);
  --side-padding-checkout: clamp(30px, 4.1667vw, 80px);
}

/*========================================================*/
/* Colors */
/*========================================================*/
/*========================================================*/
/* Fonts */
/*========================================================*/
/*========================================================*/
/* Layout */
/*========================================================*/
/*========================================================*/
/* Breakpoints */
/*========================================================*/
/*========================================================*/
/* Responsive Variables */
/*========================================================*/
@media (max-width: 1280px) {
  :root {
    --text: 16px;
  }
}
@media (max-width: 1024px) {
  :root {
    --side-padding-footer: 15px;
  }
}
@media (max-width: 660px) {
  :root {
    --input-font: 1.4rem;
  }
}
@media (max-width: 421px) {
  :root {
    --side-padding: 10px;
  }
}

/*========================================================*/
/* Design Specs */
/*========================================================*/
.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.light {
  font-weight: 300;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.extra-bold {
  font-weight: 900;
}

.primary {
  color: var(--primary-color);
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.gray {
  color: var(--gray);
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.header-1, #post .section-wrap.the-post h1, #blog .section-wrap h1, #contact-us .section-wrap h1, #quickcheckoutconfirm .checkout__main-title {
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--header-1);
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1.2;
  margin: 0 0 10px;
}

.header-2, #contact-us .section-wrap .contact-block form fieldset > span, #checkout-checkout #accordion .panel .panel-heading .panel-title, .post-link > span:first-of-type {
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--header-2);
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 15px;
}

.header-3 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-3);
  font-weight: 500;
  line-height: 1.5;
  margin: 0 0 5px;
}

.header-4 {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--header-4);
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 1.2;
  margin: 0 0 15px;
}

.hero-title {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--hero-title);
  font-weight: 600;
  color: inherit;
  line-height: 1.2;
  margin: 0 auto 20px;
}

.banner-title {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--banner-title);
  font-weight: 600;
  line-height: 1.2;
  margin: 0 auto 40px;
}

p,
.text {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text);
  margin: 0 0 10px;
}

.text-small {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text-small);
  line-height: 1.8;
  margin: 0 0 10px;
}

.text-large {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text-large);
  line-height: 1.8;
  margin: 0 0 10px;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea,
select,
label.control-label,
input[type=text].form-control,
input[type=password].form-control,
input[type=tel].form-control,
input[type=email].form-control,
textarea.form-control,
select.form-control,
.radio label,
.checkbox label,
label.control-label {
  font-size: var(--input-font);
  font-weight: 400;
  color: var(--black);
}

.radio label,
.checkbox label,
label.control-label {
  font-weight: 600;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea,
select,
input[type=text].form-control,
input[type=password].form-control,
input[type=tel].form-control,
input[type=email].form-control,
textarea.form-control,
select.form-control {
  border: none;
  border-bottom: 1px solid var(--black);
  padding: 11px 0;
  height: unset;
  background-color: transparent;
  box-shadow: none;
  border-radius: var(--input-border-radius);
}
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=tel]::placeholder,
input[type=email]::placeholder,
textarea::placeholder,
select::placeholder,
input[type=text].form-control::placeholder,
input[type=password].form-control::placeholder,
input[type=tel].form-control::placeholder,
input[type=email].form-control::placeholder,
textarea.form-control::placeholder,
select.form-control::placeholder {
  color: var(--input-placeholder-color);
  font-weight: 400;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=tel]:focus,
input[type=email]:focus,
textarea:focus,
select:focus,
input[type=text].form-control:focus,
input[type=password].form-control:focus,
input[type=tel].form-control:focus,
input[type=email].form-control:focus,
textarea.form-control:focus,
select.form-control:focus {
  box-shadow: none;
  border-color: var(--black);
}
input[type=text]:-webkit-autofill, input[type=text]:-webkit-autofill:hover, input[type=text]:-webkit-autofill:focus,
input[type=password]:-webkit-autofill,
input[type=password]:-webkit-autofill:hover,
input[type=password]:-webkit-autofill:focus,
input[type=tel]:-webkit-autofill,
input[type=tel]:-webkit-autofill:hover,
input[type=tel]:-webkit-autofill:focus,
input[type=email]:-webkit-autofill,
input[type=email]:-webkit-autofill:hover,
input[type=email]:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input[type=text].form-control:-webkit-autofill,
input[type=text].form-control:-webkit-autofill:hover,
input[type=text].form-control:-webkit-autofill:focus,
input[type=password].form-control:-webkit-autofill,
input[type=password].form-control:-webkit-autofill:hover,
input[type=password].form-control:-webkit-autofill:focus,
input[type=tel].form-control:-webkit-autofill,
input[type=tel].form-control:-webkit-autofill:hover,
input[type=tel].form-control:-webkit-autofill:focus,
input[type=email].form-control:-webkit-autofill,
input[type=email].form-control:-webkit-autofill:hover,
input[type=email].form-control:-webkit-autofill:focus,
textarea.form-control:-webkit-autofill,
textarea.form-control:-webkit-autofill:hover,
textarea.form-control:-webkit-autofill:focus,
select.form-control:-webkit-autofill,
select.form-control:-webkit-autofill:hover,
select.form-control:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--black);
  box-shadow: 0 0 0 1000px var(--light-gray) inset;
  border-bottom: 1px solid var(--black);
}
input[type=text]:-webkit-autofill::selection, input[type=text]:-webkit-autofill:hover::selection, input[type=text]:-webkit-autofill:focus::selection,
input[type=password]:-webkit-autofill::selection,
input[type=password]:-webkit-autofill:hover::selection,
input[type=password]:-webkit-autofill:focus::selection,
input[type=tel]:-webkit-autofill::selection,
input[type=tel]:-webkit-autofill:hover::selection,
input[type=tel]:-webkit-autofill:focus::selection,
input[type=email]:-webkit-autofill::selection,
input[type=email]:-webkit-autofill:hover::selection,
input[type=email]:-webkit-autofill:focus::selection,
textarea:-webkit-autofill::selection,
textarea:-webkit-autofill:hover::selection,
textarea:-webkit-autofill:focus::selection,
select:-webkit-autofill::selection,
select:-webkit-autofill:hover::selection,
select:-webkit-autofill:focus::selection,
input[type=text].form-control:-webkit-autofill::selection,
input[type=text].form-control:-webkit-autofill:hover::selection,
input[type=text].form-control:-webkit-autofill:focus::selection,
input[type=password].form-control:-webkit-autofill::selection,
input[type=password].form-control:-webkit-autofill:hover::selection,
input[type=password].form-control:-webkit-autofill:focus::selection,
input[type=tel].form-control:-webkit-autofill::selection,
input[type=tel].form-control:-webkit-autofill:hover::selection,
input[type=tel].form-control:-webkit-autofill:focus::selection,
input[type=email].form-control:-webkit-autofill::selection,
input[type=email].form-control:-webkit-autofill:hover::selection,
input[type=email].form-control:-webkit-autofill:focus::selection,
textarea.form-control:-webkit-autofill::selection,
textarea.form-control:-webkit-autofill:hover::selection,
textarea.form-control:-webkit-autofill:focus::selection,
select.form-control:-webkit-autofill::selection,
select.form-control:-webkit-autofill:hover::selection,
select.form-control:-webkit-autofill:focus::selection {
  -webkit-text-fill-color: var(--white);
}

textarea {
  border: 1px solid var(--black) !important;
  padding: 10px !important;
  margin-top: 5px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 15px;
  background-image: url("../../../assets/icons/new-arrow-down.svg") !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 10px;
}
select::-webkit-scrollbar {
  width: 5px;
}
select::-webkit-scrollbar-track {
  opacity: 0.8;
  background: var(--light-beige);
}
select::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: var(--primary-color);
}
select::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}

.form-horizontal .control-label {
  padding-top: 0;
  text-align: left;
}

.form-group.required .control-label:after {
  content: " *";
  color: var(--gray);
}

/* RADIO */
.radio-inline,
.radio > label {
  display: flex;
  align-items: center;
  margin-left: 0 !important;
}

input[type=radio] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--light-gray);
  margin: 0;
  font: inherit;
  color: var(--light-gray);
  width: 16px;
  height: 16px;
  border: 2px solid var(--light-gray);
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-content: center;
}
input[type=radio]:checked {
  background-color: var(--light-brown);
  border: 2px solid var(--light-brown);
}

input[type=radio]::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--black);
}

input[type=radio]:checked::before {
  transform: scale(1);
}

.product-plated-option input[type=radio]::before {
  box-shadow: none;
}

input[type=radio]:focus {
  outline: 2px solid var(--light-gray);
  outline-offset: 2px;
}

/* CHECKBOX */
input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--light-gray);
  margin: 0 10px 0 0;
  font: inherit;
  color: var(--light-gray);
  width: 100%;
  max-width: 16px;
  height: 16px;
  border: 2px solid var(--light-gray);
  border-radius: 2px;
  display: grid !important;
  place-content: center;
  cursor: pointer;
}
input[type=checkbox]:checked {
  background-color: var(--light-brown);
  border: 2px solid var(--light-brown);
}

input[type=checkbox]::before {
  content: "";
  width: 8px;
  height: 8px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--black);
}

input[type=checkbox]:checked::before {
  transform: scale(1);
}

input[type=checkbox]:focus {
  outline: 2px solid currentColor;
  outline-offset: 2px;
}

input[type=checkbox]:disabled {
  color: #ff0000;
  cursor: not-allowed;
}

#payment_address_shipping_address_input label, #payment_address_agree_input label {
  display: flex;
  align-items: baseline;
}

label[for^=payment],
label[for=free_checkout],
label[for=confirm_agree],
label[for=bank_transfer],
label[for=cod],
.radio-input.radio label {
  display: flex;
  align-items: baseline;
}
label[for^=payment] input[type=radio],
label[for=free_checkout] input[type=radio],
label[for=confirm_agree] input[type=radio],
label[for=bank_transfer] input[type=radio],
label[for=cod] input[type=radio],
.radio-input.radio label input[type=radio] {
  top: 5px;
}
label[for^=payment] input[type=radio],
label[for^=payment] input[type=checkbox],
label[for=free_checkout] input[type=radio],
label[for=free_checkout] input[type=checkbox],
label[for=confirm_agree] input[type=radio],
label[for=confirm_agree] input[type=checkbox],
label[for=bank_transfer] input[type=radio],
label[for=bank_transfer] input[type=checkbox],
label[for=cod] input[type=radio],
label[for=cod] input[type=checkbox],
.radio-input.radio label input[type=radio],
.radio-input.radio label input[type=checkbox] {
  margin-right: 5px;
  display: grid !important;
}

select#user_selected_installments {
  padding-right: 30px;
}

label {
  font-size: 16px;
  width: 100%;
  margin: 0;
}

.input-group.date {
  display: inline-flex;
}
.input-group.date .btn-default, .input-group.date #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .input-group.date button, .input-group.date #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .input-group.date [type=button], .input-group.date #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .input-group.date [type=button]:not([data-dismiss=alert]) {
  margin: 0;
  flex-basis: 15%;
  padding: 10px;
}

.modal-open {
  overflow: hidden;
}

.modal-title {
  font-family: var(--secondary-font), sans-serif;
  font-size: 2.6rem;
  font-weight: 500;
}

.modal-body {
  color: var(--black);
  text-align: justify;
  padding: clamp(15px, 2.0833vw, 40px);
}
.modal-body h2,
.modal-body h2,
.modal-body h3,
.modal-body h4,
.modal-body h5,
.modal-body h6 {
  font-family: var(--secondary-font), sans-serif;
  font-size: 2rem;
  font-weight: 500 !important;
  color: var(--black) !important;
}

.modal-header {
  position: relative;
}
.modal-header .close {
  opacity: 1;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
}
.modal-header ._close {
  display: flex;
  width: 16px;
  height: 16px;
}
.modal-header ._close svg {
  display: flex;
  width: 16px;
  height: 16px;
}

.modal-content {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}

/*========================================================*/
/* Reset */
/*========================================================*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

::selection {
  background: var(--black);
  color: var(--white);
}

html {
  font-size: 62.5%;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  font-family: var(--primary-font), sans-serif;
  font-size: var(--text);
  color: var(--black);
  overflow-x: hidden;
  background-color: var(--background-color);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondary-font), sans-serif;
  margin-top: unset;
  font-weight: 400;
  color: var(--black);
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

b,
strong {
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}

img,
picture {
  display: block;
  max-width: 100%;
  border: none;
}

.simplebar-mask {
  z-index: unset !important;
  position: unset !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: auto !important;
}

svg {
  display: inline-block;
}

.bf-disabled {
  display: none !important;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--beige);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3a3b3c;
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/*========================================================*/
/* Layout*/
/*========================================================*/
.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.inline-flex {
  display: inline-flex;
  align-items: center;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.float-right {
  float: right;
}

.side-padding {
  padding-right: var(--side-padding);
  padding-left: var(--side-padding);
}

.m0 {
  margin: 0;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.p0 {
  padding: 0;
}

.mobile-account-items {
  display: none;
}

/*========================================================*/
/*** Default Elements ***/
/*========================================================*/
.btn-default, #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm [type=button], #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]) {
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 24px 15px 23px;
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--btn-font);
  font-weight: 400;
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
  border-radius: var(--button-border-radius);
  background-image: none;
  text-decoration: none;
  line-height: 1;
  transition: ease 0.3s;
  min-height: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (max-width: 991px) {
  .btn-default, #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm [type=button], #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]) {
    padding: 13px 15px 10px;
    font-size: 20px;
  }
}
.btn-default:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button:hover, #quickcheckoutconfirm [type=button]:hover, #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:hover:not([data-dismiss=alert]) {
  color: var(--black);
  background-color: transparent;
  border-color: var(--black);
}
.btn-default.reverse, #quickcheckoutconfirm .popup-login .login-fields--wrap button.reverse, #quickcheckoutconfirm .reverse[type=button], #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .reverse[type=button]:not([data-dismiss=alert]) {
  width: 100%;
  color: var(--black);
  border-color: var(--black);
  background-color: transparent;
}
.btn-default.reverse:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button.reverse:hover, #quickcheckoutconfirm .reverse[type=button]:hover, #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .reverse[type=button]:hover:not([data-dismiss=alert]) {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);
}
.btn-default.light, #quickcheckoutconfirm .popup-login .login-fields--wrap button.light, #quickcheckoutconfirm .light[type=button], #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .light[type=button]:not([data-dismiss=alert]) {
  color: var(--white);
  border-color: var(--white);
  background-color: transparent;
}
.btn-default.light:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button.light:hover, #quickcheckoutconfirm .light[type=button]:hover, #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .light[type=button]:hover:not([data-dismiss=alert]) {
  background-color: var(--black);
  border-color: var(--black);
}
.btn-default.active, #quickcheckoutconfirm .popup-login .login-fields--wrap button.active, #quickcheckoutconfirm .active[type=button], #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .active[type=button]:not([data-dismiss=alert]) {
  color: var(--white);
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.btn-default.active:hover, #quickcheckoutconfirm .popup-login .login-fields--wrap button.active:hover, #quickcheckoutconfirm .active[type=button]:hover, #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .active[type=button]:hover:not([data-dismiss=alert]) {
  background-color: transparent;
  color: var(--black);
}
.btn-default.medium-button, #quickcheckoutconfirm .popup-login .login-fields--wrap button.medium-button, #quickcheckoutconfirm .medium-button[type=button], #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .medium-button[type=button]:not([data-dismiss=alert]) {
  width: 50%;
}
.btn-default.small-button, #quickcheckoutconfirm .popup-login .login-fields--wrap button.small-button, #quickcheckoutconfirm .small-button[type=button], #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .small-button[type=button]:not([data-dismiss=alert]) {
  max-width: 250px;
  padding: 10px;
}

.alert {
  margin: 15px;
}

.cta-default {
  position: relative;
  display: inline-block;
  font-size: 22px;
  font-family: var(--secondary-font), sans-serif;
  text-transform: uppercase;
  color: var(--black);
  font-weight: 400;
}
@media (max-width: 767px) {
  .cta-default {
    font-size: 18px;
  }
}
@media (max-width: 660px) {
  .cta-default {
    font-size: 16px;
  }
}
@media (max-width: 421px) {
  .cta-default {
    font-size: 14px;
  }
}
.cta-default:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}
.cta-default-white {
  color: var(--white);
}
.cta-default-white:after {
  background-color: var(--white);
}

.alert-success {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
  font-size: var(--text);
  border-radius: 0;
  text-align: center;
}

.btn-primary,
.btn-info {
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--btn-font);
  font-weight: 400;
  color: var(--white);
  background-color: var(--black);
  border: 1px solid var(--black);
  border-radius: var(--button-border-radius);
  line-height: 1;
  transition: ease 0.3s;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active,
.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  color: var(--white);
  background-color: var(--black) !important;
  border-color: var(--black) !important;
}

hr {
  margin-top: 5px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid var(--black);
}

.more-button {
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 500;
}
.more-button::after {
  display: inline-block;
  content: " ";
  background: url("../../../icons/arrow-next.svg");
  background-repeat: no-repeat;
  height: 10px;
  width: 10px;
  margin-left: 15px;
  transition: all 0.5s ease-out;
}
.more-button:hover::after {
  margin-left: 30px;
  transition: all 0.5s ease-out;
}

.post-link-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.post-link-wrap::after {
  content: "";
  width: 22.4479166667vw;
}
@media screen and (max-width: 991px) {
  .post-link-wrap::after {
    width: 30vw;
  }
}
@media screen and (max-width: 767px) {
  .post-link-wrap::after {
    width: 45vw;
  }
}
@media screen and (max-width: 500px) {
  .post-link-wrap::after {
    width: 100%;
  }
}

.post-link {
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
  margin-bottom: 8.4895833333vw;
  position: relative;
}
.post-link .blog-img-wrapper {
  overflow: hidden;
  position: relative;
}
.post-link .blog-img-wrapper .blog-img {
  height: clamp(450px, 29.688vw, 570px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: transform 400ms;
}
.post-link .blog-img-wrapper .blog-img:hover {
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -o-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
  transition: all 400ms;
}
@media screen and (max-width: 991px) {
  .post-link {
    flex-basis: 32%;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 767px) {
  .post-link {
    flex-basis: 48%;
  }
}
@media screen and (max-width: 500px) {
  .post-link {
    flex-basis: 100%;
  }
}
.post-link > .date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 15px;
}
@media screen and (max-width: 991px) {
  .post-link > span {
    padding-left: 8.5px;
    padding-right: 8.5px;
  }
}
@media screen and (max-width: 580px) {
  .post-link > span {
    text-align: center;
  }
}
.post-link > span:first-of-type {
  margin-top: 1.0416666667vw;
  margin-bottom: 1.0416666667vw;
  min-height: 3.4895833333vw;
}
.post-link > span:first-of-type._3-lines {
  min-height: 5.2083333333vw;
}
@media screen and (max-width: 991px) {
  .post-link > span:first-of-type {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.content-hidden {
  display: none !important;
}

.single-heading-space {
  margin-bottom: 5.2083333333vw;
}
@media screen and (max-width: 991px) {
  .single-heading-space {
    margin-bottom: 50px;
  }
}

.fh-wo-header {
  min-height: calc(100vh - (var(--header-height) + var(--promo-height)));
}

.fade-in {
  opacity: 0;
}

.fade-children > * {
  opacity: 0;
}

.body-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(41, 41, 41);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.body-cover.active {
  top: 0;
  z-index: 999;
  opacity: 0.7;
  visibility: visible;
  transition: all 0.3s ease;
}

.size-guide-is-open,
.overflow-hidden,
.menu-is-open,
.cart-is-open {
  overflow: hidden;
  padding-right: 8px;
}
.size-guide-is-open header,
.overflow-hidden header,
.menu-is-open header,
.cart-is-open header {
  padding-right: 8px;
}

@media (max-width: 767px) {
  .cc-btn {
    padding: 10px 5px !important;
  }
}
.size-guide-is-open .body-cover,
.overflow-hidden .body-cover,
.cart-is-open .body-cover {
  top: 0;
  z-index: 999;
  opacity: 0.7;
  visibility: visible;
  transition: all 0.3s ease;
}

#back-to-top {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--oil);
  width: 130px;
  height: 50px;
  text-align: center;
  border-radius: 30px 0 0 30px;
  position: fixed;
  bottom: 100px;
  right: -85px;
  transition: right 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}
#back-to-top:hover {
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  #back-to-top:hover {
    right: 0;
  }
}
#back-to-top:active {
  background-color: var(--dark-oil);
}
#back-to-top.show-back-to-top {
  opacity: 1;
  visibility: visible;
}
#back-to-top .back-to-top-svg {
  margin-right: 15px;
  margin-bottom: 3px;
}
#back-to-top .back-to-top-text {
  font-size: 1.3rem;
  color: var(--white);
}

.desktop-view {
  display: block;
}
@media (max-width: 767px) {
  .desktop-view {
    display: none;
  }
}

.tablet-view {
  display: none !important;
}
@media (max-width: 991px) {
  .tablet-view {
    display: block !important;
  }
}

.mobile-view {
  display: none;
}
@media (max-width: 767px) {
  .mobile-view {
    display: block;
  }
}

label.checkbox-label {
  display: inline-flex;
  align-items: center;
}

span.cookie_settings_header {
  font-family: var(--secondary-font), sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.cc-settings-dialog {
  top: 5% !important;
}
@media (max-width: 1025px) {
  .cc-settings-dialog {
    position: fixed;
    height: unset !important;
    width: 70% !important;
    margin-top: 0 !important;
    overflow: auto !important;
    padding-top: 40px;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 767px) {
  .cc-settings-dialog {
    position: relative;
    height: 100vh !important;
    width: 100% !important;
    margin-top: 0 !important;
    overflow: auto !important;
    padding-top: 40px;
    top: 0 !important;
    left: 0;
    transform: unset;
  }
}
@media (max-width: 660px) {
  .cc-settings-dialog {
    padding: 30px 15px;
  }
}

/** header **/
.logo-icon {
  display: flex;
  width: 122px;
  height: 25px;
}
.logo-icon svg {
  display: flex;
  width: 122px;
  height: 25px;
  fill: #231F20;
}

body {
  padding-top: 114px;
}
@media (max-width: 991px) {
  body {
    padding-top: 67px;
  }
}
body.page_quickcheckout {
  padding-top: 0;
}
body.home {
  padding-top: 0;
}
body.home .logo-icon svg {
  fill: var(--white);
}
body.home #cart .cart-btn .cart-icon svg {
  fill: var(--white);
}
body.home #main-header {
  background-color: transparent;
  color: var(--white);
}
body.home #main-header .header-container {
  border-bottom: none;
}
body.home #main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li a {
  font-family: var(--secondary-font), sans-serif;
  color: var(--white);
}
body.home #main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu .content .row .col-sm-1 .categories ul li a.main-menu {
  font-family: var(--secondary-font), sans-serif;
  color: var(--white);
}
body.home #main-header .header-left .header-search-icon svg {
  stroke: #fff;
}
body.home #main-header .header-left .search-input {
  border-color: var(--white);
  color: var(--white);
  margin-bottom: 0;
  position: relative;
  top: -10px;
}
@media (max-width: 991px) {
  body.home #main-header .header-left .search-input {
    border-color: unset;
    color: unset;
    margin-bottom: unset;
    top: unset;
    position: fixed;
  }
}
body.home #main-header .header-left .search-input-wrapper {
  position: relative;
  border-color: #ffffff;
}
@media (max-width: 991px) {
  body.home #main-header .header-left .search-input-wrapper {
    border-color: var(--black);
  }
}
body.home #main-header .header-left .search-input input {
  color: var(--white);
}
@media (max-width: 991px) {
  body.home #main-header .header-left .search-input input {
    color: var(--black);
  }
}
body.home #main-header .header-left .search-input button .search-icon svg {
  stroke: var(--white);
}
body.home #main-header .header-left .search-input.active button .search-icon svg {
  stroke: #ffffff;
}
@media (max-width: 991px) {
  body.home #main-header .header-left .search-input.active button .search-icon svg {
    stroke: var(--black);
  }
}
body.home #main-header .header-right a.header-wishlist .header-wishlist-icon svg path {
  stroke: var(--white);
}
body.home #main-header .header-right a.header-wishlist .header-wishlist-icon.active svg path {
  fill: var(--white);
}
body.home #main-header .header-right #cart .cart-btn .cart-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1599 6.64217V5.34014C16.1599 2.9364 14.3039 1 11.9999 1C9.69588 1 7.8399 2.93634 7.8399 5.34014V6.64217H4V21.9994C4 22.1997 4.1279 22.3333 4.32009 22.3333H19.6799C19.8719 22.3333 20 22.1999 20 21.9994V6.64217H16.1599ZM8.48001 5.37347C8.48001 3.33697 10.048 1.70097 12.0001 1.70097C13.9521 1.70097 15.5202 3.33691 15.5202 5.37347V6.67549H8.48023L8.48001 5.37347ZM19.3599 21.6655H4.64005V7.30981H7.84001V11.6832H8.47995V7.30981H15.5199V11.6832H16.1598L16.16 7.30981H19.36L19.3599 21.6655Z' fill='%23ffffff'/%3E%3C/svg%3E");
}
body.home #main-header.sticky-menu {
  background: var(--background-color);
  color: var(--black);
}
body.home #main-header.sticky-menu .logo-icon svg {
  fill: var(--black);
}
body.home #main-header.sticky-menu .header-container {
  border-bottom: 1px solid var(--black);
}
body.home #main-header.sticky-menu .header-left .navbar-default .megamenu-wrapper ul.megamenu li a {
  color: var(--black);
}
body.home #main-header.sticky-menu .header-left .navbar-default .megamenu-wrapper ul.megamenu li a strong {
  display: flex;
}
@media (max-width: 991px) {
  body.home #main-header.sticky-menu .header-left .navbar-default .megamenu-wrapper ul.megamenu li a strong {
    justify-content: center;
  }
}
body.home #main-header.sticky-menu .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu .content .row .col-sm-1 .categories ul li a.main-menu {
  color: var(--black);
}
body.home #main-header.sticky-menu .header-left .header-search-icon svg {
  stroke: var(--black);
}
body.home #main-header.sticky-menu .header-left .search-text {
  color: var(--black);
}
body.home #main-header.sticky-menu .header-left .search-input {
  color: var(--black);
}
body.home #main-header.sticky-menu .header-left .search-input-wrapper {
  border-color: var(--black);
}
body.home #main-header.sticky-menu .header-left .search-input input {
  color: var(--black);
}
body.home #main-header.sticky-menu .header-left .search-input button .search-icon svg {
  stroke: var(--black);
}
body.home #main-header.sticky-menu .header-right a.header-wishlist .header-wishlist-icon svg path {
  stroke: var(--black);
}
body.home #main-header.sticky-menu .header-right a.header-wishlist .header-wishlist-icon.active svg path {
  fill: var(--black);
}
body.home #main-header.sticky-menu .header-right #cart .cart-btn .cart-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1599 6.64217V5.34014C16.1599 2.9364 14.3039 1 11.9999 1C9.69588 1 7.8399 2.93634 7.8399 5.34014V6.64217H4V21.9994C4 22.1997 4.1279 22.3333 4.32009 22.3333H19.6799C19.8719 22.3333 20 22.1999 20 21.9994V6.64217H16.1599ZM8.48001 5.37347C8.48001 3.33697 10.048 1.70097 12.0001 1.70097C13.9521 1.70097 15.5202 3.33691 15.5202 5.37347V6.67549H8.48023L8.48001 5.37347ZM19.3599 21.6655H4.64005V7.30981H7.84001V11.6832H8.47995V7.30981H15.5199V11.6832H16.1598L16.16 7.30981H19.36L19.3599 21.6655Z' fill='%23231F20'/%3E%3C/svg%3E");
}
body.home #main-header.sticky-menu .burger-menu .icon-bar {
  background-color: var(--black);
}
body.home #main-header.sticky-menu #cart .cart-btn .cart-icon svg {
  fill: var(--black);
}
body.home .burger-menu .icon-bar {
  background-color: var(--white);
}
body.home.menu-is-open .logo-icon svg {
  fill: var(--black);
}
body.home.menu-is-open .burger-menu .icon-bar {
  background-color: var(--black);
}

.burger-menu.active {
  z-index: 9999;
}

#main-header {
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--background-color);
  z-index: 99;
  color: var(--black);
  transition: background 0.3s ease-out;
}
#main-header.sticky-menu {
  top: 0;
  position: fixed;
  transition: background 0.3s ease-out;
  width: 100%;
  left: 0;
  background: var(--background-color);
}
#main-header .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 40px 37px;
  border-bottom: 2px solid var(--black);
}
@media (max-width: 1140px) {
  #main-header .header-container {
    padding: 40px 15px 37px;
  }
}
@media (max-width: 991px) {
  #main-header .header-container {
    margin: 0;
    padding: 15px;
  }
}
#main-header .header-logo {
  flex-basis: 20%;
}
#main-header .header-logo #logo {
  margin: 0;
  display: flex;
  justify-content: center;
}
#main-header .header-logo #logo a {
  font-family: "Mustica Pro", sans-serif;
  text-transform: uppercase;
  font-size: 35px;
  line-height: 1;
  font-weight: 600;
  z-index: 999;
}
#main-header .header-left, #main-header .header-right {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  min-height: 36px;
  max-height: 36px;
}
#main-header .header-right {
  justify-content: flex-end;
}
@media (max-width: 991px) {
  #main-header .header-right {
    justify-content: unset;
    flex-direction: row-reverse;
    align-items: center;
  }
}
#main-header .header-right a {
  font-family: var(--secondary-font), sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
#main-header .header-right a:not(:last-child) {
  margin-right: 40px;
}
@media (max-width: 1140px) {
  #main-header .header-right a:not(:last-child) {
    margin-right: 20px;
  }
}
#main-header .header-right a.header-wishlist .header-wishlist-icon {
  display: none;
}
@media (max-width: 991px) {
  #main-header .header-right a.header-login {
    display: none;
  }
  #main-header .header-right a.header-wishlist {
    margin-right: 5px;
    display: flex;
  }
  #main-header .header-right a.header-wishlist .header-wishlist-text {
    display: none;
  }
  #main-header .header-right a.header-wishlist .header-wishlist-icon {
    display: inline-flex;
    width: 21px;
    height: 21px;
  }
  #main-header .header-right a.header-wishlist .header-wishlist-icon svg {
    display: inline-flex;
    width: 21px;
    height: 21px;
  }
  #main-header .header-right a.header-wishlist .header-wishlist-icon svg path {
    stroke: var(--black);
    stroke-width: 1px;
  }
  #main-header .header-right a.header-wishlist .header-wishlist-icon.active svg path {
    fill: var(--black);
  }
}
#main-header .header-left {
  /** menu **/
}
#main-header .header-left .megamenu-style-dev {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  #main-header .header-left .megamenu-style-dev {
    position: fixed;
    top: 0;
    z-index: 999;
    left: -100%;
    width: 100%;
    height: var(--screenHeight);
    visibility: hidden;
    opacity: 0;
    transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }
}
@media (max-width: 991px) and (max-width: 660px) {
  #main-header .header-left .megamenu-style-dev {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #main-header .header-left .megamenu-style-dev.active {
    left: 0;
    visibility: visible;
    opacity: 1;
    transition: left 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }
  #main-header .header-left .megamenu-style-dev .megamenu-pattern {
    width: 100%;
    height: calc(var(--screenHeight) - 40px);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
#main-header .header-left .navbar-default {
  background-color: transparent;
  border: none;
}
@media (max-width: 991px) {
  #main-header .header-left .navbar-default .navbar-header {
    display: none;
  }
}
#main-header .header-left .navbar-default .megamenu-wrapper {
  background-color: transparent;
  position: initial;
}
@media (max-width: 991px) {
  #main-header .header-left .navbar-default .megamenu-wrapper {
    background: var(--white);
    border: none;
    width: 100%;
    padding: 20px 15px;
    height: var(--screenHeight);
    max-height: unset;
    overflow: unset;
  }
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li {
  margin-right: 40px;
}
@media (max-width: 1140px) {
  #main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li {
    margin-right: 20px;
  }
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li:hover {
  background-color: transparent;
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li:hover a {
  background-color: transparent;
  color: var(--black);
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li a {
  padding: 0;
  font-family: var(--secondary-font), sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li a:before {
  display: none;
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li a:hover {
  background-color: transparent;
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li a .caret, #main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li a:after {
  display: none;
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu {
  left: 0;
  padding: 10px 40px 20px 40px;
}
@media (max-width: 1140px) {
  #main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu {
    padding: 10px 15px 20px;
  }
}
@media (max-width: 991px) {
  #main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu {
    padding: 10px 20px 20px;
  }
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu .content {
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;
  box-sizing: unset;
  box-shadow: none;
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu .content .row .col-sm-1 {
  width: auto;
}
@media (max-width: 991px) {
  #main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu .content .row .col-sm-1 {
    width: 100%;
  }
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu .content .row .col-sm-1 .categories .menu ul li {
  margin-right: unset;
}
#main-header .header-left .navbar-default .megamenu-wrapper ul.megamenu li .sub-menu .content .row .col-sm-1 .categories .menu ul li a.main-menu {
  font-family: var(--secondary-font), sans-serif;
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
#main-header .header-left .navbar-default .megamenu-wrapper.so-megamenu-active * {
  color: var(--black) !important;
}

.close-search-btn {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}
@media (max-width: 991px) {
  .close-search-btn {
    display: flex;
  }
}
.close-search-btn .close-btn {
  display: flex;
  width: 20px;
  height: 20px;
}
.close-search-btn .close-btn svg {
  display: flex;
  width: 20px;
  height: 20px;
}

.burger-menu {
  display: none;
  width: 43px;
  flex-direction: column;
}
@media (max-width: 991px) {
  .burger-menu {
    display: flex;
    z-index: 999;
  }
}
.burger-menu .icon-bar {
  display: block;
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--black);
  transition: transform 0.3s ease;
}
.burger-menu .icon-bar:not(:last-child) {
  margin-bottom: 10px;
}
.burger-menu .icon-bar.top, .burger-menu .icon-bar.bottom {
  max-width: 26px;
  margin-left: auto;
  margin-right: auto;
}
.burger-menu.active .icon-bar.top {
  max-width: 100%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0;
  position: relative;
  top: 5px;
}
.burger-menu.active .icon-bar.middle {
  opacity: 0;
}
.burger-menu.active .icon-bar.bottom {
  max-width: 100%;
  transform: rotate(-45deg);
  margin: 0;
  position: relative;
  top: -9px;
}

.search-text {
  font-family: var(--secondary-font), sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
.search-text.hide {
  display: none;
}
@media (max-width: 991px) {
  .search-text .header-search-text {
    display: none;
  }
}
.search-input {
  display: none;
  width: 100%;
  max-width: 200px;
}
.search-input.active {
  display: inline-flex;
}
@media (max-width: 991px) {
  .search-input.active {
    position: fixed;
    width: 100%;
    background: var(--white);
    top: 0;
    left: 0;
    max-width: 100%;
    padding: 15px 35px;
    z-index: 9999;
  }
}
.search-input-wrapper {
  position: relative;
  display: flex;
  border-bottom: 1px solid var(--black);
}
@media (max-width: 991px) {
  .search-input-wrapper {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.search-input button {
  background-color: transparent;
  border: none;
  padding: 10px;
  flex-basis: 10%;
}
.search-input button .search-icon {
  display: flex;
  width: 17px;
  height: 15px;
}
.search-input button .search-icon svg {
  display: flex;
  width: 17px;
  height: 15px;
  stroke: var(--black);
}
.search-input input {
  border: none;
  padding: 0;
  position: relative;
  flex-basis: 90%;
  background-color: transparent;
}
.search-input input::placeholder {
  color: transparent;
}
@media (max-width: 991px) {
  .search-input input::placeholder {
    color: var(--black);
  }
}

.header-search-icon {
  display: none;
  width: 22px;
  height: 20px;
}
.header-search-icon svg {
  display: flex;
  width: 22px;
  height: 20px;
  stroke: var(--black);
}
@media (max-width: 991px) {
  .header-search-icon {
    display: flex;
    margin-left: 10px;
  }
}

.main-menu.active, .giftcard-menu-item.active {
  text-decoration: underline;
}

.giftcard-menu-item {
  display: flex;
  justify-content: center;
  color: var(--black);
  font-size: 16px !important;
  font-weight: 400 !important;
}

@media (max-width: 660px) {
  .cc-settings-dialog {
    font-size: 12px !important;
  }
}

footer {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-top: 2px solid var(--black);
}
footer .footer-container {
  background-color: var(--footer-background-color);
  color: var(--black);
  padding: 80px;
}
@media screen and (max-width: 1440px) {
  footer .footer-container {
    padding: 80px 50px;
  }
}
@media screen and (max-width: 1140px) {
  footer .footer-container {
    padding: 80px 15px;
  }
}
@media screen and (max-width: 991px) {
  footer .footer-container {
    padding: 55px 15px;
  }
}
footer .footer-wrapper {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 991px) {
  footer .footer-wrapper {
    justify-content: unset;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 991px) {
  footer .footer-column {
    flex-basis: 33.33%;
  }
}
@media screen and (max-width: 580px) {
  footer .footer-column {
    flex-basis: 50%;
  }
}
footer .footer-column:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  footer .footer-column:not(:last-child) {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 991px) {
  footer .footer-column:not(:last-child) {
    margin-right: unset;
  }
}
footer .footer-title {
  font-family: var(--secondary-font), sans-serif;
  color: var(--black);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
footer .footer-list li {
  margin-bottom: 8px;
}
footer .footer-link {
  display: block;
  color: var(--black);
  font-size: 14px;
}
footer .footer-link:hover, footer .footer-link:focus, footer .footer-link:active {
  color: var(--black);
}
footer .footer-copyrights {
  background-color: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 16px;
}
footer .footer-newsletter {
  width: 100%;
  max-width: 345px;
}
@media screen and (max-width: 1140px) {
  footer .footer-newsletter {
    max-width: 280px;
  }
}
@media screen and (max-width: 991px) {
  footer .footer-newsletter {
    max-width: 345px;
  }
}
@media screen and (max-width: 580px) {
  footer .footer-newsletter {
    max-width: 100%;
  }
}
footer .footer-newsletter-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
}
footer .footer-newsletter-wrapper input[type=text] {
  position: relative;
  width: 100%;
  padding-right: 60px;
}
footer .footer-newsletter-wrapper input[type=text]::placeholder {
  color: var(--black);
}
footer .footer-newsletter-subscribe-btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  background: transparent;
}
footer .footer-newsletter-subscribe-arrow {
  display: flex;
  width: 25px;
  height: 15px;
}
footer .footer-newsletter-subscribe-arrow svg {
  display: flex;
  width: 25px;
  height: 15px;
}

.newsletter-popup {
  display: none;
  background-color: var(--white);
  color: var(--black);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px;
  z-index: 99;
}
@media screen and (max-width: 991px) {
  .newsletter-popup {
    width: 70%;
  }
}
@media screen and (max-width: 660px) {
  .newsletter-popup {
    padding: 50px 30px;
    width: 90%;
  }
}
.newsletter-popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.newsletter-wrapper h2 {
  font-family: var(--secondary-font), sans-serif;
  font-size: 22px;
  font-weight: 700;
}
.newsletter-wrapper p {
  font-size: 16px;
  margin-bottom: 30px;
}
.newsletter-form {
  position: relative;
}
.newsletter-form input {
  width: 100%;
}
.newsletter-form input::placeholder {
  color: var(--black);
}
.newsletter-submit {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--white);
}
.newsletter-submit-arrow {
  display: flex;
  width: 25px;
  height: 16px;
}
.newsletter-submit-arrow svg {
  display: flex;
  width: 25px;
  height: 16px;
}

.footer-legal:hover {
  cursor: pointer;
}

.loader {
  display: none;
  border: 1px solid #000;
  border-top: 1px solid #000;
  border-radius: 50%;
  width: 15px;
  height: 23px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
#cart {
  margin-bottom: 0;
}
#cart .cart-btn {
  font-family: var(--secondary-font), sans-serif;
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  margin-right: 40px;
}
#cart .cart-btn span {
  display: inline-flex;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
@media (max-width: 991px) {
  #cart .cart-btn span {
    font-size: 13px;
    line-height: 0.7;
  }
  #cart .cart-btn span .parenthesis {
    display: none;
  }
}
@media (max-width: 1140px) {
  #cart .cart-btn {
    margin-right: 20px;
  }
}
@media (max-width: 991px) {
  #cart .cart-btn {
    margin-right: 0;
    display: flex;
    align-items: flex-end;
  }
  #cart .cart-btn .cart-text {
    display: none;
  }
}
#cart .cart-btn .cart-icon {
  display: none;
}
@media (max-width: 991px) {
  #cart .cart-btn .cart-icon {
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1599 6.64217V5.34014C16.1599 2.9364 14.3039 1 11.9999 1C9.69588 1 7.8399 2.93634 7.8399 5.34014V6.64217H4V21.9994C4 22.1997 4.1279 22.3333 4.32009 22.3333H19.6799C19.8719 22.3333 20 22.1999 20 21.9994V6.64217H16.1599ZM8.48001 5.37347C8.48001 3.33697 10.048 1.70097 12.0001 1.70097C13.9521 1.70097 15.5202 3.33691 15.5202 5.37347V6.67549H8.48023L8.48001 5.37347ZM19.3599 21.6655H4.64005V7.30981H7.84001V11.6832H8.47995V7.30981H15.5199V11.6832H16.1598L16.16 7.30981H19.36L19.3599 21.6655Z' fill='%23231F20'/%3E%3C/svg%3E");
  }
  #cart .cart-btn .cart-icon svg {
    display: inline-flex;
    width: 24px;
    height: 24px;
    fill: var(--black);
  }
}
#cart .minicart-body {
  display: block;
  position: fixed;
  z-index: 9999;
  background-color: var(--white);
  border: none;
  max-width: max(580px, 45.3125vw);
  width: 100%;
  transition: left 0.6s ease;
  right: -100%;
  top: 0;
  bottom: 0;
  height: var(--screenHeight);
  border-radius: 0;
}
#cart .minicart-body.open {
  right: 0;
  transition: right 0.6s ease;
}
@media (max-width: 767px) {
  #cart .minicart-body.open {
    max-width: 100%;
  }
}
#cart .minicart-body .minicart-wrapper {
  height: var(--screenHeight);
}
#cart .minicart-body .mini-cart-header {
  position: relative;
  padding: 44px 15px 41px;
  border-bottom: 2px solid var(--black);
}
@media (max-width: 991px) {
  #cart .minicart-body .mini-cart-header {
    padding: 20px 15px 18px;
  }
}
#cart .minicart-body .mini-cart-header h2 {
  text-align: center;
  margin-bottom: 0;
  color: var(--black);
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 400;
}
#cart .minicart-body .mini-cart-header .close-mini-cart-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
}
#cart .minicart-body .mini-cart-header .close-mini-cart-btn .close-btn {
  display: block;
  width: 24px;
  height: 24px;
}
#cart .minicart-body .mini-cart-header .close-mini-cart-btn .close-btn svg {
  display: block;
  width: 24px;
  height: 24px;
}
#cart .minicart-body .all-products-w-button {
  height: calc(var(--screenHeight) - 325px);
}
@media (max-width: 991px) {
  #cart .minicart-body .all-products-w-button {
    height: calc(var(--screenHeight) - 240px);
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products {
  padding: 40px max(15px, 3.6458333333vw);
  overflow-y: auto;
  max-height: calc(var(--screenHeight) - 325px);
}
@media (max-width: 991px) {
  #cart .minicart-body .all-products-w-button .scrollable-products {
    max-height: calc(var(--screenHeight) - 240px);
    padding: 15px;
  }
}
#cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products {
  padding: 30px 0;
  justify-content: space-between;
}
@media (max-width: 991px) {
  #cart .minicart-body .all-products-w-button .scrollable-products li .mini-cart-products {
    padding: 15px 0;
  }
}
#cart .minicart-body .mobile-bottom-cart {
  color: var(--black);
  background-color: var(--white);
  border-top: 1px solid var(--black);
  padding: 25px max(15px, 3.6458333333vw);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
@media (max-width: 991px) {
  #cart .minicart-body .mobile-bottom-cart {
    padding: 30px 15px 45px;
  }
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-total .col-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-total .col-total:nth-child(2), #cart .minicart-body .mobile-bottom-cart .mini-cart-total .col-total:nth-child(3) {
  display: none;
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-checkout {
  margin-top: 30px;
}
#cart .minicart-body .mobile-bottom-cart .mini-cart-checkout a {
  width: 100%;
}

.prd-row {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  border-bottom: 1px solid var(--black);
}
@media (max-width: 991px) {
  .prd-row {
    padding: 30px 0;
  }
}
.prd-img {
  flex-basis: 30%;
}
@media (max-width: 421px) {
  .prd-img {
    flex-basis: 35%;
  }
}
.prd-img a {
  display: inline-block;
}
.prd-img a .img-thumbnail {
  object-fit: cover;
  width: 100%;
  max-width: 175px;
  border: 1px solid var(--black);
  border-radius: 0;
  padding: 0;
}
.prd-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 70%;
  margin-left: 10px;
  color: var(--black);
}
@media (max-width: 421px) {
  .prd-item {
    flex-basis: 65%;
  }
}
.prd-name {
  display: inline-block;
  font-size: 22px;
  font-family: var(--secondary-font), sans-serif;
  text-transform: capitalize;
  letter-spacing: 0.01em;
}
@media (max-width: 767px) {
  .prd-name {
    font-size: 18px;
  }
}
@media (max-width: 660px) {
  .prd-name {
    font-size: 16px !important;
  }
}
.prd-price {
  font-size: 22px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .prd-price {
    font-size: 16px;
  }
}
.prd-stock {
  display: flex;
  color: var(--gray);
}
.prd-options {
  display: flex;
}
.prd-options li {
  padding: 0;
}
.prd-options li .option-item {
  display: inline-block;
  color: #727272;
  font-size: 18px;
}
@media (max-width: 991px) {
  .prd-options li .option-item {
    font-size: 16px;
  }
}
@media (max-width: 660px) {
  .prd-options li .option-item {
    font-size: 14px;
  }
}
.prd-options li:not(:last-child):after {
  display: inline-block;
  content: "/";
  color: #727272;
  font-size: 16px;
  margin: 0 3px;
}
.prd-choices {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prd-remove {
  padding: 0;
  border: none;
  background: none;
  font-size: 18px;
  text-decoration: underline;
}
.prd-remove:hover {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .prd-remove {
    font-size: 14px;
  }
}

/**breadcrumb**/
.breadcrumb {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 0 30px;
  border-radius: 0;
}
.breadcrumb li {
  padding: 0;
  text-shadow: none;
}
.breadcrumb li a {
  text-transform: uppercase;
  display: inline-block;
  font-size: 22px;
  font-weight: 400;
  color: var(--black);
}
@media (max-width: 991px) {
  .breadcrumb li a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .breadcrumb li a {
    font-size: 16px;
  }
}
.breadcrumb li a:after {
  content: "/";
  display: inline-block;
  margin: 0 7px 0 10px;
}
.breadcrumb li::after {
  display: none;
}
.breadcrumb li:last-child a {
  color: var(--gray);
}
.breadcrumb li:last-child a:after {
  display: none;
}
.breadcrumb-account {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  background-color: black;
  margin: 0;
  padding: 10px;
  position: sticky;
  z-index: 9;
  top: var(--headerHeight);
}
.breadcrumb-account li a {
  color: var(--white);
}

/*========================================================*/
/* Homepage */
/*========================================================*/
.home-banner {
  position: relative;
  height: 100vh;
  height: var(--screenHeight);
}
.home-banner img, .home-banner video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  height: var(--screenHeight);
}
.home-banner .banner-link {
  font-family: var(--secondary-font), sans-serif;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
  color: var(--white);
}
@media (max-width: 580px) {
  .home-banner .banner-link {
    font-size: 20px;
  }
}
.home-banner .banner-principles {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 75px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
@media (max-width: 421px) {
  .home-banner .banner-principles {
    flex-wrap: wrap;
  }
}
.home-banner .banner-principles li {
  font-family: "Mustica Pro", sans-serif;
  font-size: max(16px, 1.5625vw);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--white);
}
@media (max-width: 660px) {
  .home-banner .banner-principles li {
    font-size: 14px;
  }
}
@media (max-width: 421px) {
  .home-banner .banner-principles li {
    font-size: 13px;
  }
}
.home-banner .banner-principles li:not(:last-child):after {
  display: inline-block;
  content: "·";
  margin: 0 15px;
  color: var(--white);
}
@media (max-width: 580px) {
  .home-banner .banner-principles li:not(:last-child):after {
    margin: 0 5px;
  }
}
.home-section {
  display: flex;
  align-items: center;
  background-color: #1E1E1E;
}
@media (max-width: 767px) {
  .home-section {
    flex-direction: column;
  }
}
.home-section-left {
  flex-basis: 50%;
}
.home-section-left img {
  object-fit: cover;
  width: 100%;
}
.home-section-right {
  flex-basis: 50%;
  position: relative;
}
.home-section-right img {
  position: relative;
}
.home-section-right-content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: var(--white);
  text-align: center;
  padding: 75px 30px;
  max-width: 705px;
  margin: 0 auto;
}
.home-section-right-content .logo-icon {
  margin: 0 auto 30px;
}
.home-section-right-content p {
  font-family: var(--secondary-font), sans-serif;
  margin-bottom: 30px;
}
.home footer {
  border: none;
}

/*swiper arrows*/
.swiper-button-prev {
  width: 63px !important;
  height: 63px !important;
}
.swiper-button-prev::after {
  display: inline-block;
  content: "" !important;
  background-image: url("../../../icons/carousel-arrow.svg");
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  background-repeat: no-repeat;
  width: 63px;
  height: 63px;
}
@media (max-width: 1280px) {
  .swiper-button-prev::after {
    background-image: url("../../../icons/carousel-arrow-md.svg");
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 767px) {
  .swiper-button-prev {
    display: none !important;
  }
}

.swiper-button-next {
  width: 63px !important;
  height: 63px !important;
}
.swiper-button-next::after {
  display: inline-block;
  content: "" !important;
  background-image: url("../../../icons/carousel-arrow.svg");
  background-repeat: no-repeat;
  width: 63px;
  height: 63px;
}
@media (max-width: 1280px) {
  .swiper-button-next::after {
    background-image: url("../../../icons/carousel-arrow-md.svg");
    width: 43px;
    height: 43px;
  }
}
@media (max-width: 767px) {
  .swiper-button-next {
    display: none !important;
  }
}

/*========================================================*/
/*** GLOBAL ACCOUNT ***/
/*========================================================*/
.has-page-message, .has-page-message-error, .has-page-message-success {
  margin: auto;
  max-width: 600px;
  text-align: center;
  border: 1px solid;
  padding: 15px;
  margin-bottom: 20px;
}
.has-page-message-wrap {
  margin-left: 15px;
  margin-right: 15px;
}
.has-page-message-error {
  color: #ff0000;
}
.has-page-message-success {
  color: #224344;
}

.account-container {
  padding: 100px 40px;
}
@media (max-width: 991px) {
  .account-container {
    padding: 75px 15px;
  }
}
.account-container .form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
}
.account-container h1, .account-container h2 {
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
}
.account-container p {
  text-align: justify;
}
.account-container .list-unstyled a {
  text-decoration: underline;
}
.account-container .btn-default, .account-container #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .account-container button, .account-container #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .account-container [type=button], .account-container #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .account-container [type=button]:not([data-dismiss=alert]) {
  margin-top: 35px;
}
.account-wrapper {
  max-width: 550px;
  margin: 0 auto;
}
.account-wrapper-medium {
  max-width: 810px;
  margin: 0 auto;
}
.account-login-wrapper {
  max-width: 1220px;
  margin: 0 auto;
}
.account-login-wrapper #content {
  min-height: 500px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .account-login-wrapper {
    max-width: 550px;
  }
}
.account-login-wrapper .flex-row {
  justify-content: space-between;
}
@media (max-width: 767px) {
  .account-login-wrapper .flex-row {
    flex-direction: column;
  }
}
.account-login-wrapper .left, .account-login-wrapper .right {
  flex-basis: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .account-login-wrapper .left, .account-login-wrapper .right {
    flex-basis: calc(50% - 15px);
  }
}
.account-login-wrapper .left {
  margin-right: 30px;
}
@media (max-width: 991px) {
  .account-login-wrapper .left {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .account-login-wrapper .left {
    margin-right: 0;
    margin-bottom: 75px;
  }
}
.account-login-wrapper .right {
  margin-left: 30px;
}
@media (max-width: 991px) {
  .account-login-wrapper .right {
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .account-login-wrapper .right {
    margin-left: 0;
  }
}
.account-list {
  text-align: center;
  margin-bottom: 40px;
}
.account-wishlist {
  max-width: 810px;
  margin: 0 auto;
}
.account-wishlist .wishlist-row {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  border-bottom: 1px solid var(--black);
}
@media (max-width: 991px) {
  .account-wishlist .wishlist-row {
    padding: 30px 0;
  }
}
.account-form--field {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
}
.account-form--field input[type=email] {
  width: 100%;
}
.account-form--field input[type=email]::placeholder {
  color: var(--black);
}

#common-success #content {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#account-reset #content {
  min-height: unset;
}

#checkout-cart .prd-choices .flex-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 180px;
}
#checkout-cart .prd-choices .flex-row .refresh-button {
  max-width: 45px;
  border: none;
  background-color: transparent;
  margin: 0 0 0 5px;
  padding: 0;
}
#checkout-cart .prd-choices .flex-row .refresh-button i.fa.fa-refresh {
  color: var(--black);
}

.forgotten-text {
  color: var(--gray);
  text-decoration: underline;
  font-size: 16px;
}
.forgotten-text:hover {
  color: var(--gray);
  text-decoration: underline;
}

legend {
  font-family: var(--secondary-font);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--black);
  border: none;
  padding: 10px 0;
  margin: 0 0 15px;
}
@media (max-width: 991px) {
  legend {
    font-size: 18px;
  }
}

.text-danger {
  font-size: 14px;
}

.flex-row.buttons {
  margin: 0;
}
.flex-row.buttons .btn-default:first-child, .flex-row.buttons #quickcheckoutconfirm .popup-login .login-fields--wrap button:first-child, #quickcheckoutconfirm .popup-login .login-fields--wrap .flex-row.buttons button:first-child, .flex-row.buttons #quickcheckoutconfirm [type=button]:first-child, #quickcheckoutconfirm .flex-row.buttons [type=button]:first-child, .flex-row.buttons #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:first-child:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .flex-row.buttons [type=button]:first-child:not([data-dismiss=alert]) {
  margin-right: 15px;
}
@media (max-width: 991px) {
  .flex-row.buttons .btn-default:first-child, .flex-row.buttons #quickcheckoutconfirm .popup-login .login-fields--wrap button:first-child, #quickcheckoutconfirm .popup-login .login-fields--wrap .flex-row.buttons button:first-child, .flex-row.buttons #quickcheckoutconfirm [type=button]:first-child, #quickcheckoutconfirm .flex-row.buttons [type=button]:first-child, .flex-row.buttons #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:first-child:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .flex-row.buttons [type=button]:first-child:not([data-dismiss=alert]) {
    margin-right: 7px;
  }
}
.flex-row.buttons .btn-default:nth-child(2), .flex-row.buttons #quickcheckoutconfirm .popup-login .login-fields--wrap button:nth-child(2), #quickcheckoutconfirm .popup-login .login-fields--wrap .flex-row.buttons button:nth-child(2), .flex-row.buttons #quickcheckoutconfirm [type=button]:nth-child(2), #quickcheckoutconfirm .flex-row.buttons [type=button]:nth-child(2), .flex-row.buttons #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:nth-child(2):not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .flex-row.buttons [type=button]:nth-child(2):not([data-dismiss=alert]) {
  margin-left: 15px;
}
@media (max-width: 991px) {
  .flex-row.buttons .btn-default:nth-child(2), .flex-row.buttons #quickcheckoutconfirm .popup-login .login-fields--wrap button:nth-child(2), #quickcheckoutconfirm .popup-login .login-fields--wrap .flex-row.buttons button:nth-child(2), .flex-row.buttons #quickcheckoutconfirm [type=button]:nth-child(2), #quickcheckoutconfirm .flex-row.buttons [type=button]:nth-child(2), .flex-row.buttons #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:nth-child(2):not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .flex-row.buttons [type=button]:nth-child(2):not([data-dismiss=alert]) {
    margin-left: 7px;
  }
}

.table-bordered {
  border: 1px solid #000;
  margin-bottom: 40px;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #000;
}

.table-bordered > tbody > tr > td .btn-default, .table-bordered > tbody > tr > td #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .table-bordered > tbody > tr > td button, .table-bordered > tbody > tr > td #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .table-bordered > tbody > tr > td [type=button], .table-bordered > tbody > tr > td #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .table-bordered > tbody > tr > td [type=button]:not([data-dismiss=alert]) {
  margin: 0;
  padding: 10px;
}

.table-bordered > thead {
  background-color: #000;
  color: #fff;
}

@media (max-width: 991px) {
  .checkout-container {
    padding-top: calc(var(--header-height) + 20px);
  }
}
.checkout-container #main-header,
.checkout-container #search {
  display: none;
}
.checkout-container #d_logo {
  width: 100%;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
}
.checkout-container #d_logo a {
  display: inline-block;
  margin: 0 auto;
  max-width: clamp(100px, 12.5vw, 240px);
  width: 100%;
  transition: width 0.3s ease;
}
@media (max-width: 991px) {
  .checkout-container #d_logo {
    order: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 99;
    padding: 15px 0;
    background-color: var(--light-beige);
  }
  .checkout-container #d_logo a {
    width: 100px;
  }
  .checkout-container #d_logo a.scrolled {
    width: 85px;
  }
}
.checkout-container .checkout-header {
  display: flex;
  align-items: center;
  margin: 0 0 clamp(25px, 5.2083vw, 100px);
  padding: 15px 0;
}
@media (max-width: 991px) {
  .checkout-container .checkout-header {
    flex-direction: column;
    background-color: var(--light-beige);
  }
  .checkout-container .checkout-header .back-to-home {
    order: 2;
    margin: 0 0 5px;
  }
  .checkout-container .checkout-header .phone-support {
    order: 3;
  }
}
.checkout-container .checkout-header .back-to-home {
  flex-basis: 25%;
}
.checkout-container .checkout-header .phone-support {
  flex-basis: 25%;
}
.checkout-container .checkout-header .phone-support br {
  display: none;
}
@media (max-width: 1280px) {
  .checkout-container .checkout-header .phone-support br {
    display: block;
  }
}
.checkout-container .checkout-header .phone-support .support-icon {
  position: relative;
  top: 5px;
  margin: 0 5px;
}
.checkout-container .checkout-header .phone-support a:last-child {
  text-decoration: none;
}
.checkout-container .checkout-title, .checkout-container #quickcheckoutconfirm .checkout__main-title, #quickcheckoutconfirm .checkout-container .checkout__main-title {
  max-width: 1515px;
  width: 100%;
  margin: 0 auto 40px;
}
@media (max-width: 991px) {
  .checkout-container .checkout-title, .checkout-container #quickcheckoutconfirm .checkout__main-title, #quickcheckoutconfirm .checkout-container .checkout__main-title {
    text-align: center;
    font-size: 2.4rem;
  }
}
.checkout-container .checkout-wrapper {
  max-width: 1515px;
  width: 100%;
  margin: 0 auto;
}
.checkout-container .alert {
  margin: 0 0 20px;
}
.checkout-container .radio-input {
  display: flex;
  align-items: center;
}
.checkout-container #payment_view {
  padding: 0 25px 50px;
}
@media (max-width: 1140px) {
  .checkout-container #payment_view {
    padding: 0 0 25px;
  }
}
.checkout-container #d_quickcheckout .text {
  margin-bottom: 0;
}
.checkout-container #d_quickcheckout .price {
  margin: 0 0 0 5px;
}
.checkout-container #d_quickcheckout input[type=radio],
.checkout-container #d_quickcheckout input[type=checkbox] {
  margin-right: 5px;
}
.checkout-container .header-2, .checkout-container #contact-us .section-wrap .contact-block form fieldset > span, #contact-us .section-wrap .contact-block form .checkout-container fieldset > span, .checkout-container #checkout-checkout #accordion .panel .panel-heading .panel-title, #checkout-checkout #accordion .panel .panel-heading .checkout-container .panel-title, .checkout-container .post-link > span:first-of-type {
  margin: 0;
}
.checkout-container a {
  color: var(--primary-color);
  text-decoration: underline;
}
.checkout-container .guest-login-register {
  display: flex;
}
.checkout-container .guest-login-register .btn-group {
  flex-basis: 33.333333%;
}
@media (max-width: 660px) {
  .checkout-container .guest-login-register .btn-group:nth-child(1), .checkout-container .guest-login-register .btn-group:nth-child(2) {
    flex-basis: 50%;
    margin: 0 0 5px;
  }
  .checkout-container .guest-login-register .btn-group:nth-child(3) {
    flex-basis: 100%;
  }
}
.checkout-container .checkout-left {
  margin-right: clamp(20px, 2.3438vw, 45px);
  width: calc(50% - clamp(20px, 2.3438vw, 45px));
  background-color: var(--white);
}
@media (max-width: 991px) {
  .checkout-container .checkout-left {
    background-color: var(--light-beige);
    border-right: unset;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
.checkout-container .checkout-right {
  padding-left: clamp(20px, 2.3438vw, 45px);
}
@media (max-width: 991px) {
  .checkout-container .checkout-right {
    padding-right: 0;
    padding-left: 0;
  }
}
.checkout-container .checkout-right .theiaStickySidebar {
  background-color: var(--light-beige);
}
.checkout-container #d_quickcheckout {
  padding: 0 var(--side-padding-checkout) clamp(25px, 5.2083vw, 100px);
  max-width: 1800px;
  width: 100%;
}
@media (max-width: 767px) {
  .checkout-container #d_quickcheckout {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.checkout-container .form-horizontal .radio,
.checkout-container .form-horizontal .checkbox,
.checkout-container .form-horizontal .radio-inline,
.checkout-container .form-horizontal .checkbox-inline {
  padding-top: 0;
}
@media (max-width: 767px) {
  .checkout-container .form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
  }
}
.checkout-container .well {
  box-shadow: none;
}
.checkout-container #qc_confirm_order {
  padding: 20px 15px;
}
.checkout-container #qc_confirm_order:disabled {
  color: var(--input-border-color);
  background-color: var(--white);
  border-color: var(--input-border-color);
  transition: all linear 0.3s;
}
@media (max-width: 660px) {
  .checkout-container #qc_confirm_order {
    padding: 15px;
  }
}
.checkout-container .text-danger {
  margin: 0 !important;
  font-size: 1.2rem;
}
.checkout-container button.decrease, .checkout-container button.increase, .checkout-container button.delete {
  background-color: unset;
  background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-color: unset;
}
@media (max-width: 991px) {
  .checkout-container button.delete {
    display: none;
  }
}
.checkout-container .panel {
  border-radius: 0;
  border: none;
  background: none;
  box-shadow: none;
  padding: 0 25px;
}
@media (max-width: 1140px) {
  .checkout-container .panel {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .checkout-container .panel {
    margin-bottom: 0;
  }
}
.checkout-container .panel .panel-body {
  padding: 15px 0;
}
@media (max-width: 767px) {
  .checkout-container .panel .panel-body {
    padding: 10px;
  }
}
.checkout-container .panel .panel-heading {
  background-color: unset;
  border-radius: 0;
  border: none;
  padding: 10px 0;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .checkout-container .panel .panel-heading {
    margin-top: 15px;
    padding: 10px 10px 0 10px;
  }
}
.checkout-container #payment_view .header-2, .checkout-container #payment_view #contact-us .section-wrap .contact-block form fieldset > span, #contact-us .section-wrap .contact-block form .checkout-container #payment_view fieldset > span, .checkout-container #payment_view #checkout-checkout #accordion .panel .panel-heading .panel-title, #checkout-checkout #accordion .panel .panel-heading .checkout-container #payment_view .panel-title, .checkout-container #payment_view .post-link > span:first-of-type {
  margin: 30px 0 30px;
}
@media (max-width: 767px) {
  .checkout-container #payment_view .header-2, .checkout-container #payment_view #contact-us .section-wrap .contact-block form fieldset > span, #contact-us .section-wrap .contact-block form .checkout-container #payment_view fieldset > span, .checkout-container #payment_view #checkout-checkout #accordion .panel .panel-heading .panel-title, #checkout-checkout #accordion .panel .panel-heading .checkout-container #payment_view .panel-title, .checkout-container #payment_view .post-link > span:first-of-type {
    margin: 15px 0 20px;
  }
}
.checkout-container #shipping_address .panel-body,
.checkout-container #shipping_method .panel-body,
.checkout-container #payment_method .panel-body {
  border-bottom: 1px solid #ececec;
}
.checkout-container #shipping_method strong.title {
  display: none;
}
.checkout-container #payment_address_heading_heading > i {
  display: none;
}
.checkout-container .btn, .checkout-container #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .checkout-container button, .checkout-container #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .checkout-container [type=button], .checkout-container #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkout-container [type=button]:not([data-dismiss=alert]) {
  box-shadow: none !important;
  text-shadow: none !important;
}
.checkout-container #login_view {
  margin: 0 -16px;
}
@media (max-width: 991px) {
  .checkout-container #login_view {
    margin: unset;
  }
}
.checkout-container #login_view .guest-login-register {
  display: flex;
  flex-wrap: wrap;
}
.checkout-container #login_view .guest-login-register .btn-default, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .checkout-container #login_view .guest-login-register button, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .checkout-container #login_view .guest-login-register [type=button], .checkout-container #login_view .guest-login-register #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkout-container #login_view .guest-login-register [type=button]:not([data-dismiss=alert]) {
  background-color: var(--light-beige);
  border: none;
  color: var(--black);
  font-family: var(--primary-font), sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 20px 25px 20px 40px;
  text-align: left;
}
@media (max-width: 1140px) {
  .checkout-container #login_view .guest-login-register .btn-default, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .checkout-container #login_view .guest-login-register button, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .checkout-container #login_view .guest-login-register [type=button], .checkout-container #login_view .guest-login-register #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkout-container #login_view .guest-login-register [type=button]:not([data-dismiss=alert]) {
    padding: 20px 25px 20px 15px;
  }
}
.checkout-container #login_view .guest-login-register .btn-default.active, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm .popup-login .login-fields--wrap button.active, #quickcheckoutconfirm .popup-login .login-fields--wrap .checkout-container #login_view .guest-login-register button.active, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm .active[type=button], #quickcheckoutconfirm .checkout-container #login_view .guest-login-register .active[type=button], .checkout-container #login_view .guest-login-register #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .active[type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkout-container #login_view .guest-login-register .active[type=button]:not([data-dismiss=alert]) {
  background-color: var(--white);
  position: relative;
  text-decoration: underline;
}
@media (max-width: 991px) {
  .checkout-container #login_view .guest-login-register {
    flex-direction: column;
  }
  .checkout-container #login_view .guest-login-register .btn-default, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .checkout-container #login_view .guest-login-register button, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .checkout-container #login_view .guest-login-register [type=button], .checkout-container #login_view .guest-login-register #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkout-container #login_view .guest-login-register [type=button]:not([data-dismiss=alert]) {
    background-color: transparent;
    padding: 10px 20px;
  }
  .checkout-container #login_view .guest-login-register .btn-default.active, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm .popup-login .login-fields--wrap button.active, #quickcheckoutconfirm .popup-login .login-fields--wrap .checkout-container #login_view .guest-login-register button.active, .checkout-container #login_view .guest-login-register #quickcheckoutconfirm .active[type=button], #quickcheckoutconfirm .checkout-container #login_view .guest-login-register .active[type=button], .checkout-container #login_view .guest-login-register #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .active[type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkout-container #login_view .guest-login-register .active[type=button]:not([data-dismiss=alert]) {
    background-color: transparent;
    text-decoration: none;
    font-weight: 600;
  }
  .checkout-container #login_view .guest-login-register .btn-group {
    flex-basis: 100%;
    margin: 0 0 5px;
    border-bottom: 1px solid var(--light-gray);
  }
}
.checkout-container #login_social_login {
  background-color: var(--light-beige);
  margin: 0 -16px;
  padding-bottom: 15px;
}
@media (max-width: 991px) {
  .checkout-container #login_social_login {
    margin: 0;
  }
}
.checkout-container #login_social_login #d_social_login {
  max-width: unset;
  margin: 0;
}
.checkout-container #login_social_login #d_social_login .d-social-login__button .d-social-login__button__icon {
  font-size: 1.5rem;
  text-align: center;
  padding: 22px 20px;
  max-height: unset;
  background-color: var(--blue);
}
.checkout-container #cart_view .qc-name a {
  text-decoration: none;
  font-size: 1.4rem;
  font-family: var(--secondary-font), sans-serif;
  color: var(--black);
}
.checkout-container #cart_view thead td.qc-image {
  opacity: 0;
}
.checkout-container #cart_view .table {
  margin-bottom: 50px;
  border: none;
}
@media (max-width: 660px) {
  .checkout-container #cart_view .table {
    font-size: 1.1rem;
  }
}
.checkout-container #cart_view .table td {
  border: none;
}
@media (max-width: 660px) {
  .checkout-container #cart_view .table td {
    font-size: 1.1rem;
  }
}
.checkout-container #cart_view .table td.qc-image {
  padding: 15px 0;
}
@media (max-width: 660px) {
  .checkout-container #cart_view .table td.qc-image {
    max-width: 60px;
  }
}
@media (max-width: 660px) {
  .checkout-container #cart_view .table td.qc-quantity {
    width: 22%;
  }
  .checkout-container #cart_view .table td.qc-quantity .input-group {
    max-width: 65px;
  }
}
.checkout-container #cart_view .table thead {
  border-bottom: 1px solid var(--light-gray);
}
.checkout-container #cart_view .table thead td {
  padding-bottom: 18px;
}
.checkout-container #cart_view .table thead td.qc-image {
  padding: 10px 0;
}
.checkout-container #cart_view .table tbody tr {
  border-bottom: 1px solid var(--light-gray);
}
.checkout-container #cart_view .table tbody td {
  padding: 15px 8px;
}
.checkout-container #cart_view .qc-image {
  width: 80px;
}
.checkout-container #cart_view .qc-image a {
  display: inline-block;
}
.checkout-container #cart_view .qc-image img {
  mix-blend-mode: multiply;
}
.checkout-container #cart_view .qc-name-price {
  display: none !important;
}
.checkout-container #cart_view .qc-coupon {
  margin: 0 0 25px;
}
.checkout-container #cart_view .qc-coupon .col-sm-12 {
  padding: 0;
}
.checkout-container #cart_view .qc-coupon #coupon,
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  height: 60px;
}
.checkout-container #cart_view .qc-coupon #coupon {
  width: 100%;
  min-height: 35px;
  padding: 15px;
}
@media (max-width: 991px) {
  .checkout-container #cart_view .qc-coupon #coupon {
    max-width: unset;
    padding: 20px 15px;
    height: unset;
  }
}
.checkout-container #cart_view .qc-coupon #confirm_coupon {
  display: block;
  max-width: 135px;
  width: 100%;
  padding: 5px 20px;
  height: 60px;
}
@media (max-width: 991px) {
  .checkout-container #cart_view .qc-coupon #confirm_coupon {
    max-width: unset;
    padding: 20px 15px;
    height: unset;
  }
}
@media (max-width: 991px) {
  .checkout-container #cart_view .qc-coupon .coupon-container {
    display: flex;
    flex-direction: column;
  }
  .checkout-container #cart_view .qc-coupon .coupon-container > input {
    margin-bottom: 10px;
  }
  .checkout-container #cart_view .qc-coupon .coupon-container .input-group-btn {
    width: 100%;
    margin: 0 0 5px;
  }
}
.checkout-container #cart_view .qc-totals .row {
  margin: 0;
  font-size: var(--header-3);
  text-align: right;
  padding: 20px 0;
}
.checkout-container #cart_view .qc-totals .row:last-child {
  padding: 20px 0;
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
}
.checkout-container #cart_view .qc-totals .row:last-child label {
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.checkout-container #cart_view .qc-totals .row:last-child div {
  font-weight: 500;
  font-size: 1.5rem;
}
.checkout-container #cart_view .qc-totals .row label {
  text-align: left !important;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 5px 0;
}
.checkout-container #cart_view .qc-totals .row div {
  font-size: 1.4rem;
}
.checkout-container #cart_view .quantity-checkout {
  position: relative;
  max-width: 70px;
  height: 34px;
}
.checkout-container #cart_view .quantity-checkout .quantity input[type=text].form-control {
  border: 1px solid var(--light-gray);
  border-radius: var(--input-border-radius);
  padding: 10px 5px;
  text-align: center;
  max-width: 70px;
  max-height: 34px;
}
.checkout-container #cart_view .quantity-checkout button {
  position: absolute;
  z-index: 9;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
}
.checkout-container #cart_view .quantity-checkout button.decrease {
  left: 5px;
}
.checkout-container #cart_view .quantity-checkout button.increase {
  right: 5px;
}
.checkout-container #cart_view .qc-product-qantity {
  max-width: 75px;
  min-height: 35px;
  padding: 5px;
}
.checkout-container #cart_view .qc-quantity {
  width: 27%;
}
.checkout-container #cart_view .qc-quantity .input-group.input-group-sm {
  max-width: 150px;
}
.checkout-container #cart_view .qc-checkout-product table.table tbody td {
  vertical-align: middle;
}
@media (max-width: 991px) {
  .checkout-container label[for=payment_address_agree] input[type=checkbox],
  .checkout-container label[for=payment_address_shipping_address] input[type=checkbox],
  .checkout-container label[for=confirm_agree] input[type=checkbox] {
    flex-basis: 5%;
    max-width: 16px;
  }
  .checkout-container label[for=payment_address_agree] span,
  .checkout-container label[for=payment_address_shipping_address] span,
  .checkout-container label[for=confirm_agree] span {
    flex-basis: 95%;
    text-align: left;
  }
}
.checkout-container .form-group.required .control-label:after {
  content: "";
}
.checkout-container .form-group.required .control-label span:after {
  content: " *";
  color: var(--light-gray);
}
.checkout-container #payment_address_form,
.checkout-container #shipping_address_form {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
}
.checkout-container #payment_address_form > div,
.checkout-container #shipping_address_form > div {
  flex-basis: calc(50% + 15px);
}
.checkout-container #payment_address_newsletter_input,
.checkout-container #payment_address_customer_group_id_input,
.checkout-container #payment_address_shipping_address_input,
.checkout-container #payment_address_agree_input,
.checkout-container #payment_address_heading_heading {
  flex-basis: calc(100% + 30px) !important;
}
.checkout-container #payment_address_customer_group_id_input .col-xs-12:first-child {
  display: none;
}
.checkout-container #confirm_form {
  margin-bottom: clamp(30px, 3.125vw, 60px);
}
.checkout-container #confirm_comment {
  min-height: 140px;
  resize: vertical;
}
.checkout-container #payment_address_newsletter_input label,
.checkout-container #payment_address_shipping_address_input label,
.checkout-container #payment_address_agree_input label,
.checkout-container #payment_address_heading_heading label {
  text-align: left;
}
.checkout-container #payment_address_heading_heading {
  margin: 30px 0 20px;
}
@media (max-width: 767px) {
  .checkout-container #payment_address_heading_heading {
    margin: 20px 0 10px;
  }
}
.checkout-container #login_model {
  top: 50%;
  height: fit-content;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .checkout-container #payment_address_form > div,
  .checkout-container #shipping_address_form > div,
  .checkout-container #custom-fields-account-invoice > div {
    flex-basis: calc(100% + 30px) !important;
  }
  .checkout-container #payment_address_form > div > div,
  .checkout-container #shipping_address_form > div > div,
  .checkout-container #confirm_comment_input > div,
  .checkout-container #confirm_agree_input > div {
    padding: 0;
  }
  .checkout-container #payment_view {
    padding: 10px;
  }
}

.gift-packaging {
  margin: 10px 0;
}
.gift-packaging .control-label {
  align-items: baseline;
  font-size: 1.2rem;
}

#banner-easy-returns {
  position: relative;
  margin: 30px auto;
  padding: 30px 0;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
#banner-easy-returns:after {
  content: "";
  width: 98%;
  height: 93%;
  border: 1px solid var(--white);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#banner-easy-returns h5 {
  font-family: var(--primary-font), sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  max-width: 430px;
  margin: 10px auto 25px;
}
#banner-easy-returns a.btn-default, #banner-easy-returns #quickcheckoutconfirm a[type=button], #quickcheckoutconfirm #banner-easy-returns a[type=button], #banner-easy-returns #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body a[type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body #banner-easy-returns a[type=button]:not([data-dismiss=alert]) {
  position: relative;
  z-index: 1;
  text-decoration: none;
  padding: 10px;
  margin-bottom: 10px;
}

span.select2.select2-container.select2-container--default {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  width: 100% !important;
  border: none;
  border-bottom: 1px solid var(--black);
  padding: 10px 0;
  height: unset;
  line-height: 1.5;
  background-color: transparent;
  box-shadow: none;
  border-radius: var(--input-border-radius);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../../../assets/icons/new-arrow-down.svg") !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 10px;
}

.select2-container--default.select2-container--open.select2-container--above {
  border: 1px solid var(--black);
  border-bottom: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: unset;
  padding: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--black);
  color: white;
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--white);
}

.select2-search--dropdown, .select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: var(--white);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: var(--input-font);
  font-weight: 400;
  color: var(--black);
}

.select2-container--default .select2-selection--single .select2-selection__clear span {
  display: none;
}

.has-error .select2-selection.select2-selection--single {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08) !important;
}

.custom-field-custom-red-box {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08) !important;
}

.has-error .form-control {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08) !important;
}

#checkout-loader {
  display: none;
}

#checkout-loader.active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: rgba(250, 247, 243, 0.4);
}

#checkout-checkout {
  padding-top: max(30px, 2.6041666667vw);
  padding-bottom: max(30px, 2.6041666667vw);
}
#checkout-checkout .alert.alert-success.success {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: max(30px, 2.6041666667vw);
  background: transparent;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
#checkout-checkout .alert.alert-danger.alert-dismissible {
  margin-left: 0;
  margin-right: 0;
  background: transparent;
  border-radius: unset;
  color: #a94442;
  border-color: #a94442;
}
#checkout-checkout .main_heading_title {
  margin-bottom: 30px;
}
#checkout-checkout #accordion .panel {
  background-color: unset;
  border-radius: unset;
  border-color: #6a6b59;
}
@media screen and (max-width: 768px) {
  #checkout-checkout #accordion .panel:first-child .panel-body .row [class*=col-]:first-child {
    order: 2;
    margin-bottom: unset !important;
  }
  #checkout-checkout #accordion .panel:first-child .panel-body .row [class*=col-]:last-child {
    order: 1;
    margin-bottom: 30px;
  }
}
#checkout-checkout #accordion .panel .panel-heading {
  background-color: unset;
}
#checkout-checkout #accordion .panel .panel-heading .panel-title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}
#checkout-checkout #accordion .panel .panel-heading .panel-title .accordion-toggle:after {
  content: "";
  position: relative;
  padding: 15px;
  line-height: 1.5;
  background-image: url("../../../assets/icons/new-arrow-down.svg") !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 10px;
}
#checkout-checkout #accordion .panel .panel-heading .panel-title .accordion-toggle .fa {
  display: none;
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .row {
  display: flex;
}
@media screen and (max-width: 768px) {
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .row {
    flex-direction: column;
  }
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .row [class*=col-] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .row [class*=col-] {
    min-width: 100%;
  }
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .row [class*=col-]:first-of-type {
    margin-bottom: 30px;
  }
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .row [class*=col-] > *:not([type=button]) {
  width: 100%;
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .row [class*=col-] fieldset#address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox {
  line-height: 1;
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .radio label:first-child,
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox label:first-child {
  margin-right: 15px;
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap,
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap,
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block {
    flex-direction: column;
  }
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap [type=button],
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block [type=button] {
    margin-top: 15px;
    align-self: flex-end;
  }
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap .checkbox-wrap,
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap label,
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block .checkbox-wrap,
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block label {
  display: flex;
  flex: 1;
  font-weight: initial;
}
@media screen and (max-width: 768px) {
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap .checkbox-wrap,
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap label,
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block .checkbox-wrap,
  #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block label {
    width: 100%;
  }
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap .checkbox-wrap [type=checkbox],
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .checkbox-outer-wrap label [type=checkbox],
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block .checkbox-wrap [type=checkbox],
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .payment-methods-block label [type=checkbox] {
  margin-top: 3px;
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .well {
  background-color: var(--light-brown);
  border-color: var(--primary-color);
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]) {
  width: auto;
}
#checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]):hover {
  color: var(--oil) !important;
  background-color: transparent !important;
  border-color: var(--oil) !important;
}

.alert-message-danger {
  color: #e45451;
  font-weight: bold;
}

.coupon-messages {
  padding: 0;
}

.coupon-messages .alert {
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.quickcheckout-content .checkbox__wrap.newsletter-checkout-box {
  display: none;
}

.field__wrap--input.required .control-label:after {
  content: " *";
  color: var(--gray);
}

.checkout-message {
  padding: 15px;
  border: 1px solid;
  margin-bottom: 15px;
  font-size: var(--input-font);
}

#banner-easy-returns {
  margin-bottom: 0;
}

.checkout-hidden {
  display: none !important;
}

body.loginPopupisOpen {
  overflow: hidden;
}

.cart-section-title, #quickcheckoutconfirm .checkout__title, #quickcheckoutconfirm .checkout__wrap .payment__title,
#quickcheckoutconfirm .checkout__wrap .shipping__title, #quickcheckoutconfirm .checkout__wrap .address__title {
  font-family: var(--secondary-font), sans-serif;
  font-size: var(--header-2);
  font-weight: 400;
  line-height: 1.2;
  color: #333333;
}

.popup-login-title, #quickcheckoutconfirm .popup-login__head__title {
  font-family: var(--secondary-font), sans-serif;
  font-weight: 500;
  font-size: 2.8rem;
  text-transform: uppercase;
}

.popup-login-close, #quickcheckoutconfirm .popup-login__head ._close {
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: var(--black);
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 1;
}
.popup-login-close:focus, #quickcheckoutconfirm .popup-login__head ._close:focus, .popup-login-close:hover, #quickcheckoutconfirm .popup-login__head ._close:hover {
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.checkout__social--login a {
  font-size: 1.5rem;
  text-align: center;
  padding: 22px 20px;
  max-height: unset;
  background-color: var(--blue);
  color: var(--white);
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (max-width: 991px) {
  .checkout__social--login a {
    font-size: 1.3rem;
    padding: 12px 10px;
  }
}

#quickcheckoutconfirm .checkout__title, #quickcheckoutconfirm .checkout__wrap .payment__title,
#quickcheckoutconfirm .checkout__wrap .shipping__title, #quickcheckoutconfirm .checkout__wrap .address__title {
  margin: 0;
  padding: 20px 0 15px;
}
#quickcheckoutconfirm .checkout__title--no-top-space {
  margin-top: unset;
}
#quickcheckoutconfirm .checkout__social--login {
  background-color: var(--white);
  padding-bottom: 25px;
}
@media (max-width: 991px) {
  #quickcheckoutconfirm .checkout__social--login a {
    font-size: 1.5rem;
    padding: 22px 20px;
  }
}
#quickcheckoutconfirm .quickcheckout-content {
  background-color: #f5f3f0;
  border: 1px solid #f5f3f0;
  margin-bottom: 0;
  padding: 0;
}

#quickcheckoutconfirm {
  color: var(--black);
  max-width: 1640px;
  padding-left: max(10px, 4.1666666667vw);
  padding-right: max(10px, 4.1666666667vw);
  padding-bottom: max(30px, 2.6041666667vw);
  margin: auto;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm {
    padding-top: 150px;
  }
}
#quickcheckoutconfirm .checkout__main-title {
  width: 100%;
  margin-bottom: 40px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__main-title {
    text-align: center;
    font-size: 2.4rem;
  }
}
#quickcheckoutconfirm .checkout__wrap {
  display: flex;
  position: relative;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .checkout__wrap > * {
  width: 50%;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap > * {
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--left-side {
    margin-right: 2.34375vw;
  }
}
#quickcheckoutconfirm .checkout__wrap--left-side__content--wrap {
  background: #f5f3f0;
  padding-bottom: 25px;
}
#quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .shipping > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .payment > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > #terms > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .address > *:not(.address__type) {
  margin: 25px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .shipping > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .payment > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > #terms > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .address > *:not(.address__type) {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .shipping > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .payment > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > #terms > *:not(.address__type), #quickcheckoutconfirm .checkout__wrap--left-side__content--wrap > .address > *:not(.address__type) {
    margin: 10px 15px;
  }
}
#quickcheckoutconfirm .checkout__wrap--left-side__content--wrap .payment__wrap {
  margin-top: 15px;
}
#quickcheckoutconfirm .checkout__wrap--right-side {
  background: #f5f3f0;
  padding: 25px;
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    margin-left: 2.34375vw;
  }
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .checkout__wrap--right-side {
    padding: 15px;
  }
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout__wrap--right-side__content--wrap {
    padding-bottom: 60px;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type {
  display: flex;
}
#quickcheckoutconfirm .checkout__wrap .address__type > * {
  cursor: pointer;
  flex: 1;
  background-color: var(--white);
  color: var(--black);
  font-family: var(--primary-font), sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 20px 25px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout__wrap .address__type > * {
    padding: 15px 20px;
  }
}
#quickcheckoutconfirm .checkout__wrap .address__type--active {
  background-color: #f5f3f0;
  text-decoration: underline;
}
#quickcheckoutconfirm .checkout__wrap .address__customer-type--wrap {
  margin-bottom: 15px;
}
#quickcheckoutconfirm .checkout__wrap .terms__wrap {
  background-color: red;
}
#quickcheckoutconfirm .checkout__wrap .terms a {
  color: var(--primary-color);
}
#quickcheckoutconfirm label {
  margin-bottom: 0;
  font-weight: initial;
}
#quickcheckoutconfirm label a {
  font-weight: bold;
  text-decoration: underline;
}
#quickcheckoutconfirm .option__wrap:not(:last-child) {
  margin-bottom: 10px;
}
#quickcheckoutconfirm .option__wrap__heading {
  display: none;
}
#quickcheckoutconfirm [type=button] {
  width: 100%;
}
#quickcheckoutconfirm [type=button]:hover {
  color: var(--black) !important;
  background-color: transparent !important;
  border-color: var(--black) !important;
}
#quickcheckoutconfirm .radio__wrap,
#quickcheckoutconfirm .checkbox__wrap {
  display: flex;
  align-items: center;
}
#quickcheckoutconfirm .radio__wrap [type=radio],
#quickcheckoutconfirm .radio__wrap [type=checkbox],
#quickcheckoutconfirm .checkbox__wrap [type=radio],
#quickcheckoutconfirm .checkbox__wrap [type=checkbox] {
  margin-right: 10px;
}
#quickcheckoutconfirm .checkbox__wrap {
  line-height: 1;
  margin-bottom: 10px;
  width: 100%;
}
#quickcheckoutconfirm .checkbox__wrap span {
  cursor: pointer;
}
#quickcheckoutconfirm .checkbox__wrap--show-passwords {
  display: none;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .checkbox__wrap--agree {
    width: 100%;
    margin-bottom: 15px !important;
    justify-content: center;
  }
}
#quickcheckoutconfirm .radio__wrap span,
#quickcheckoutconfirm .radio__wrap input {
  cursor: pointer;
}
#quickcheckoutconfirm .field__wrap {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (max-width: 768px) {
  #quickcheckoutconfirm .field__wrap {
    flex-direction: column;
  }
}
#quickcheckoutconfirm .field__wrap--input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: calc(50% - max(30px, 1.5625vw) / 2);
}
@media screen and (max-width: 768px) {
  #quickcheckoutconfirm .field__wrap--input {
    margin-right: 0 !important;
    width: 100%;
  }
}

.checkout__cart {
  padding-top: 15px;
  padding-bottom: 15px;
}
.checkout__cart__head,
.checkout__cart .checkout__cart__content--product {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
  padding: 15px 10px;
  background-color: #f5f3f0;
}
@media screen and (max-width: 600px) {
  .checkout__cart__head > *,
  .checkout__cart .checkout__cart__content--product > * {
    font-size: 1.2rem;
  }
}
.checkout__cart__head .checkout__cart--image,
.checkout__cart .checkout__cart__content--product .checkout__cart--image {
  width: 15%;
  min-width: max(60px, 4.1666666667vw);
}
.checkout__cart__head .checkout__cart--name,
.checkout__cart .checkout__cart__content--product .checkout__cart--name {
  width: 35%;
  margin-right: auto;
  margin-left: 7px;
  text-transform: uppercase;
}
.checkout__cart__head .checkout__cart--name small,
.checkout__cart .checkout__cart__content--product .checkout__cart--name small {
  font-size: 85% !important;
}
.checkout__cart__head .checkout__cart--quantity,
.checkout__cart .checkout__cart__content--product .checkout__cart--quantity {
  width: 25%;
  text-align: center;
}
.checkout__cart__head .checkout__cart--price,
.checkout__cart .checkout__cart__content--product .checkout__cart--price {
  display: none;
}
.checkout__cart__head .checkout__cart--total,
.checkout__cart .checkout__cart__content--product .checkout__cart--total {
  text-align: right;
  width: 20%;
}
.checkout__cart__head {
  background: var(--black);
  color: #fff;
  padding: 15px 10px;
}
.checkout__cart__head .checkout__cart--image {
  opacity: 0;
}
.checkout__cart__content .checkout__cart--name {
  text-decoration: none;
  font-size: 1.4rem;
  color: var(--black);
}
.checkout__cart__content .checkout__cart--name > *:first-child {
  font-family: var(--secondary-font), sans-serif;
}
.checkout__cart__content .checkout__cart--name--giftwrap {
  margin-top: 10px;
}
.checkout__cart__content .checkout__cart--name--giftwrap .checkbox__wrap {
  font-size: 1.2rem;
  margin-bottom: unset;
}
.checkout__cart__content .checkout__cart--name--giftwrap .checkbox__wrap span {
  margin-bottom: 3px;
}
.checkout__cart__output--totals {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.checkout__cart__output--totals span {
  font-size: 1.4rem;
}
.checkout__cart__output--totals span:first-child {
  font-weight: 500;
}
.checkout__cart__output--totals:first-child {
  margin-top: 15px;
}
.checkout__cart__output--totals:last-child {
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  margin-top: 15px;
}
.checkout__cart__output--totals:last-child span {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.checkout__cart--image img {
  width: 100%;
  max-width: 80px;
  border: 1px solid var(--black);
  background-color: #fff;
}
@media screen and (max-width: 600px) {
  .checkout__cart--name .checkbox__wrap {
    font-size: 1.1rem;
  }
}
.checkout__cart--quantity__wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.checkout__cart--quantity__control {
  border: 1px solid var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.checkout__cart--quantity__control > * {
  padding: 5px !important;
  display: flex;
  align-items: center;
  height: 33px;
}
.checkout__cart--quantity__control input {
  border: none !important;
  text-align: center;
}
.checkout__cart--quantity__control button {
  background-color: transparent;
  border: none;
}
.checkout__cart--quantity__update, .checkout__cart--quantity__remove {
  background-color: unset;
  padding: 0;
  margin: 5px;
}
.checkout__cart--quantity__update {
  margin-left: 15px;
}
.checkout__cart--quantity__remove {
  position: absolute;
  right: min(-35px, -3.125vw);
}
@media screen and (max-width: 600px) {
  .checkout__cart--quantity__remove {
    display: none;
  }
}
.checkout__cart--textarea {
  display: flex;
  width: 100%;
  min-height: 140px;
  margin-top: 15px;
  margin-bottom: 30px;
  resize: vertical;
}

#quickcheckoutconfirm .text-danger {
  font-size: 1.2rem;
  color: #e45451;
}

#quickcheckoutconfirm .redeem__wrap {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
#quickcheckoutconfirm .redeem--item {
  display: flex;
}
#quickcheckoutconfirm .redeem--heading {
  display: none;
}
#quickcheckoutconfirm .redeem--content {
  display: flex;
  width: 100%;
}
#quickcheckoutconfirm .redeem--content input {
  flex: 1;
  border: 1px solid var(--black);
  padding: 12px 10px;
}
#quickcheckoutconfirm .redeem--content input::placeholder {
  color: var(--black);
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .redeem--content input {
    padding: 10px;
  }
}
#quickcheckoutconfirm .redeem--content button {
  width: 100%;
  max-width: 135px;
  padding: 5px 20px;
  white-space: normal;
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .redeem--content button {
    padding: 13px 15px 10px;
  }
}
@media screen and (max-width: 600px) {
  #quickcheckoutconfirm .prd-remove {
    font-size: 12px;
  }
}

#quickcheckoutconfirm .popup-login {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
#quickcheckoutconfirm .popup-login > span {
  content: "";
  background-color: rgb(41, 41, 41);
  opacity: 0.7;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
#quickcheckoutconfirm .popup-login.open {
  display: flex;
  margin: 0 !important;
}
#quickcheckoutconfirm .popup-login__wrap {
  background-color: var(--white);
  width: 100%;
  max-width: 600px;
  margin: 20px;
}
#quickcheckoutconfirm .popup-login__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--light-gray);
  background-color: #f5f3f0;
}
#quickcheckoutconfirm .popup-login .login-fields {
  width: 100%;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap {
  padding: 40px;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap input {
  margin-bottom: 15px;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap a {
  text-decoration: underline;
}
#quickcheckoutconfirm .popup-login .login-fields--wrap button {
  margin-top: 35px;
}

#quickcheckoutconfirm .checkout-header {
  width: 100%;
  padding: 40px;
  margin-bottom: 55px;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white);
    padding: 15px;
    z-index: 1;
    border-bottom: 2px solid var(--black);
    margin-bottom: unset;
  }
}
#quickcheckoutconfirm .checkout-header__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
#quickcheckoutconfirm .checkout-header__wrap > * {
  width: 300px;
  color: var(--primary-color);
}
#quickcheckoutconfirm .checkout-header__wrap > * * {
  white-space: nowrap;
}
#quickcheckoutconfirm .checkout-header .checkout-header--logo {
  display: flex;
  justify-content: center;
  align-content: center;
}
#quickcheckoutconfirm .checkout-header--back-link {
  text-decoration: underline;
  display: inline-block;
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--back-link {
    margin-bottom: 5px;
  }
}
#quickcheckoutconfirm .checkout-header--assist {
  text-align: right;
}
@media screen and (min-width: 992px) {
  #quickcheckoutconfirm .checkout-header--assist {
    padding-right: 25px;
  }
}
@media screen and (max-width: 991px) {
  #quickcheckoutconfirm .checkout-header--assist {
    display: flex;
    flex-direction: column;
    width: auto;
    text-align: center;
  }
}
#quickcheckoutconfirm .checkout-header ._underline {
  text-decoration: underline;
}
#quickcheckoutconfirm .checkout-header .support-icon {
  position: relative;
  top: 5px;
  margin: 0 5px;
}

#quickcheckoutconfirm #d_social_login {
  display: unset;
  width: unset;
  max-width: unset;
  margin: unset;
}

#checkout-safety-net {
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.address__title {
  margin-top: 40px;
}

#quickcheckoutconfirm .alert-danger {
  font-size: 13px;
  color: #ff0000;
  display: block;
  text-align: left;
  background: none;
}

#quickcheckoutconfirm .alert.alert-success {
  font-size: 13px;
  display: block;
  text-align: left;
  background: none;
}

.redeem__wrap .alert-success {
  text-align: left;
  font-size: 13px;
  color: #3c763d;
  background-color: unset;
}

#payment-address {
  margin-bottom: 25px;
}

#payment-address .quickcheckout-content .hide {
  display: none !important;
}

/* cart page */
#checkout-cart .cart-total {
  margin-top: 35px;
}
#checkout-cart .cart-total .col-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
}
@media (max-width: 660px) {
  #checkout-cart .cart-total .col-total {
    justify-content: space-between;
  }
}
#checkout-cart .cart-total .col-total:last-child {
  display: none;
}
@media (max-width: 660px) {
  #checkout-cart .flex-row.buttons {
    flex-direction: column;
  }
  #checkout-cart .flex-row.buttons .btn-default:first-child, #checkout-cart .flex-row.buttons #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:first-child:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body #checkout-cart .flex-row.buttons [type=button]:first-child:not([data-dismiss=alert]), #checkout-cart .flex-row.buttons #quickcheckoutconfirm [type=button]:first-child, #quickcheckoutconfirm #checkout-cart .flex-row.buttons [type=button]:first-child, #checkout-cart .flex-row.buttons #quickcheckoutconfirm .popup-login .login-fields--wrap button:first-child, #quickcheckoutconfirm .popup-login .login-fields--wrap #checkout-cart .flex-row.buttons button:first-child {
    margin-right: 0;
  }
  #checkout-cart .flex-row.buttons .btn-default:nth-child(2), #checkout-cart .flex-row.buttons #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:nth-child(2):not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body #checkout-cart .flex-row.buttons [type=button]:nth-child(2):not([data-dismiss=alert]), #checkout-cart .flex-row.buttons #quickcheckoutconfirm [type=button]:nth-child(2), #quickcheckoutconfirm #checkout-cart .flex-row.buttons [type=button]:nth-child(2), #checkout-cart .flex-row.buttons #quickcheckoutconfirm .popup-login .login-fields--wrap button:nth-child(2), #quickcheckoutconfirm .popup-login .login-fields--wrap #checkout-cart .flex-row.buttons button:nth-child(2) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.about-container {
  padding: 0 80px 120px;
}
@media (max-width: 1440px) {
  .about-container {
    padding: 0 40px 80px;
  }
}
@media (max-width: 1024px) {
  .about-container {
    padding: 0 15px 75px;
  }
}
.about-wrapper {
  width: 100%;
  max-width: 1568px;
  margin: 0 auto;
}
.about-description {
  padding: max(75px, 5.8333333333vw) 0 40px;
}
@media (max-width: 660px) {
  .about-description {
    padding: 40px 0 25px;
  }
}
.about-description h2 {
  font-family: var(--secondary-font), sans-serif;
  font-size: max(30px, 6.25vw);
  font-weight: 700;
  text-transform: uppercase;
}
.about-description p {
  width: 100%;
  max-width: 596px;
  text-align: center;
}
.about-images {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .about-images {
    flex-direction: column;
  }
}
.about-images div:first-child {
  margin-right: 15px;
}
@media (max-width: 767px) {
  .about-images div:first-child {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.about-pillars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.about-pillars-item {
  flex-basis: calc(33.3333% - 15px);
  margin-right: 15px;
}
@media (max-width: 767px) {
  .about-pillars-item {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.about-pillars-item h3 {
  font-family: var(--secondary-font), sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}
.about-section-1 .about-description p {
  margin: 0 auto;
}
.about-section-2 .about-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.about-section-2 .about-description p {
  text-align: left;
}
.about-section-3 .about-description {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.about-section-3 .about-description p {
  text-align: right;
}

.error-container {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  padding: 0 15px;
}
.error-container #content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}
.error-container #content .btn-default, .error-container #content #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body [type=button]:not([data-dismiss=alert]), #checkout-checkout #accordion .panel .panel-collapse.collapse.in .panel-body .error-container #content [type=button]:not([data-dismiss=alert]), .error-container #content #quickcheckoutconfirm [type=button], #quickcheckoutconfirm .error-container #content [type=button], .error-container #content #quickcheckoutconfirm .popup-login .login-fields--wrap button, #quickcheckoutconfirm .popup-login .login-fields--wrap .error-container #content button {
  margin-top: 35px;
}

/*========================================================*/
/*** CATEGORY ***/
/*========================================================*/
.product-choices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background: var(--white);
  border-bottom: 2px solid var(--black);
}
@media (max-width: 1140px) {
  .product-choices {
    padding: 15px;
  }
}
@media screen and (max-width: 991px) {
  .product-choices {
    padding: 20px 15px;
    align-items: flex-end;
  }
}
.product-choices .product-sorting {
  width: 100%;
  max-width: 250px;
}
@media screen and (max-width: 991px) {
  .product-choices .product-sorting {
    max-width: 200px;
  }
  .product-choices .product-sorting .select2-container--default .select2-selection--single {
    padding: 0;
  }
}
.product-choices .product-sorting .select2-container--default .select2-selection--single {
  padding: 3px 0;
}
.product-choices #sub-category-items {
  flex-basis: 85%;
  display: flex;
}
@media screen and (max-width: 991px) {
  .product-choices #sub-category-items {
    flex-basis: 50%;
  }
}
.product-choices #sub-category-items .list-group {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}
.product-choices #sub-category-items .list-group-item {
  font-family: var(--secondary-font), sans-serif;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  color: var(--black);
  font-weight: 400;
  padding: 0;
  margin: 0 25px 0 0;
  position: relative;
}
@media screen and (max-width: 991px) {
  .product-choices #sub-category-items .list-group-item {
    display: none;
  }
}
.product-choices #sub-category-items .list-group-item:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}
.product-choices #sub-category-items .list-group-item:hover:after {
  width: 100%;
  transition: width 0.3s ease;
}
.product-choices #sub-category-items .list-group-item.active:after {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .product-choices #sub-category-items .list-group-item.active {
    display: block;
  }
}

/*** product-list ***/
.product-list {
  display: flex;
  flex-wrap: wrap;
}
.product-list__item {
  flex-basis: 25%;
  border: 2px solid var(--black);
  border-top: none;
  border-left: none;
}
@media screen and (max-width: 991px) {
  .product-list__item {
    flex-basis: 50%;
  }
}
@media screen and (max-width: 660px) {
  .product-list__item {
    flex-basis: 50%;
    border: 1px solid var(--black);
    border-right: none;
    border-top: none;
  }
}

.category-banner {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
}
.category-banner img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 582px;
}
.category-banner video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: 582px;
}
.category-banner-text {
  text-transform: uppercase;
  padding: 15px;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page_category footer, .page_product footer, .page_search footer {
  border-top: none;
  position: relative;
}
.page_category footer:after, .page_product footer:after, .page_search footer:after {
  display: inline-block;
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: -2px;
  background-color: var(--black);
}

ul.pagination,
.load-more {
  display: none;
}

.select2-results__option {
  font-size: 13px;
  background-color: #f5f3f0;
}

#product-category .product .product-caption {
  display: none;
}
#product-category #content {
  min-height: unset;
}

@media screen and (max-width: 660px) {
  .category-banner img {
    min-height: unset;
  }
  .category-banner video {
    min-height: unset;
  }
  .category-banner-text {
    font-size: 10px;
  }
}
/*========================================================*/
/*** BRANDS ***/
/*========================================================*/
#product-manufacturer h1 {
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 15px 0;
}

ul.brand-anchor {
  padding-bottom: 30px;
}

ul.brand-anchor li {
  max-width: 30px;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0 7px 7px 0;
  background-color: #f0f0f0;
}

ul.brand-anchor li > a {
  font-size: 1.4rem;
  line-height: initial;
  font-weight: 400;
  color: #000;
  display: block;
  width: 100%;
  padding: 7px 0;
}

ul.brand-anchor li:hover {
  background-color: #000;
}

ul.brand-anchor li:hover a {
  color: #fff;
}

.brand-letter {
  display: inline-block;
  width: 100%;
  padding: 0 0 20px;
  margin: 20px 0;
  position: relative;
  border-bottom: 1px solid #eee;
}

.letter_only {
  display: inline-block;
  float: left;
  width: 10%;
}

.brands_per_letter {
  display: inline-block;
  float: left;
  width: 90%;
}

#product-manufacturer .brand-name {
  margin: 0 0 20px 0;
  font-weight: 800;
}

.brands_per_letter a {
  color: #000;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

/*========================================================*/
/*** PRODUCT PAGE ***/
/*========================================================*/
.product {
  /** Product Tabs **/
  /** Product Related **/
}
.product-container {
  padding: 50px 80px 130px 0;
}
@media (max-width: 991px) {
  .product-container {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .product-container {
    padding: 30px 15px;
  }
}
.product-wrapper {
  display: flex;
}
@media (max-width: 767px) {
  .product-wrapper {
    flex-direction: column;
  }
}
.product-left {
  flex-basis: 50%;
  position: relative;
}
.product-left-wrapper {
  position: relative;
  max-width: 865px;
  margin-right: 40px;
}
@media (max-width: 991px) {
  .product-left-wrapper {
    margin-right: 0;
  }
}
.product-right {
  flex-basis: 50%;
  max-width: 700px;
  margin-left: 40px;
}
@media (max-width: 767px) {
  .product-right {
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}
.product-images {
  position: relative;
}
.product-images ul {
  flex-direction: column;
}
@media (max-width: 767px) {
  .product-images ul {
    flex-direction: row;
  }
}
.product-wishlist-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  border: none;
  z-index: 9;
  background: transparent;
}
.product-wishlist-btn.active .product-wishlist svg {
  fill: var(--black);
}
.product-title {
  font-size: 50px;
  font-weight: 600;
  line-height: 1;
}
@media (max-width: 1280px) {
  .product-title {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .product-title {
    font-size: 28px;
  }
}
.product-price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .product-price {
    margin-bottom: 10px;
  }
}
.product-price li span {
  display: flex;
}
.product-price li:not(:last-child) {
  margin-right: 20px;
}
.product-price .price {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
}
.product-price .price-new {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
}
.product-price .price-old {
  font-size: 20px;
  font-weight: 200;
  text-decoration: line-through;
}
.product-plated {
  display: flex;
}
.product-plated .radio label {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-plated .radio label .product-plated-option {
  position: relative;
}
@media (max-width: 767px) {
  .product-plated .radio label .product-plated-option {
    max-width: 100px;
  }
}
.product-plated .radio label .option-value-name {
  font-size: 16px;
  margin-bottom: 10px;
}
.product-plated .radio label img {
  position: relative;
  border-radius: 0;
  background: transparent;
}
.product-plated .radio label input[type=radio] {
  margin-left: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid var(--black);
  border-radius: 0;
}
.product-plated .radio label input[type=radio]:checked {
  background-color: #fff;
}
.product-plated .radio:not(:last-child) {
  margin-right: 15px;
}
.product-plated .radio + .radio {
  margin-top: 10px;
}
.product-quantity-title {
  margin-top: 30px;
}
.product-tabs {
  margin: 0 0 20px;
}
.product-tab-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--secondary-font), sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  color: #1E1E1E;
}
.product-tab-heading .tab-arrow {
  width: 17px;
  height: 17px;
  display: inline-flex;
}
.product-tab-heading .tab-arrow svg {
  width: 17px;
  height: 17px;
  display: inline-flex;
}
.product-tab-content {
  text-align: justify;
  display: none;
  padding: 15px 30px 0 0;
}
@media (max-width: 1280px) {
  .product-tab-content {
    padding: 10px 20px 0 0;
  }
}
@media (max-width: 991px) {
  .product-tab-content {
    padding: 0;
  }
}
.product-tab-content .panel-body {
  padding: 0;
}
.product-tab.active .product-tab-content {
  display: block;
  transition: height 0.03s ease;
}
.product-tab.active .tab-arrow {
  transform: rotate(-90deg);
  transition: 200ms linear all;
}
.product-related {
  margin: 30px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .product-related {
    margin: 30px 15px;
  }
}
.product-related h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
}
@media (max-width: 991px) {
  .product-related h3 {
    font-size: 18px;
  }
}

#product .select-option {
  width: 100%;
  max-width: 265px;
}

div.required .control-label:before {
  display: none;
}

/** Size Guide **/
.size-guide {
  display: none;
  width: 60%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 0;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 0;
  z-index: 9999;
  max-height: 85vh;
  overflow-y: auto;
}
@media (max-width: 660px) {
  .size-guide {
    width: 90%;
  }
}
.size-guide::-webkit-scrollbar {
  width: 6px;
}
.size-guide-trigger {
  text-decoration: underline;
}
.size-guide-trigger:hover, .size-guide-trigger:focus, .size-guide-trigger:active {
  text-decoration: underline;
}
.size-guide.active {
  display: block;
}
.size-guide-container {
  padding: max(15px, 2.6041666667vw);
  border: none;
}
.size-guide-container .close-size-guide-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 99;
}
.size-guide-container .close-size-guide-btn .close-btn-icon {
  display: inline-block;
  width: 35px;
  height: 35px;
}
.size-guide-container .close-size-guide-btn .close-btn-icon svg {
  display: inline-block;
  width: 35px;
  height: 35px;
}
.size-guide-container .close-size-guide-btn .close-btn-icon svg .st0 {
  stroke: var(--black);
}

/** Product Quantity **/
.quantity-input-field {
  width: 100%;
  max-width: 90px;
}
.quantity-input-field .quantity {
  position: relative;
  border: 1px solid var(--black);
}
.quantity-input-field .quantity .number-input {
  position: relative;
}
.quantity-input-field .quantity input[type=text] {
  font-size: 20px;
  background-color: transparent;
  padding: 0;
  color: var(--black);
  text-align: center;
  box-shadow: none;
  appearance: none;
  border: none;
  border-radius: 0;
  min-height: 35px;
}
@media (max-width: 767px) {
  .quantity-input-field .quantity input[type=text] {
    font-size: 18px;
    min-height: 30px;
  }
}
.quantity-input-field .quantity .quantity-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quantity-input-field .quantity .quantity-nav .quantity-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}
.quantity-input-field .quantity .quantity-nav .quantity-button.quantity-down:after {
  content: "";
  background-image: url("../../../assets/icons/minus-icon.svg");
  width: 13px;
  height: 13px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 660px) {
  .quantity-input-field .quantity .quantity-nav .quantity-button.quantity-down:after {
    width: 10px;
    height: 10px;
  }
}
.quantity-input-field .quantity .quantity-nav .quantity-button.quantity-up:after {
  content: "";
  background-image: url("../../../assets/icons/plus-icon.svg");
  width: 13px;
  height: 13px;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 660px) {
  .quantity-input-field .quantity .quantity-nav .quantity-button.quantity-up:after {
    width: 10px;
    height: 10px;
  }
}

/*========================================================*/
/*** SINGLE PRODUCT ***/
/*========================================================*/
.product {
  position: relative;
  height: 100%;
}
.product-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 100%;
}
.product-image-thumb {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-height: 400px;
  max-height: 582px;
}
@media screen and (max-width: 660px) {
  .product-image-thumb {
    min-height: unset;
  }
}
.product-image-hover {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
  object-fit: cover;
  width: 100%;
  min-height: 400px;
  max-height: 582px;
}
@media screen and (max-width: 991px) {
  .product-image-hover {
    display: none;
  }
}
.product-image:hover .product-image-hover {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
  z-index: 1;
}
.product-caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 25px;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .product-caption {
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 660px) {
  .product-caption {
    bottom: 20px;
  }
}
.product-caption .price-new {
  font-size: 16px;
  line-height: 1.3;
}
.product-caption .price-old {
  font-size: 14px;
}
.product-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

#button-cart {
  margin: 30px 0;
}

/** Product Wishlist **/
.product-wishlist {
  display: flex;
  width: 30px;
  height: 28px;
}
.product-wishlist svg {
  display: flex;
  width: 30px;
  height: 28px;
}
.product-wishlist:hover svg {
  fill: var(--black);
}

/** Swiper Dots **/
.swiper-pagination-bullet {
  position: relative;
  background-color: transparent;
  width: 16px;
  height: 16px;
  border: 1px solid var(--black);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--black);
}

.page_product .product-list__item, .home .product-list__item {
  border-top: 2px solid var(--black);
}

#product .form-group {
  display: none;
}

#product-product .product-right .has-error .select2-selection.select2-selection--single {
  box-shadow: none !important;
}

@media screen and (max-width: 660px) {
  #product-category .product-heading {
    font-size: 16px;
  }
  #product-category .product-caption .price-new {
    font-size: 14px;
  }
  #product-category .product-wishlist svg {
    width: 22px;
  }
  #product-category .product-wishlist-btn {
    top: 10px;
    right: 5px;
  }
  #product-category .product-caption {
    bottom: 0;
  }
}
/*========================================================*/
/*** CONTACT ***/
/*========================================================*/
#information-contact .breadcrumb {
  display: none;
}

#information-contact #content {
  padding-bottom: 70px;
}

#information-contact h1 {
  text-align: center;
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  padding: 35px 0 30px;
}

#information-contact h3 {
  font-size: 1.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  text-transform: uppercase;
}

#information-contact form h3 {
  padding-bottom: 20px;
}

ul.contact-info > li,
ul.contact-info > li > a {
  font-size: 1.3rem;
  line-height: 2.4rem;
  letter-spacing: 0.025em;
  font-weight: 500;
  color: var(--oil);
}

ul.contact-info > li > a.email-address {
  text-decoration: underline;
}

#information-contact .stores-info {
  padding-top: 50px;
}

#information-contact .addresses {
  padding-bottom: 40px;
}

#contact-us .section-wrap {
  padding-left: 12.7083333333vw;
  padding-right: 12.7083333333vw;
}
@media screen and (max-width: 1440px) {
  #contact-us .section-wrap {
    padding-left: 6.3541666667vw;
    padding-right: 6.3541666667vw;
  }
}
@media screen and (max-width: 991px) {
  #contact-us .section-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
#contact-us .section-wrap h1 {
  text-align: center;
  padding-top: 3.3333333333vw;
  padding-bottom: 0.9895833333vw;
}
#contact-us .section-wrap p {
  text-align: center;
  margin-bottom: 0.8854166667vw;
}
@media screen and (max-width: 991px) {
  #contact-us .section-wrap p {
    padding-left: 8.5px;
    padding-right: 8.5px;
  }
}
#contact-us .section-wrap .contact-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 30px;
}
#contact-us .section-wrap .contact-block._top {
  padding-bottom: 4.1666666667vw;
  padding-right: 0;
}
#contact-us .section-wrap .contact-block._top .contact-image-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 6.25vw;
}
#contact-us .section-wrap .contact-block._top .contact-image-wrap img {
  width: 100%;
}
#contact-us .section-wrap .contact-block._bottom {
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
}
#contact-us .section-wrap .contact-block .addresses .address {
  border-top: 1px solid #B3B3B3;
  padding-top: 30px;
}
#contact-us .section-wrap .contact-block .addresses .address:first-child {
  border-top: none;
  padding-top: 0;
}
#contact-us .section-wrap .contact-block .addresses .address h2 {
  font-size: var(--text);
  color: var(--black);
  font-weight: 500;
  font-size: 20px;
}
#contact-us .section-wrap .contact-block .addresses .address .contact-info {
  margin-bottom: 41px;
}
#contact-us .section-wrap .contact-block .addresses .address .contact-info:last-child {
  margin-bottom: 25px;
}
#contact-us .section-wrap .contact-block .addresses .address .contact-info li {
  display: inline;
}
#contact-us .section-wrap .contact-block .addresses .address h3 {
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
}
#contact-us .section-wrap .contact-block form fieldset {
  display: flex;
  flex-direction: column;
}
#contact-us .section-wrap .contact-block form fieldset > span {
  margin-bottom: 50px;
  text-align: center;
}
#contact-us .section-wrap .contact-block form fieldset .form-field {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 30px;
}
#contact-us .section-wrap .contact-block form fieldset .form-field input,
#contact-us .section-wrap .contact-block form fieldset .form-field textarea {
  margin-bottom: 5px;
}
#contact-us .section-wrap .contact-block form fieldset .field-group {
  display: flex;
}
@media screen and (max-width: 660px) {
  #contact-us .section-wrap .contact-block form fieldset .field-group {
    flex-direction: column;
  }
}
@media screen and (min-width: 661px) {
  #contact-us .section-wrap .contact-block form fieldset .field-group .form-field:first-child {
    margin-right: 25px;
  }
  #contact-us .section-wrap .contact-block form fieldset .field-group .form-field:last-child {
    margin-left: 2.5px;
  }
}
#contact-us .section-wrap .contact-block:last-child {
  padding-right: 0;
  padding-left: 30px;
  flex: 1.9;
}
#contact-us .section-wrap .contact-block .image-map-container {
  margin-top: 4.1666666667vw;
  padding-top: 4.1666666667vw;
  border-top: 1px solid #B3B3B3;
  justify-content: space-between;
}
#contact-us .section-wrap .contact-block .image-map-container .image-container {
  width: calc(50% - 15px);
}
#contact-us .section-wrap .contact-block .image-map-container .map-container {
  width: calc(50% - 15px);
}
#contact-us .section-wrap .contact-block .image-map-container .map-container iframe {
  width: 100%;
  height: 100%;
}
#contact-us .section-wrap .main-contact-block {
  display: flex;
}
@media screen and (max-width: 991px) {
  #contact-us .section-wrap .main-contact-block {
    flex-direction: column;
    max-width: 705px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 1440px) and (max-width: 1440px) {
  #contact-us .section-wrap .main-contact-block .contact-block:first-child {
    min-width: 280px;
    flex: 1;
  }
}
@media screen and (max-width: 1440px) and (max-width: 991px) {
  #contact-us .section-wrap .main-contact-block .contact-block:first-child {
    margin-top: 20px;
    padding-right: 0;
    border-bottom: 1px solid #B3B3B3;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1440px) and (max-width: 1440px) {
  #contact-us .section-wrap .main-contact-block .contact-block:last-child {
    flex: 2;
  }
}
@media screen and (max-width: 1440px) and (max-width: 991px) {
  #contact-us .section-wrap .main-contact-block .contact-block:last-child {
    padding-left: 0;
  }
}
@media screen and (max-width: 1440px) and (max-width: 660px) {
  #contact-us .section-wrap .main-contact-block .contact-block .image-map-container {
    flex-direction: column;
    margin-top: 30px;
    padding-top: 30px;
  }
}
@media screen and (max-width: 1440px) {
  #contact-us .section-wrap .main-contact-block .contact-block .image-map-container .image-container {
    width: calc(50% - 7px);
  }
}
@media screen and (max-width: 1440px) and (max-width: 660px) {
  #contact-us .section-wrap .main-contact-block .contact-block .image-map-container .image-container {
    width: 100%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1440px) {
  #contact-us .section-wrap .main-contact-block .contact-block .image-map-container .map-container {
    width: calc(50% - 7px);
  }
}
@media screen and (max-width: 1440px) and (max-width: 660px) {
  #contact-us .section-wrap .main-contact-block .contact-block .image-map-container .map-container {
    width: 100%;
    height: 450px;
  }
}

/*========================================================*/
/*** SEARCH RESULTS ***/
/*========================================================*/
#product-search .product-choices .search-col {
  flex-basis: 33.333%;
}
#product-search .product-choices .search-col .search-results {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 991px) {
  #product-search .product-choices .search-col .search-results {
    align-items: unset;
  }
}
#product-search .product-choices .search-col .search-results .search-input {
  display: flex;
  border-bottom: 1px solid var(--black);
}
#product-search .product-choices .search-col .search-total {
  display: flex;
  justify-content: flex-end;
}
#product-search .product-choices .search-col .search-total p {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  #product-search .product-choices {
    align-items: flex-end;
  }
  #product-search .product-choices .search-col:nth-child(1) {
    display: none;
  }
}
#product-search .no-search-items {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  padding: 25px;
  text-align: center;
}

.search-input #input-search {
  text-transform: uppercase;
}
.search-input #input-search::placeholder {
  font-size: 22px;
  text-transform: uppercase;
}

/*========================================================*/
/*** INFORMATION PAGES ***/
/*========================================================*/
.information-container {
  padding: 100px 40px;
}
@media (max-width: 991px) {
  .information-container {
    padding: 55px 15px;
  }
}
.information-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1110px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .information-wrapper {
    flex-direction: column;
  }
}
.information-menu {
  flex-basis: 30%;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .information-menu {
    margin-right: 0;
    margin-bottom: 35px;
  }
}
.information-menu-wrapper {
  position: sticky;
  top: calc(var(--headerHeight) + 80px);
}
.information-menu-wrapper li {
  margin-bottom: 10px;
}
.information-menu-wrapper li a {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-family: var(--secondary-font);
  font-size: 18px;
}
.information-menu-wrapper li a.active:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: 0;
  left: 0;
}
.information-content {
  flex-basis: 70%;
  text-align: justify;
}
.information-content h1, .information-content h2, .information-content h3, .information-content h4, .information-content h5, .information-content h6 {
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.information-links:hover {
  cursor: pointer;
}

#blog .section-wrap {
  padding-left: 12.7083333333vw;
  padding-right: 12.7083333333vw;
}
@media screen and (max-width: 1440px) {
  #blog .section-wrap {
    padding-left: 6.3541666667vw;
    padding-right: 6.3541666667vw;
  }
}
@media screen and (max-width: 991px) {
  #blog .section-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
#blog .section-wrap h1 {
  text-align: center;
  padding-top: 3.3333333333vw;
  padding-bottom: 1.8229166667vw;
  margin-bottom: 0;
}
@media screen and (max-width: 991px) {
  #blog .section-wrap h1 {
    padding-top: 30px;
    padding-bottom: 45px;
  }
}
#blog .section-wrap p {
  text-align: center;
  margin-bottom: 3.6458333333vw;
}
@media screen and (max-width: 991px) {
  #blog .section-wrap p {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 991px) {
  #blog .section-wrap p {
    padding-left: 8.5px;
    padding-right: 8.5px;
  }
}

#post .section-wrap.the-post {
  width: 100%;
  max-width: 860px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 7.96875vw;
}
@media screen and (max-width: 991px) {
  #post .section-wrap.the-post {
    padding-bottom: 110px;
  }
}
#post .section-wrap.the-post .full-post-date {
  display: inherit;
  text-align: center;
  padding-top: 3.125vw;
}
@media screen and (max-width: 991px) {
  #post .section-wrap.the-post .full-post-date {
    padding-top: 45px;
  }
}
#post .section-wrap.the-post h1 {
  text-align: center;
  padding-top: 3.3333333333vw;
  padding-bottom: 1.8229166667vw;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 991px) {
  #post .section-wrap.the-post h1 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
#post .section-wrap.the-post .post-main-image {
  margin-bottom: 3.125vw;
}
@media screen and (max-width: 991px) {
  #post .section-wrap.the-post .post-main-image {
    margin-bottom: 20px;
  }
}
#post .section-wrap.the-post p {
  text-align: justify;
  margin-bottom: 1.8229166667vw;
}
@media screen and (max-width: 991px) {
  #post .section-wrap.the-post p {
    margin-bottom: 35px;
    padding-left: 8.5px;
    padding-right: 8.5px;
  }
  #post .section-wrap.the-post p:empty {
    display: none;
  }
}
#post .section-wrap.the-post p:last-child {
  margin-bottom: 0;
}
#post .section-wrap.the-post p > img {
  margin-top: 0.78125vw;
  margin-bottom: 0.78125vw;
  margin-left: auto;
  margin-right: auto;
}
#post .section-wrap.the-post p a {
  display: inline-flex;
  align-items: center;
}
#post .section-wrap.the-post p a img {
  margin-top: 7.5px;
  margin-left: 15px;
}

.post-b-space {
  padding-bottom: 7.8125vw;
}

.faq-outer-container #column-left {
  max-width: 200px;
  width: 100%;
}
.faq-outer-container .faq-menu ul li {
  border-bottom: 1px solid var(--light-brown);
  padding: 5px 0;
}
.faq-outer-container .faq-menu a {
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 400;
}
.faq-outer-container .faq-menu a.active {
  color: var(--oil);
  font-weight: 500;
}
.faq-outer-container .faq-menu a:hover {
  color: var(--oil);
}
.faq-outer-container .faq-container {
  width: 100%;
  max-width: 915px;
  margin: 0 auto 0 clamp(30px, 5.2083vw, 100px);
}
@media (max-width: 767px) {
  .faq-outer-container .faq-container {
    margin: 30px auto;
  }
}
.faq-outer-container .information-container {
  padding: 0 30px 100px;
}
@media (max-width: 767px) {
  .faq-outer-container .information-container {
    padding: 0 10px 50px;
    flex-direction: column;
  }
}

.register-success-container {
  padding: clamp(30px, 3.3854vw, 50px) clamp(60px, 13.5417vw, 260px) clamp(60px, 13.5417vw, 260px);
}
@media (max-width: 991px) {
  .register-success-container {
    padding: clamp(30px, 3.3854vw, 50px) var(--side-padding) clamp(60px, 13.5417vw, 260px);
  }
}
.register-success-container .header-1, .register-success-container #quickcheckoutconfirm .checkout__main-title, #quickcheckoutconfirm .register-success-container .checkout__main-title, .register-success-container #contact-us .section-wrap h1, #contact-us .section-wrap .register-success-container h1, .register-success-container #blog .section-wrap h1, #blog .section-wrap .register-success-container h1, .register-success-container #post .section-wrap.the-post h1, #post .section-wrap.the-post .register-success-container h1 {
  margin: 25px 0 clamp(30px, 3.3854vw, 65px);
}
.register-success-container .register-success-wrapper {
  max-width: 1145px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .register-success-container .register-success-wrapper {
    flex-direction: column-reverse;
  }
}
.register-success-container .register-success-wrapper .left {
  max-width: 600px;
  width: 100%;
  margin-right: 45px;
}
@media (max-width: 991px) {
  .register-success-container .register-success-wrapper .left {
    margin: 30px 0 0;
  }
}
.register-success-container .register-success-wrapper .right {
  max-width: 460px;
  width: 100%;
  text-align: justify;
}
@media (max-width: 991px) {
  .register-success-container .register-success-wrapper .right {
    max-width: 600px;
  }
}

.voucher-container {
  padding: 80px;
}
@media screen and (max-width: 1140px) {
  .voucher-container {
    padding: 75px 15px;
  }
}
.voucher-container h1 {
  text-align: center;
  margin-bottom: max(30px, 4.1666666667vw);
}
.voucher-container .form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
}
.voucher-container h2 {
  margin-top: 50px;
  font-size: 22px;
}
.voucher-wrapper {
  max-width: 1460px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 991px) {
  .voucher-wrapper {
    flex-direction: column;
    max-width: 715px;
  }
}
.voucher-left {
  max-width: 715px;
  margin-right: 15px;
}
@media screen and (max-width: 991px) {
  .voucher-left {
    margin-right: 0;
  }
}
.voucher-right {
  max-width: 715px;
  margin-left: 15px;
  position: relative;
}
@media screen and (max-width: 1280px) {
  .voucher-right {
    max-width: 435px;
  }
}
@media screen and (max-width: 991px) {
  .voucher-right {
    margin-left: 0;
  }
}
.voucher-right-wrapper {
  position: sticky;
  top: calc(var(--headerHeight) + 30px);
}
.voucher-image {
  position: relative;
  border-radius: 16px;
}
.voucher-image .giftcard-amount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--secondary-font), sans-serif;
  font-size: 80px;
  width: 100%;
  text-align: center;
  color: var(--gray);
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .voucher-image .giftcard-amount {
    font-size: 30px;
  }
}

.input-radio-inline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
.input-radio-inline .radio:not(:last-child) {
  margin-right: 6px;
}
.input-radio-inline .radio label {
  display: block;
  position: relative;
  padding-left: 44px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  user-select: none;
  width: 120px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--black);
  border-radius: 32px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .input-radio-inline .radio label {
    width: 75px;
    height: 35px;
  }
}
@media screen and (max-width: 767px) {
  .input-radio-inline .radio label {
    font-size: 16px;
  }
}
.input-radio-inline .radio label input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.input-radio-inline .radio label input[type=radio]:checked ~ span, .input-radio-inline .radio label input[type=radio]:checked ~ span {
  background-color: var(--black);
  border: 1px solid var(--black);
  border-radius: 32px;
  color: var(--white);
}
.input-radio-inline .radio label span {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 120px;
  height: 50px;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .input-radio-inline .radio label span {
    width: 75px;
    height: 35px;
  }
}

.giftcard-image-mobile {
  max-width: 450px;
  margin: 0 auto;
}

.hero-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.hero-slider .slide-info {
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--white);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
@media (max-width: 660px) {
  .hero-slider .slide-info {
    width: 100%;
    padding: 0 10px;
  }
}
.hero-slider .slide-info .super-hero {
  display: block;
  text-transform: capitalize;
  font-size: clamp(3rem, 4.167vw, 8rem);
  text-align: center;
  margin: 0 0 40px;
}
@media (max-width: 991px) {
  .hero-slider .slide-info .super-hero {
    margin: 0 0 30px;
  }
}
@media (max-width: 660px) {
  .hero-slider .slide-info .super-hero {
    margin: 0 auto 30px;
    max-width: 350px;
  }
}
.hero-slider .slide-info .super-hero .smaller-text {
  font-size: clamp(2.437rem, 3.385vw, 6.5rem);
  text-transform: lowercase;
}
.hero-slider .slide-info .hero-title {
  margin: 25px 0 40px;
}
.hero-slider .swiper-slide img {
  margin: 0 auto;
}
.hero-slider .hero-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  max-width: 22px;
}
@media (max-width: 660px) {
  .hero-slider .hero-arrow {
    max-width: 15px;
  }
}
.hero-slider .hero-arrow.hero-button-prev {
  left: 2%;
}
.hero-slider .hero-arrow.hero-button-next {
  right: 2%;
}

.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px;
  margin: 0;
  background-color: #fff;
  color: var(--black);
  border: none;
  border-radius: 0;
  z-index: 9999;
  animation: note 4500ms forwards;
}
@media (max-width: 767px) {
  .alert {
    padding: 35px 25px;
    width: 90%;
    margin: 0;
  }
}
.alert a,
.alert a:hover {
  color: var(--black);
  font-weight: 500;
}
.alert .close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: inherit;
}
@media (max-width: 767px) {
  .alert .close {
    top: 10px;
    right: 10px;
  }
}

@keyframes note {
  0% {
    margin-top: 25px;
    opacity: 0;
  }
  10% {
    margin-top: 0;
    opacity: 1;
  }
  90% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: 25px;
    opacity: 0;
  }
}