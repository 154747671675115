.about {
  &-container {
    padding: 0 80px 120px;
    @media (max-width: $laptop) {
      padding: 0 40px 80px;
    }
    @media (max-width: $tablet-lg) {
      padding: 0 15px 75px;
    }
  }
  &-wrapper {
    width: 100%;
    max-width: 1568px;
    margin: 0 auto;
  }
  &-description {
    padding: max(75px, vw(112px)) 0 40px;
    @media (max-width: $mobile) {
      padding: 40px 0 25px;
    }
    h2 {
      font-family: $secondary_font;
      font-size: max(30px, vw(120px));
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      width: 100%;
      max-width: 596px;
      text-align: center;
    }
  }
  &-images {
    display: flex;
    justify-content: space-between;
    @media (max-width: $tablet-sm) {
      flex-direction: column;
    }
    div {
      &:first-child {
        margin-right: 15px;
        @media (max-width: $tablet-sm) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
    }
  }
  &-pillars {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &-item {
      flex-basis: calc(33.3333% - 15px);
      margin-right: 15px;
      @media (max-width: $tablet-sm) {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }
      h3 {
        font-family: $secondary_font;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
  &-section-1 {
    .about-description {
      p {
        margin: 0 auto;
      }
    }
  }
  &-section-2 {
    .about-description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        text-align: left;
      }
    }
  }
  &-section-3 {
    .about-description {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        text-align: right;
      }
    }
  }
}

