/*========================================================*/
/*** GLOBAL ACCOUNT ***/
/*========================================================*/


.has-page-message {

    &,
    &-error,
    &-success {
        margin: auto;
        max-width: 600px;
        text-align: center;
        border: 1px solid;
        padding: 15px;
        margin-bottom: 20px;
    }

    &-wrap {
        margin-left: 15px;
        margin-right: 15px;
    }

    &-error {
        color: #ff0000;
    }

    &-success {
        color: #224344;
    }
}

.account {
    &-container {
        padding: 100px 40px;
        @media (max-width: $tablet) {
            padding: 75px 15px;
        }
        .form-group {
            display: flex;
            flex-direction: column;
            margin: 0 0 15px;
        }
        h1, h2 {
            font-size: 26px;
            text-align: center;
            margin-bottom: 20px;
        }
        p {
            text-align: justify;
        }
       .list-unstyled {
           a {
               text-decoration: underline;
           }
       }
        .btn-default {
            margin-top: 35px;
        }
    }
    &-wrapper {
        max-width: 550px;
        margin: 0 auto;
    }
    &-wrapper-medium {
        max-width: 810px;
        margin: 0 auto;
    }
    //account login
    &-login-wrapper {
        max-width: 1220px;
        margin: 0 auto;
        #content {
            min-height: 500px;
            display: flex;
            align-items: center;
        }
        @media (max-width: $tablet-sm) {
            max-width: 550px;
        }

        .flex-row {
            justify-content: space-between;
            @media (max-width: $tablet-sm) {
                flex-direction: column;
            }
        }
        .left, .right {
            flex-basis: calc(50% - 30px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: $tablet) {
                flex-basis: calc(50% - 15px);
            }
        }
        .left {
            margin-right: 30px;
            @media (max-width: $tablet) {
                margin-right: 15px;
            }
            @media (max-width: $tablet-sm) {
                margin-right: 0;
                margin-bottom: 75px;
            }
        }
        .right {
            margin-left: 30px;
            @media (max-width: $tablet) {
                margin-left: 15px;
            }
            @media (max-width: $tablet-sm) {
                margin-left: 0;
            }
        }
    }
    &-list {
        text-align: center;
        margin-bottom: 40px;
    }
    //account wishlist
    &-wishlist {
        max-width: 810px;
        margin: 0 auto;
        .wishlist-row {
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            border-bottom: 1px solid $black;
            @media (max-width: $tablet) {
                padding: 30px 0;
            }
        }
    }
    &-form--field {
        width: 100%;
        display: flex;
        position: relative;
        align-items: flex-end;
        justify-content: space-between;
        input[type="email"] {
            width: 100%;
            &::placeholder {
                color: $black;
            }
        }
    }
}
#common-success {
    #content {
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
#account-reset {
    #content {
        min-height: unset;
    }
}

#checkout-cart {
    .prd-choices {
        .flex-row {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 180px;

            .refresh-button {
                max-width: 45px;
                border: none;
                background-color: transparent;
                margin: 0 0 0 5px;
                padding: 0;
                i.fa.fa-refresh {
                    color: $black;
                }
            }
        }
    }
}
.forgotten-text {
    color: $gray;
    text-decoration: underline;
    font-size: 16px;
    &:hover {
        color: $gray;
        text-decoration: underline;
    }
}
legend {
    font-family: var(--secondary-font),;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: $black;
    border: none;
    padding: 10px 0;
    margin: 0 0 15px;
    @media (max-width: $tablet) {
        font-size: 18px;
    }
}
.text-danger {
    font-size: 14px;
}
.flex-row {
    &.buttons {
        margin: 0;
        .btn-default {
            &:first-child {
                margin-right: 15px;
                @media (max-width: $tablet) {
                    margin-right: 7px;
                }
            }
            &:nth-child(2) {
                margin-left: 15px;
                @media (max-width: $tablet) {
                    margin-left: 7px;
                }
            }
        }
    }
}
.table-bordered {
    border: 1px solid #000;
    margin-bottom: 40px;
}
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #000;
}
.table-bordered > tbody > tr > td {
    .btn-default {
        margin: 0;
        padding: 10px;
    }
}
.table-bordered>thead {
    background-color: #000;
    color: #fff;
}