footer {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-top: 2px solid $black;
    .footer {
        &-container{
            background-color: $footer_background_color;
            color: $black;
            padding: 80px;
            @media screen and (max-width: $laptop) {
                padding: 80px 50px;
            }
            @media screen and (max-width: $laptop-xs) {
                padding: 80px 15px;
            }
            @media screen and (max-width: $tablet) {
                padding: 55px 15px;
            }
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: $tablet) {
                justify-content: unset;
                flex-wrap: wrap;
            }
        }
        &-column {
            @media screen and (max-width: $tablet) {
                flex-basis: 33.33%;
            }
            @media screen and (max-width: $mobile-md) {
                flex-basis: 50%;
            }
            &:not(:last-child) {
                margin-right: 30px;
                @media screen and (max-width: $tablet) {
                    margin-bottom: 35px;
                }
                @media screen and (max-width: $tablet) {
                    margin-right: unset;
                }
            }
        }
        &-title {
            font-family: $secondary_font;
            color: $black;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 16px;
        }
        &-list {
            li {
                margin-bottom: 8px;
            }
        }
        &-link {
            display: block;
            color: $black;
            font-size: 14px;
            &:hover, &:focus, &:active {
                color: $black;
            }
        }
        &-copyrights {
            background-color: $black;
            color: $white;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            padding: 16px;
        }
        &-newsletter {
            width: 100%;
            max-width: 345px;
            @media screen and (max-width: $laptop-xs) {
                max-width: 280px;
            }
            @media screen and (max-width: $tablet) {
                max-width: 345px;
            }
            @media screen and (max-width: $mobile-md) {
                max-width: 100%;
            }
            &-wrapper {
                position: relative;
                width: 100%;
                max-width: 400px;
                input[type='text'] {
                    position: relative;
                    width: 100%;
                    padding-right: 60px;
                    &::placeholder {
                        color: $black;
                    }
                }
            }
            &-subscribe {
                &-btn {
                    cursor: pointer;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 0;
                    background: transparent;
                }
                &-arrow {
                    display: flex;
                    width: 25px;
                    height: 15px;
                    svg {
                        display: flex;
                        width: 25px;
                        height: 15px;
                    }
                }
            }
        }
    }
}
.newsletter {
    &-popup {
        display: none;
        background-color: $white;
        color: $black;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 60px;
        z-index: 99;
        @media screen and (max-width: $tablet) {
            width: 70%;
        }
        @media screen and (max-width: $mobile) {
            padding: 50px 30px;
            width: 90%;
        }
        &-close {
            position: absolute;
            top: 20px;
            right: 20px;
        }
    }
    &-wrapper {
        h2 {
            font-family: $secondary_font;
            font-size: 22px;
            font-weight: 700;
        }
        p {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
    &-form {
        position: relative;
        input {
            width: 100%;
            &::placeholder {
                color: $black;
            }
        }
    }
    &-submit {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $white;
        &-arrow {
            display: flex;
            width: 25px;
            height: 16px;
            svg {
                display: flex;
                width: 25px;
                height: 16px;
            }
        }
    }
}

.footer-legal:hover{
    cursor:pointer;
}

.loader {
    display: none;
    border: 1px solid #000;
    border-top: 1px solid #000;
    border-radius: 50%;
    width: 15px;
    height: 23px;
    animation: spin 2s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}