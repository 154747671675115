/**breadcrumb**/
.breadcrumb {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 0 30px;
  border-radius: 0;
  li {
    padding: 0;
    text-shadow: none;
    a {
      text-transform: uppercase;
      display: inline-block;
      font-size: 22px;
      font-weight: 400;
      color: var(--black);
      @media (max-width: $tablet) {
        font-size: 18px;
      }
      @media (max-width: $tablet-sm) {
        font-size: 16px;
      }
      &:after {
        content: "/";
        display: inline-block;
        margin: 0 7px 0 10px;
      }
    }
    &::after {
      display: none;
    }
    &:last-child {
      a {
        color: $gray;
        &:after {
          display: none;
        }
      }
    }
  }
  &-account {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    background-color: black;
    margin: 0;
    padding: 10px;
    position: sticky;
    z-index: 9;
    top: var(--headerHeight);
    li {
      a {
        color: $white;
      }
    }
  }
}
