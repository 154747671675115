/*========================================================*/
/*** MINI CART ***/
/*========================================================*/
#cart {
    margin-bottom: 0;
    .cart-btn {
        font-family: $secondary_font;
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        margin-right: 40px;
        span {
            display: inline-flex;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.2;
            @media (max-width: $tablet) {
                font-size: 13px;
                line-height: 0.7;
                .parenthesis {
                    display: none;
                }
            }
        }
        @media (max-width: $laptop-xs) {
            margin-right: 20px;
        }
        @media (max-width: $tablet) {
            margin-right: 0;
            display: flex;
            align-items: flex-end;
           .cart-text {
               display: none;
           }
        }
        .cart-icon {
            display: none;
            @media (max-width: $tablet) {
                display: inline-flex;
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1599 6.64217V5.34014C16.1599 2.9364 14.3039 1 11.9999 1C9.69588 1 7.8399 2.93634 7.8399 5.34014V6.64217H4V21.9994C4 22.1997 4.1279 22.3333 4.32009 22.3333H19.6799C19.8719 22.3333 20 22.1999 20 21.9994V6.64217H16.1599ZM8.48001 5.37347C8.48001 3.33697 10.048 1.70097 12.0001 1.70097C13.9521 1.70097 15.5202 3.33691 15.5202 5.37347V6.67549H8.48023L8.48001 5.37347ZM19.3599 21.6655H4.64005V7.30981H7.84001V11.6832H8.47995V7.30981H15.5199V11.6832H16.1598L16.16 7.30981H19.36L19.3599 21.6655Z' fill='%23231F20'/%3E%3C/svg%3E");
                svg {
                    display: inline-flex;
                    width: 24px;
                    height: 24px;
                    fill:$black;
                }
            }
        }
    }
    .minicart-body {
        display: block;
        position: fixed;
        z-index: 9999;
        background-color: $white;
        border: none;
        max-width: max(580px,vw(870px));
        width: 100%;
        transition: left 0.6s ease;
        right: -100%;
        top: 0;
        bottom: 0;
        height: var(--screenHeight);
        border-radius: 0;
        &.open {
            right: 0;
            transition: right 0.6s ease;
            @media (max-width: $tablet-sm) {
                max-width: 100%;
            }
        }
        .minicart-wrapper {
            height: var(--screenHeight);
        }
        .mini-cart-header {
            position: relative;
            padding: 44px 15px 41px;
            border-bottom: 2px solid var(--black);
            @media (max-width: $tablet) {
                padding: 20px 15px 18px;
            }
            h2 {
                text-align: center;
                margin-bottom: 0;
                color: $black;
                text-transform: uppercase;
                font-size: 25px;
                font-weight: 400;
            }
            .close-mini-cart-btn {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right:35px;
                .close-btn {
                    display: block;
                    width: 24px;
                    height: 24px;
                    svg {
                        display: block;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .all-products-w-button {
            height: calc(var(--screenHeight) - 325px);
            @media (max-width: $tablet) {
                height: calc(var(--screenHeight) - 240px);
            }
            .scrollable-products {
                padding: 40px max(15px, vw(70px));
                overflow-y: auto;
                max-height: calc(var(--screenHeight) - 325px);
                @media (max-width: $tablet) {
                    max-height: calc(var(--screenHeight) - 240px);
                    padding: 15px;
                }
                li {
                    .mini-cart-products {
                        padding: 30px 0;
                        justify-content: space-between;
                        @media (max-width: $tablet) {
                            padding: 15px 0;
                        }
                    }
                }
            }
        }
        .mobile-bottom-cart {
            color: $black;
            background-color: $white;
            border-top: 1px solid $black;
            padding: 25px max(15px, vw(70px));
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            @media (max-width: $tablet) {
                padding: 30px 15px 45px;
            }
            .mini-cart-total {
                .col-total {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-transform: uppercase;
                    font-size: 18px;
                    font-weight: 400;
                    &:nth-child(2), &:nth-child(3) {
                        display: none;
                    }
                }
            }
            .mini-cart-checkout {
                margin-top: 30px;
                a {
                    width: 100%;
                }
            }
        }
    }
}

.prd {
    &-row {
        display: flex;
        justify-content: space-between;
        padding: 50px 0;
        border-bottom: 1px solid var(--black);
        @media (max-width: $tablet) {
            padding: 30px 0;
        }
    }
    &-img {
        flex-basis: 30%;
        @media (max-width: $mobile-sm) {
            flex-basis: 35%;
        }
        a {
            display: inline-block;
            .img-thumbnail {
                object-fit: cover;
                width: 100%;
                max-width: 175px;
                border: 1px solid $black;
                border-radius: 0;
                padding: 0;
            }
        }
    }
    &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-basis: 70%;
        margin-left: 10px;
        color: $black;
        @media (max-width: $mobile-sm) {
            flex-basis: 65%;
        }
    }
    &-name {
        display: inline-block;
        font-size: 22px;
        font-family: $secondary_font;
        text-transform: capitalize;
        letter-spacing: 0.01em;
        @media (max-width: $tablet-sm) {
            font-size: 18px;
        }
        @media (max-width: $mobile) {
            font-size: 16px!important;
        }
    }
    &-price {
        font-size: 22px;
        margin-bottom: 0;
        @media (max-width: $tablet-sm) {
            font-size: 16px;
        }
    }
    &-stock {
        display: flex;
        color: $gray;
    }
    &-options {
        display: flex;
        li {
            padding: 0;
            .option-item {
                display: inline-block;
                color: #727272;
                font-size: 18px;
                @media (max-width: $tablet) {
                    font-size: 16px;
                }
                @media (max-width: $mobile) {
                    font-size: 14px;
                }
            }
            &:not(:last-child){
                &:after {
                    display: inline-block;
                    content: '/';
                    color: #727272;
                    font-size: 16px;
                    margin: 0 3px;
                }
            }
        }
    }
    &-choices {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-remove {
        padding: 0;
        border: none;
        background: none;
        font-size: 18px;
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
        }
        @media (max-width: $tablet-sm) {
            font-size: 14px;
        }
    }
}
