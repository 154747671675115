.error {
  &-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    #content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
      .btn-default {
        margin-top: 35px;
      }
    }
  }
}